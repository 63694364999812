import MerchantModel from "./MerchantModel";
import PartnerWebhookModel from "./PartnerWebhookModel";

class PartnerModel {
    static startDate = new Date();
    static json = {
        "id": "0e1896bd-c5b7-4fd0-a959-ef58c35d32ed",
        "name": " 16068",
        "key": "Partners.16068",
        "applicationToken": "dab9e528-2266-4be8-846d-efa91fcb7865",
        "created": "2022-05-10T05:31:25.8881291Z",
        
        "webhooks": []
    };
    
    toJson = () => { 
        return {
            name: this.name + '',
            email: this.email || '',
            phone: this.phone || '',
            website: this.website || '',
            created: this.created
        };
    }
    
    constructor(json, hasDetails) {
        if (!json) json = {};
        
        this.id = json.id;
        this.name = json.name || '';
        this.key = json.key || '';
        this.applicationToken = json.applicationToken || '';
        this.created = json.created ? new Date(json.created) : null;
        this.lastTransactionDate = new Date().addDays(-4);
        
        this.merchants = MerchantModel.fromJsonArray(json.merchants);
        this.webhooks = PartnerWebhookModel.fromJsonArray(json.webhooks);
    }

    search(term) { 
        return PartnerModel.searchFor(this, term);
    }

    findLocation(locationId) {
        if (typeof locationId !== "string" || locationId.length < 30) return null;
        
        let location = null;
        
        const merchants = this.merchants.filter((merchant) => {
            if (!!location) return false;
            location = merchant.findLocation(locationId);
            
            return !!location;
        });
        
        return location || null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new PartnerModel(json));
    }
    
    static searchFor(partner, term) {
        if ((typeof term !== "string" && typeof term !== "number") || !term)
            return true;
        
        if (!partner?.name) return false;
        
        term = term.toString().toLowerCase();
        
        return partner.name.toLowerCase().includes(term);
    }
    
}

export default PartnerModel;
