class TransactionModel {
    // public Guid SaleId { get; set; }
    // public Guid TransactionId { get; set; }
    // public string TerminalSerialNumber { get; set; }
    // public string PosTransactionId { get; set; }
    // public string PosTransactionData { get; set; }
    // public string TransactionType { get; set; }
    // public string CustomerToken { get; set; }
    // public string CustomerTokenExpDate { get; set; }
    // public double Amount { get; set; }
    // public string ReceiptWebUrl { get; set; }
    // public string CardType { get; set; }
    // public string CardLast4 { get; set; }
    // public string CardBin { get; set; }
    // public string CardAuthCode { get; set; }
    // public string CardHolderName { get; set; }
    //
    // public List<AdditionalTransactionData> AdditionalData { get; set; } = new();
    // public DateTime Created { get; set; }
    // public DateTime? TerminalTimestamp { get; set; }

    constructor(json) {
        if (!json) json = {};
        
        this.saleId = json.saleId;
        this.transactionId = json.transactionId;
        this.id = json.transactionId;
        this.terminalSerialNumber = json.terminalSerialNumber;
        this.posTransactionId = json.posTransactionId;
        this.posTransactionData = json.posTransactionData;
        this.posTransaction = null;
        
        if (typeof this.posTransactionData === "string") {
            this.posTransaction = JSON.parse(this.posTransactionData);
        }
        
        this.transactionType = json.transactionType;
        this.customerToken = json.customerToken;
        this.customerTokenExpDate = json.customerTokenExpDate;
        this.amount = json.amount || 0.0;
        this.receiptWebUrl = json.receiptWebUrl;
        this.cardType = json.cardType;
        this.cardLast4 = json.cardLast4;
        this.cardBin = json.cardBin;
        this.cardAuthCode = json.cardAuthCode;
        this.cardHolderName = json.cardHolderName;
        this.additionalData = json.additionalData || [];
        this.created = json.created ? new Date(json.created) : null;
        this.terminalTimestamp = !!json.terminalTimestamp ? new Date(json.terminalTimestamp) : null;
    }
    
    static createMockTransactions(saleId, count = 3) {
        
        let transactions = [];
        const days = Math.floor(Math.random() * 60);
        
        for (let i = 0; i < count; i++) {
            const transactionJson = {
                saleId: saleId,
                transactionId: "00000000-0000-0000-0000-000000000000",
                terminalSerialNumber: "123456",
                posTransactionId: "00000000-0000-0000-0000-000000000000",
                posTransactionData: "00000000-0000-0000-0000-000000000000",
                transactionType: "00000000-0000-0000-0000-000000000000",
                customerToken: "00000000-0000-0000-0000-000000000000",
                customerTokenExpDate: "00000000-0000-0000-0000-000000000000",
                amount: (Math.random() * 100) + 12.0,
                receiptWebUrl: "00000000-0000-0000-0000-000000000000",
                cardType: "00000000-0000-0000-0000-000000000000",
                cardLast4: "00000000-0000-0000-0000-000000000000",
                cardBin: "00000000-0000-0000-0000-000000000000",
                cardAuthCode: "00000000-0000-0000-0000-000000000000",
                cardHolderName: "00000000-0000-0000-0000-000000000000",
                additionalData: [],
                created: new Date().addDays(-days)
            };
            transactions.push(new TransactionModel(transactionJson));
        }
        
        return transactions.sort((a, b) => b.created - a.created);
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new TransactionModel(json));
    }
}

export default TransactionModel;
