import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const AddButton = ({ path, to, link, href, label, caption, children, icon }) => {
    const p = (to || path) || (link || href);
    const text = typeof children === "string" ? children : (label || caption || "Add");
    
    return (<Link className={"add-new"} to={p || "/"}>
        <FontAwesomeIcon icon={icon || faPlusCircle} /> { text }
    </Link>);
};

export default AddButton;
