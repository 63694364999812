import CreditCardModel from "../../sales/models/CreditCardModel";

class ReportItemModel { 
    static getKeyFromDate(key, startDate, endDate) {
        if (!key || typeof startDate !== 'object') return null;
        if (typeof endDate !== 'object') endDate = new Date();
        
        return key + '-' + startDate.formatDate('MM-dd-yyyy') + '-' + endDate.formatDate('MM-dd-yyyy');
    }

    static createDateRange (startDate, endDate) {
        let title = startDate.formatDate('MMMM DAY, YYYY') + ' - ' + endDate.formatDate('MMMM DAY, YYYY');
        let key = startDate.formatDate('YYYY-MM-dd') + '-' + endDate.formatDate('YYYY-MM-dd');
        
        return { startDate: startDate, endDate: endDate, title: title, key: key };
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.label = json.label || '';
        this.value = parseFloat(json.value || '0.0');
        this.id = json.id || '';

        if (isNaN(this.value)) this.value = 0.0;

        let x = Date.parse(json.x_axis_date);
        let xAxisDate = isNaN(x) ? new Date() : new Date(x);
        
        this.xAxisSortValue = json.x_axis_sort_value;
        this.xAxisDate = xAxisDate;
        this.xAxisValue = json.x_axis_value;
    }

    /**
     * Creates a single Series set for a line chart. If the values are not numbers, then the valueField is used to get the value.
     * @param values {[number|object]} - Array of numbers or objects with a value field.
     * @param name {string} - Name of the series. Will be displayed in the legend.
     * @param color {string} - Color of the line and points.
     * @param lineWidth {number} - Stroke with of the line of the series
     * @param pointRadius {number} - Radius of the points on the line.
     * @param valueField {string} - If the values are not numbers, then this is the field to get the value from.
     * @returns {{borderColor: string, backgroundColor: string, data: (*|*[]), borderWidth: number, label, pointRadius: number}}
     */
    static createLineChartSeries(values, name, color, lineWidth = 1, pointRadius = 1.5, valueField = "value") {
        if (typeof color !== 'string') color = "#CCDD88";

        return {
            label: name,
            data: values?.map((item) => typeof item === "number" ? item : item[valueField]?.toString() || item) ?? [],
            borderColor: color,
            backgroundColor: color,
            borderWidth: lineWidth,
            pointRadius: pointRadius
        };
    }
    
    static fromJsonArray(jsonArray) {
        let items = [];

        if (!!jsonArray && Array.isArray(jsonArray)) {
            for (let i = 0; i < jsonArray.length; i++) {
                let model = new ReportItemModel(jsonArray[i]);
                if (model.id || model.label) items.push(model);
            }
        }

        return items;
    }
}

export default ReportItemModel;
