class PartnerWebhookModel {
    static json = {
        "id": "",
        "partnerId": "",
        "webhookType": "",
        "url": ""
    };

    constructor(json, wh) {
        if (!json) json = {};

        this.url = json.url || null;
        this.id = json.id || null;
        this.webhookType = json.webhookType || null;
        this.partnerId = json.partnerId || null;
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new PartnerWebhookModel(json));
    }

    static toJsonArray(webhooks) {
        if (!webhooks || !Array.isArray(webhooks)) return [];

        return webhooks.map(wh => {
            return {
                partnerId: wh.partnerId,
                webhookType: wh.webhookType,
                url: wh.url
            };
        });
    }
}

export default PartnerWebhookModel;
