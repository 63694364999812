import React, {useRef, useState} from 'react';
import FormButton from "../../common/ui/FormButton";
import MerchantModel from "../models/MerchantModel";
import {Link} from "react-router-dom";
import ErrorModel from "../../common/models/ErrorModel";

const MerchantForm = (props) => {
    const {merchant, onSubmit, onCancel, isCreate, error, requiredFields} = props;
    const [formError, setFormError] = useState(error || {});

    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const websiteRef = useRef();
    
    
    const validateForm = (merchantModel) => {
        const req = Object.keys(requiredFields || {}).length > 0 ? requiredFields : { name: "Merchant name is required"};
        let err = {};
        
        if (!!req.name && !merchantModel.name) err.name = req.name;

        return new ErrorModel(err, true);
    }
    const onFormSubmit = async (e) => {
        const merchantModel = new MerchantModel();
        
        merchantModel.name = nameRef?.current?.value;
        merchantModel.email = emailRef?.current?.value;
        merchantModel.website = websiteRef?.current?.value;
        merchantModel.phone = phoneRef?.current?.value;
        
        const formError = validateForm(merchantModel);
        const prefix = "merchant-";
        
        if (formError.hasFormErrors()) {
            formError.focus(prefix);
            setFormError(formError.formError);
            return;
        }
        
        const rsp = onSubmit(e, merchantModel);
        
        return (typeof rsp.then === "function") ? await rsp.then((x) => x).catch((ex) => {
            console.warn("Axios Error:");
            console.error(JSON.stringify(ex, null, 4));
            
            const em = ErrorModel.createFormError(ex, prefix);
            setFormError(em);
            
            return null;
        }) : rsp;
    };

    return (<div className="form">
        <div className="form-group">
            <label htmlFor="merchant-name">Legal Entity Name</label>
            <input onChange={() => setFormError({})} type="text" id="merchant-name" ref={nameRef} defaultValue={merchant?.name || ""} />
            <div className="form-error">{ formError["name"] || null }</div>
        </div>

        <div className="form-group">
            <label htmlFor="merchant-email">Email</label>
            <input type="text" id="merchant-email" ref={emailRef} defaultValue={merchant?.email} />
        </div>

        <div className="form-group">
            <label htmlFor="merchant-phone">Phone</label>
            <input type="text" id="merchant-phone" ref={phoneRef} defaultValue={merchant?.phone} />
        </div>

        <div className="form-group">
            <label htmlFor="merchant-website">Website</label>
            <input type="text" id="merchant-website" ref={websiteRef} defaultValue={merchant?.website} />
        </div>

        <div className="form-error">{ formError["general"] || null }</div>
        
        <div className="button">
            <span><FormButton id="merchant-update-button" onClick={onFormSubmit} label={isCreate ? "Create Merchant" : "Update Merchant Details"}/></span>
            <span className={"cancel"}>
                <a onClick={onCancel}>Cancel</a>
            </span>
        </div>

    </div>);
};

export default MerchantForm;
