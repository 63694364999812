
class WebhookLogModel { 
    static json = {
        "id": "0fbe7b2a-0057-4fca-9127-b8ac3a7fcf5b",
        "partnerId": "bb68e800-d00e-4323-a407-85012df6a6e8",
        "statusCode": 0,
        "webhookType": "Webhook.Terminal.Connection.Changed",
        "url": "http://localhost:7071/api/partners/bb68e800-d00e-4323-a407-85012df6a6e8/ConnectionChanged",
        "requestJson": "{\"SerialNumber\":\"ST3SL472MW000012\",\"PartnerId\":\"bb68e800-d00e-4323-a407-85012df6a6e8\",\"MerchantLocationId\":\"deae6d3b-db99-4f26-9c9c-7e615ab57480\",\"Name\":\"Demo ST3SL472MW000012\",\"TerminalPosType\":\"poynt\",\"ConnectionId\":\"b46f259f27d548c4ca605f\",\"MaxTransactionsAllowed\":200,\"TransactionsPerformed\":31,\"DemoMode\":true,\"IsConnected\":true,\"Created\":\"2022-09-20T18:45:01.0061593Z\",\"LastConnected\":\"2022-10-18T18:21:04.3527522Z\"}",
        "responseJson": null,
        "errorMessage": "An attempt was made to access a socket in a way forbidden by its access permissions. (localhost:7071)",
        "succeeded": false,
        "timestamp": "2022-10-18T18:21:04.3789543"
    };
    
    constructor(json) {
        this.id = json.id;
        this.partnerId = json.partnerId;
        this.statusCode = json.statusCode;
        this.url = json.url;
        this.webhookType = json.webhookType;
        this.requestJson = json.requestJson;
        this.responseJson = json.responseJson;
        this.errorMessage = json.errorMessage;
        this.succeeded = json.succeeded;
        this.timestamp = json.timestamp;
    }

    static fromJsonArray(jsonArray) {
        let list = [];

        for(let i = 0; i < jsonArray.length; i++) {
            let wl = new WebhookLogModel(jsonArray[i]);
            if (!!wl.id) {
                list.push(wl);
            }
        }

        return list;
    }
}

export default WebhookLogModel;
