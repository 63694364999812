class NotificationService {
    static instance = new NotificationService();
    
    static createMockNotificationsJson() { 
        return [
            { id: '00-00-01', title: 'New CardSync Partner', entity_type: 1, entity_id: '43-21-01', path: '/partners/9012-09876543-009988', created: new Date(), modified: null },
            { id: '00-00-02', title: 'Konnektive Enrollment', entity_type: 2, entity_id: '5678-09876543-009988', path: '/partners/5678-09876543-009988/enrollments', created: new Date().addMinutes(-5), modified: null },
            { id: '00-00-03', title: 'Bluefin Enrollment', entity_type: 2, entity_id: '1234-09876543-009988', path: '/partners/1234-09876543-009988/enrollments', created: new Date().addMinutes(-15), modified: null },
            { id: '00-00-04', title: 'New Trouble Ticket', entity_type: 3, entity_id: '00-003', path: '/support/00-003', created: new Date().addDays(-1), modified: null },
            { id: '00-00-05', title: 'Batch Processed', entity_type: 4, entity_id: 'f8g9h0i1-f8g9h0i1-f8g9h0i1', path: '/partners/1234-09876543-009988/merchants/7890-09876543-332/batch/f8g9h0i1-f8g9h0i1-f8g9h0i1', created: '2022-06-20T08:32:00', modified: null },
        ]
    }
    
    constructor() {
        this.notifications = [];
    }
}

export default NotificationService;