import {
    Link, useOutletContext,
    useParams
} from "react-router-dom";
import React, {
    useContext,
    useEffect,
    useState
} from "react";
import SaleService
    from "../../components/sales/services/SaleService";
import HttpService
    from "../../components/common/services/HttpService";
import BackButton from "../../components/common/ui/BackButton";
import NumberDisplay from "../../components/formatters/NumberDisplay";
import AddButton from "../../components/common/ui/AddButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faHouseFire, faLocationDot,
    faMinusSquare,
    faPlusCircle,
    faPlusSquare,
    faSquareArrowUpRight
} from "@fortawesome/free-solid-svg-icons";
import PartnerService from "../../components/partners/services/PartnerService";
import DateTime from "../../components/formatters/DateTime";
import appContext from "../../AppContext";
import AppContext from "../../AppContext";
import Controller from "../../components/common/services/Controller";
import EnvironmentModel from "../../components/common/models/EnvironmentModel";

const SaleDetailsScreen = ({props}) => {
    const { partner, merchant, location, setClasses } = useOutletContext();
    const { saleId, partnerId, merchantId, locationId } = useParams();
    const [sale, setSale] = useState(null);
    const [saleLocation, setSaleLocation] = useState((location || partner?.findLocation(locationId)) || null);
    const [message, setMessage] = useState(null);
    const [isOpen, setIsOpen] = useState({ transactionData: false});
    const [scroller, setScroller] = useState(Controller.createScrollController());

    let _;

    const isMerchant = typeof merchantId === "string" && merchantId.length > 30;
    const isLocation = typeof locationId === "string" && locationId.length > 30;
    const isPartner = !isMerchant && !isLocation;
    
    const getLocationAsync = async (id) => {
        const loc = await PartnerService.instance.getMerchantAsync(id).catch((ex) => { 
            console.warn("Error Getting Merchant: " + ex);
            return null;
        })
    };
    
    const getSaleAsync = async () => {
        const saleModel = await SaleService.instance.getSaleAsync(saleId).catch((e) => {
            setMessage("Sale does not exist, or otherwise failed to load.");
            return null;
        });
        
        if (!!saleModel) { 
            if (!saleLocation?.id && !!saleModel.merchantLocationId) { 
                //
            }
            
            setSale(saleModel);
        }
    };
    
    useEffect(() => {
        scroller.scroll();
        _ = getSaleAsync();
    }, []);
    
    useEffect(() => {
        setClasses({ body: "sales-pad" });
    }, [isOpen]);
    
    if(!sale?.id) return (<h4>{ message || "Loading..."}</h4>);
    
    const backPath = "/";
    const backButton = (<BackButton />);
    
    const partnerElement = (<Link to={"/partners/" + sale.partnerId + "/details"}>{partner?.name || "Partner"}</Link>);
    
    const mid = (merchantId || sale.merchantId) || (sale.merchant?.id || null);
    const merch = (merchant || partner?.merchants?.find((m) => m.id === mid)) || sale.merchant;
    const merchantElement = mid ? 
        (<Link to={"/partners/" + sale.partnerId + "/merchants/" + mid + "/details"}>{merch.name}</Link>) :
        (merch?.name || "Merchant");

    const locId = sale?.locationId || locationId;
    const loc = (saleLocation || location) || (partner?.findLocation(locId) || sale?.location);
    const locationElement = !!mid && !!loc ?
        (<Link to={"/partners/" + sale.partnerId + "/merchants/" + mid + "/locations/" + locId + "/details"}>{loc.name}</Link>) :
        sale.location?.name || "Location";
    
    const receiptUrl = HttpService.instance.getUrl("/api/partners/" + sale.partnerId + "/sales/" + sale.id + "/receipt");
    const receiptViewElement = (<a className={"add-new"} href={receiptUrl} target={"_blank"}>
        <FontAwesomeIcon icon={faSquareArrowUpRight} /> View Receipt
    </a>);
    
    const lineItemElements = sale.lineItems.map((item, index) => {
        return (<tr key={"line-item-" + index}>
            <td>{ item.name }</td>
            <td><NumberDisplay value={item.price} type={"currency"} decimalPlaces={2} /> x <NumberDisplay value={item.qty} decimalPlaces={0} /></td>
            <td><NumberDisplay type={"currency"} value={item.amount} decimalPlaces={2} /></td>
        </tr>);
    });

    const transactionElements = sale.transactions.map((t, index) => {
        const cn = isOpen?.transactionData ? "" : "collapsed";

        const serialNumber = t.terminalSerialNumber;
        const transactionDataElement = t.posTransactionData ?
            (<span className={("json-data").trim()} onClick={() => setIsOpen({...isOpen, transactionData: !isOpen.transactionData})}>
                <span>POS Transaction Data:</span>
                <section className={cn}>
                    <span className={"opener closed"}><FontAwesomeIcon icon={faMinusSquare}/></span>
                    <span className={"opener open"}><FontAwesomeIcon icon={faPlusSquare}/></span>
                    {JSON.stringify(t.posTransaction, null, 4) || t.posTransactionData}
                </section>
            </span>) : null;

        const typeClassName = t.transactionType.replaceAll(".", "-").toLowerCase();
        const last4 = !!t.cardLast4 ? "**** **** **** " + t.cardLast4 : null;
        const posTransactionIdElement = !!t.posTransactionId ? (<strong> (Pos Transaction: {t.posTransactionId})</strong>) : null;

        const authCodeElement = t.cardAuthCode ? (<span>Card Auth Code: {t.cardAuthCode}</span>) : null;
        const cardholderElement = t.cardHolderName ? (<span>Card Holder Name: {t.cardHolderName}</span>) : null;
        const processorElement = !!authCodeElement || !!cardholderElement ? (<div className={"processor-data"}>{cardholderElement} {authCodeElement}</div>) : null;
        const webReceiptElement = typeof t.receiptWebUrl === "string" && t.receiptWebUrl.startsWith("http") ?
            (<span>Receipt Web URL: <a className={"external"} target={"_blank"} href={t.receiptWebUrl}>Go to transaction receipt <FontAwesomeIcon icon={faSquareArrowUpRight} /></a></span>) :
            null;
        
        const terminalPath = "/partners/" + partnerId + "/terminals/" + serialNumber + "/from-sale/" + saleId + (!isPartner ? "/with-merchant/" + mid : "");
        const portalUrl = !!serialNumber ?
            EnvironmentModel.environment.terminalPortalUrl + "?serialNumber=" + serialNumber + "&applicationToken=" + partner?.applicationToken + "&saleId="+saleId :
            null;

        const portalActionElement = !!partner?.applicationToken ? (<a className={"add-new"} href={portalUrl} target={"_blank"}>
            <FontAwesomeIcon icon={faSquareArrowUpRight} /> View in Portal
        </a>) : null;
        
        return (
            <div className={"transaction-element " + typeClassName} key={"transaction-" + index}>
                <div className={"transaction-item"}>
                    <div className={"transaction-details"}>
                        <h4>{t.cardType} <span>{t.transactionType.replaceAll(".", " ")}</span><span> { last4 }</span> <DateTime value={t.terminalTimestamp || t.created} time={true} seconds={true} /></h4>
                        <span>Terminal Serial# <Link to={terminalPath}>{t.terminalSerialNumber}</Link> &nbsp; { portalActionElement }</span>
                        { processorElement }
                        <span>Transction Id: {t.id} {posTransactionIdElement}</span>
                        { webReceiptElement }
                    </div>

                    <div>
                        <NumberDisplay value={t.amount} type={"currency"} isComponent={true} decimalPlaces={2}/>
                    </div>
                </div>
                {transactionDataElement}
            </div>);
    });
    
    const additionalElements = (<p className={"sale-details"}>
        <span>Sale Id: {sale.id}</span>
        <span>Partner Id: {sale.partnerId}</span>
        {sale.partnerSaleId ? <span>Partner Sale Id: {sale.partnerSaleId}</span> : null}
        {sale.partnerCustomerId ? <span>Partner Customer Id: {sale.partnerCustomerId}</span> : null}
        {sale.partnerMerchantLocationId ? <span>Partner Location Id: {sale.partnerMerchantLocationId}</span> : null}
    </p>);
    
    const merchantSubmenuElement = !!merchant?.id ?
        (<span className={"subtitle"}>{ merchant?.name || "No Merchant"}</span>) : 
        null;
    
    const sellerPanels = (<div className={"seller"}>
        <div>
            <span><FontAwesomeIcon icon={faAddressCard} /> Partner: { partnerElement }</span>
        </div>
        <div>
            <span><FontAwesomeIcon icon={faAddressCard} /> Merchant: {merchantElement}</span>
        </div>
        <div>
            <span><FontAwesomeIcon icon={faLocationDot} /> Location: {locationElement}</span>
        </div>
    </div>);
    
    const hasLineItems = sale.lineItems?.length > 0;
    const amountSummaryElements = sale.amountItems?.length > 0 ?
        sale.amountItems.map((item, idx) => { 
            return (<div className={"amount-item"} key={"amount-item-" + idx}>
                <span>{ item.name }:</span>
                <span><NumberDisplay value={item.amount} type={"currency"} decimalPlaces={2} /></span>
            </div>);
        }) :
        null;
    
    const amountsElement = amountSummaryElements ? (<div className={"summary-amounts"}>{amountSummaryElements}</div>) : null;
    
    return <>
        <h2 className={"sales"}>
            {backButton}
            Sale Details

            <span className={"subtitle"}>Partner: { partner?.name || "No Partner"}</span>
            { merchantSubmenuElement }
            { receiptViewElement }
        </h2>

        <div className={"sale-body"}>
            <table className={"receipt"} width={"100%"}>
                <thead>
                    <tr>
                        <th>Sale #{sale?.saleNumber}</th>
                        <th colSpan={2}>Total: <NumberDisplay type={"currency"} value={sale.total} decimalPlaces={2} /></th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={3} className={hasLineItems ? "" : "empty"}>
                        { sellerPanels }
                        { !hasLineItems ? amountsElement : null }
                    </td>
                </tr>
                { lineItemElements }
                </tbody>
            </table>
            { hasLineItems ? amountsElement : null }
    
            <div className={"sale-details transactions"}>
                <h2>Transactions</h2>
                {transactionElements}
            </div>
            
            <div className={"additional-info"}>
                <h2>Additional Info</h2>
                { additionalElements }
            </div>
        </div>
    </>;
}

export default SaleDetailsScreen;