import {
    Route,
    Routes
} from "react-router-dom";
import PartnersScreen
    from "./routes/partners/PartnersScreen";
import PartnerCreationScreen
    from "./routes/partners/PartnerCreationScreen";
import PartnerDetailsScreen
    from "./routes/partners/PartnerDetailsScreen";
import PartnerAccountDetailsScreen
    from "./routes/partners/PartnerAccountDetailsScreen";
import MerchantsScreen
    from "./routes/merchants/MerchantsScreen";
import MerchantDetailsScreen
    from "./routes/merchants/MerchantDetailsScreen";
import SalesScreen
    from "./routes/partners/SalesScreen";
import WebhooksScreen
    from "./routes/partners/WebhooksScreen";
import WebhookLogsScreen
    from "./routes/partners/WebhookLogsScreen";
import TerminalsScreen
    from "./routes/terminals/TerminalsScreen";
import ReportingScreen
    from "./routes/reporting/ReportingScreen";
import UsersScreen
    from "./routes/people/UsersScreen";
import UserDetailsScreen
    from "./routes/people/UserDetailsScreen";
import UserAccessScreen
    from "./routes/people/UserAccessScreen";
import UserSessionHistoryScreen
    from "./routes/people/UserSessionHistoryScreen";
import React
    , {
    useEffect,
    useState
} from "react";
import Main
    from "./Main";
import {
    faHouseUser
} from "@fortawesome/free-solid-svg-icons";
import CommonMasterPage
    from "./routes/common/CommonMasterPage";
import PartnerMasterPage
    from "./routes/partners/PartnerMasterPage";

import UserSubMenu
    from "./components/people/ui/UserSubMenu";
import LoginScreen
    from "./routes/authentication/LoginScreen";
import AuthenticationService
    from "./components/authentication/services/AuthenticationService";
import MerchantSaveScreen
    from "./routes/merchants/MerchantSaveScreen";
import TerminalUpdateScreen
    from "./routes/terminals/TerminalUpdateScreen";
import TerminalCreationScreen
    from "./routes/terminals/TerminalCreationScreen";
import UserService
    from "./components/people/services/UserService";
import SaleService
    from "./components/sales/services/SaleService";
import PartnerService
    from "./components/partners/services/PartnerService";
import TerminalService
    from "./components/terminals/services/TerminalService";
import HttpService
    from "./components/common/services/HttpService";
import PlatformService
    from "./components/common/services/PlatformService";
import MerchantLocationDetailsScreen from "./routes/merchants/MerchantLocationDetailsScreen";

import MerchantMasterPage
    from "./routes/merchants/MerchantMasterPage";
import MerchantLocationsScreen
    from "./routes/merchants/MerchantLocationsScreen";
import MerchantLocationSaveScreen
    from "./routes/merchants/MerchantLocationSaveScreen";
import SaleDetailsScreen
    from "./routes/sales/SaleDetailsScreen";
import MerchantLocationMasterPage
    from "./routes/merchants/MerchantLocationMasterPage";


function App() {
    if(!HttpService.instance)
        HttpService.instance = new HttpService();

    if(!PlatformService.instance)
        PlatformService.instance = new PlatformService();
    
    //create singleton instances;
    if(!AuthenticationService.instance)
        AuthenticationService.instance = new AuthenticationService();
    
    if(!UserService.instance)
        UserService.instance = new UserService();

    if(!SaleService.instance)
        SaleService.instance = new SaleService();

    if(!PartnerService.instance)
        PartnerService.instance = new PartnerService();

    if(!TerminalService.instance)
        TerminalService.instance = new TerminalService();

    const [session, setSession] = useState(AuthenticationService.instance.session || null);
    const [appContent, setAppContent] = useState(<>Loading...</>);
    
    const onAuthenticate = (sh, error) => {
        if (typeof error === 'string') {
            alert(error);
            setAppContent(getLogin());
            return;
        } else if (typeof error === 'object') {
            setAppContent(getLogin());
            return;
        }

        if (sh) {
            setSession(sh);
            setAppContent(getMain());
            return true;
        }

        console.error("Something weird happened with no explicit error message.");
        setAppContent(getLogin());
        return false;
    };
    
    useEffect(() => {
        if (!session)
            AuthenticationService.instance.restoreSessionAsync().then((sh) => {
                setSession(sh);
            });
    }, []);
    
    useEffect(() => {
        if(!AuthenticationService.instance.isGetting) {
            if(session) setAppContent(getMain());
            else setAppContent(getLogin());
        }
    }, [session]);
    
    const getLogin = () => {
        return <LoginScreen onAuthenticate={onAuthenticate} />;
    };

    const getMain = (props) => {
            const mainRoutes = (<Routes>
                    <Route path="/" index element={<Main bodyClass={"x"} />} />
                    <Route path="/partners" element={<PartnersScreen />} />
                    <Route path="/partners/new" element={<PartnerCreationScreen />} />
                    <Route path="/partners/:partnerId"  element={<PartnerMasterPage />} >
                        <Route path="/partners/:partnerId/details" index element={<PartnerDetailsScreen />} />
                        <Route path="/partners/:partnerId/update" element={<PartnerCreationScreen />} />
                        <Route path="/partners/:partnerId/merchants" element={<MerchantsScreen />} />
                        <Route path="/partners/:partnerId/merchants/new" element={<MerchantSaveScreen />} />
                        <Route path="/partners/:partnerId/sales" element={<SalesScreen />} />
                        <Route path="/partners/:partnerId/sales/:saleId" element={<SaleDetailsScreen />} />
                        <Route path="/partners/:partnerId/sales/:saleId/with-merchant/:merchantId" element={<SaleDetailsScreen />} />
                        <Route path="/partners/:partnerId/webhooks" element={<WebhooksScreen />} />
                        <Route path="/partners/:partnerId/webhooks/logs" element={<WebhookLogsScreen />} />
                        <Route path="/partners/:partnerId/terminals" element={<TerminalsScreen />} />
                        <Route path="/partners/:partnerId/terminals/new" element={<TerminalCreationScreen />} />
                        <Route path="/partners/:partnerId/terminals/:serialNumber" element={<TerminalUpdateScreen />} />
                        <Route path="/partners/:partnerId/terminals/:serialNumber/from-sale/:saleId" element={<TerminalUpdateScreen />} />
                        <Route path="/partners/:partnerId/terminals/:serialNumber/from-sale/:saleId/with-merchant/:merchantId" element={<TerminalUpdateScreen />} />
                        <Route path="/partners/:partnerId/terminals/:serialNumber/update" element={<TerminalUpdateScreen />} />
                    </Route>

                    <Route path="/partners/:partnerId/merchants/:merchantId" element={<MerchantMasterPage />}>
                        <Route path="/partners/:partnerId/merchants/:merchantId/details" index element={<MerchantDetailsScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/update" element={<MerchantSaveScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/sales" element={<SalesScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/sales/:saleId" element={<SaleDetailsScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/terminals" element={<TerminalsScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations" element={<MerchantLocationsScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations/new" element={<MerchantLocationSaveScreen />} />
                    </Route>

                    <Route path="/partners/:partnerId/merchants/:merchantId"  element={<MerchantLocationMasterPage />} >
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations/:locationId/details" element={<MerchantLocationDetailsScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations/:locationId/update" element={<MerchantLocationSaveScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations/:locationId/sales" element={<SalesScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations/:locationId/sales/:saleId" element={<SaleDetailsScreen />} />
                        <Route path="/partners/:partnerId/merchants/:merchantId/locations/:locationId/terminals" element={<TerminalsScreen />} />
                    </Route>

                    <Route path={"/terminals"} element={<TerminalsScreen />} />
                    <Route path={"/reporting"} element={<ReportingScreen />} />
                    <Route path={"/people"} element={<UsersScreen />} />
                    <Route path={"/people/:userId"} element={<UserSubMenu />} >
                        <Route path={"/people/:userId/details"} index element={<UserDetailsScreen />} />
                        <Route path={"/people/:userId/access"} element={<UserAccessScreen />} />
                        <Route path={"/people/:userId/sessions"} element={<UserSessionHistoryScreen />} />
                    </Route>
                </Routes>);
            
            return (<>
                <CommonMasterPage icon={faHouseUser} bodyClassName={"home-page"}>
                    {mainRoutes}
                </CommonMasterPage>
            </>);
    };
    
    return appContent;
}


export default App;