import HttpService from "./HttpService";
import PlatformModel from "../models/PlatformModel";

class PlatformService { 
    static instance;
    
    constructor() {
        this.platformInfo = null;
    }
    
    async getPlatformInfoAsync() {
        const path = "/api/utilities/platforminfo";
        const me = this;

        if(this.platformInfo !== undefined)
            return this.platformInfo;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            me.platformInfo = new PlatformModel(rsp.data);
            return me.platformInfo;
        }).catch((ex) => { 
            console.warn("Error getting platform info");
            console.warn(ex);
        });
    }
}

export default PlatformService;
