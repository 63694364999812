import React, {useEffect, useRef, useState} from 'react';
import UserService from "../../components/people/services/UserService";
import {
    Link,
    useOutletContext,
    useParams
} from "react-router-dom";
import PhoneNumber from "../../components/formatters/PhoneNumber";
import DateTime from "../../components/formatters/DateTime";
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPersonWalking,
    faPersonRunning,
    faArrowRightToBracket,
    faUserPlus,
    faFeatherPointed
} from "@fortawesome/free-solid-svg-icons";
import RefreshButton from "../../components/common/ui/RefreshButton";
import Pager from "../../components/common/ui/pager/Pager";
import UserModel from "../../components/people/models/UserModel";
import '../../components/people/ui/users.css';
import BackButton from "../../components/common/ui/BackButton";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import PersonForm from "../../components/people/ui/PersonForm";

const UserDetailsScreen = (props) => {
    let [user, setUser] = useState(useOutletContext().user);
    let [payload, setPayload] = useState(user?.toJson());
    
    const refreshUser = () => {
      //  
    };

    const updateUser = (user) => {
        //
    };

    useEffect(() => {
        if (!!user) setPayload(user.toJson());
    }, [user])
    
    useEffect(() => {
        if (!user?.id) { 
            UserService.instance.getUserAsync(user.id).then((user) => {
                setUser(user);
            });
        }
    }, []);

    const userForm = !!payload ? (<PersonForm person={payload} useButton={true} buttonCaption={"Update User Details"} onSubmit={updateUser} />) : null;
    // const userSubMenu = !!user ? (<UserSubMenu user={user} selection={"details"} />) : null;
    
    return (
        // <MasterScreenMain selection="users">
        //     <div className="pad">

                <div className="details-body large multi-form">
                {/*//     { userSubMenu }*/}
                    <div className="pad">
                        <h2>
                            <BackButton path="/people" />
                            { user?.name }
                            <span className="subtitle">User Details</span>
                            <RefreshButton onRefresh={refreshUser} />
                        </h2>

                        <div className={"date-activity"}>
                            <span id={"user-last-login-date"}>
                                <FontAwesomeIcon icon={faArrowRightToBracket} />
                                <DateTime showRelative={true} value={user?.lastLogin} defaultValue={"Hasn't logged in yet"} prefix={"Last Login: "} />
                            </span>
                            <span id={"user-created-date"}>
                                <FontAwesomeIcon icon={faUserPlus} />
                                <DateTime showRelative={true} value={user?.created} defaultValue={"Record creation date was not logged"} prefix={"Created: "} />
                            </span>
                            <span id={"user-modified-date"}>
                                <FontAwesomeIcon icon={faFeatherPointed} />
                                <DateTime showRelative={true} value={user?.modified} defaultValue={"User has never been modified"} prefix={"Last Modified: "} />
                            </span>
                        </div>
 
                        <p>
                            User details appear on logs, reports, and partner correspondance. Largely for display and communications purposes.
                        </p>
                        
                        <div id="user-details-body">
                            { userForm }
                            
                        </div>
                     </div>

     </div>
            // </div>
        // </MasterScreenMain>
    );
    
};

export default UserDetailsScreen;