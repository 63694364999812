import {
    useLocation,
    useNavigate,
    useOutletContext,
    useParams
} from "react-router-dom";
import MerchantLocationForm
    from "../../components/partners/ui/MerchantLocationForm";
import LocationModel
    from "../../components/partners/models/LocationModel";
import PartnerService
    from "../../components/partners/services/PartnerService";
import {
    useState
} from "react";
import ErrorModel from "../../components/common/models/ErrorModel";
import BackButton from "../../components/common/ui/BackButton";

const MerchantLocationSaveScreen = ({props}) => {
    const urlLocation = useLocation();
    const { partnerId, merchantId, locationId } = useParams();
    const { partner, merchant, location, refreshAsync } = useOutletContext();
    const [error, setError] = useState({});
    
    const navigate = useNavigate();
    const isCreate = urlLocation.pathname.includes("new");

    const saveLocationAsync = async (location) => {
        location.merchantId = merchantId;
        
        const loc = isCreate ?
            await PartnerService.instance.createMerchantLocationAsync(partner.id, location) :
            await PartnerService.instance.updateMerchantLocationAsync(partner.id, location);
        
        if (!loc) return null;
        
        await refreshAsync(PartnerService.instance);

        const path = '/partners/' + partnerId + '/merchants/' + merchantId + '/locations/' + loc.id + '/details';
        
        navigate(path);
        
        return loc;
    }

    const cancel = () => {
        const path = isCreate ?
            '/partners/' + partnerId + '/merchants/' + merchantId + '/locations' :
            '/partners/' + partnerId + '/merchants/' + merchantId + '/locations/' + locationId + '/details';
        
        navigate(path);
    }

    const form = (<MerchantLocationForm
        location={isCreate ? new LocationModel() : location}
        onSubmit={saveLocationAsync}
        onCancel={cancel}
        error={{...error}}
        isCreate={isCreate} />);
    
    const backPath = isCreate ?
        "/partners/" + partnerId + "/merchants/" + merchantId + "/locations" :
        "/partners/" + partnerId + "/merchants/" + merchantId + "/locations/" + locationId + "/details";
    
    return <>
        <h2>
            <BackButton path={backPath} />
            {isCreate ? "New" : "Update"} Location
            for {merchant.name}
        </h2>

        { form }
    </>;
}

export default MerchantLocationSaveScreen