import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EmptyBox = (props) => {
    const { message, id, className, icon } = props;
    const defaultId = () => {
        return 'empty-box-' + (Math.random() * 10000).toFixed(0).toString();
    };
    
    let cn = "empty-box " + (className || '');
    
    let body = props.children;
    
    if (!!icon) { 
        if (className !== 'middle') cn = cn + " middle";
        body = (<><span>{body}</span><span><FontAwesomeIcon icon={icon} /></span></>);
    }
    
    return (<div id={id || defaultId() } className={cn.trim()}>{body}</div>);
};

export default EmptyBox;
