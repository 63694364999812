import React from 'react';
import {
    Link,
    useLocation
} from "react-router-dom";
import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import {
    faAnchor,
    faBuildingWheat,
    faCheckToSlot,
    faIdCardClip,
    faLock,
    faMobileRetro
} from "@fortawesome/free-solid-svg-icons";

const MerchantSubMenu = ({ partner, merchant, selection }) => {
    const location = useLocation();
    const partnerId = partner?.id;
    const merchantId = merchant?.id;
    
    const getSelectionKey = () => {
        if (typeof selection === "string") return selection;
        if (location.pathname.includes('/sales')) return 'sales';
        if (location.pathname.includes('/locations')) return 'locations';
        if (location.pathname.includes('/terminals')) return 'terminals';
        if (location.pathname.includes('/users')) return 'users';
        if (location.pathname.includes('/settings')) return 'settings';
        
        return "overview";
    };
    
    const menuSelection = getSelectionKey();
    const classSelector = {};
    classSelector[menuSelection] = "selected";
    
    return (
        <div className="sub-column">
            <h4><span>Merchant:</span>{ merchant?.name }</h4>
            <ul>
                <li className={classSelector["overview"] || null}><Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/details'}><FontAwesomeIcon icon={faIdCardClip} />Overview</Link></li>
                <li className={classSelector["sales"] || null}><Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/sales'}><FontAwesomeIcon icon={faCheckToSlot} />Sales</Link></li>
                <li className={classSelector["locations"] || null}><Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/locations'}><FontAwesomeIcon icon={faBuildingWheat} />Locations</Link></li>
                <li className={classSelector["terminals"] || null}><Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/terminals'}><FontAwesomeIcon icon={faMobileRetro} />Terminals</Link></li>
            </ul>
            
            <h4>
                <span>Partner:</span>
                <Link to={"/partners/" + partnerId + "/details"}>{partner?.name}</Link>
            </h4>
            
        </div>
    );
};

export default MerchantSubMenu;

