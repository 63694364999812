import React, {useState} from "react";
import {
    useLocation,
    useNavigate,
    useOutletContext,
    useParams
} from "react-router-dom";
import PartnerService
    from "../../components/partners/services/PartnerService";
import MerchantForm
    from "../../components/partners/ui/MerchantForm";
import MerchantModel
    from "../../components/partners/models/MerchantModel";
import ErrorModel from "../../components/common/models/ErrorModel";
import BackButton from "../../components/common/ui/BackButton";

const MerchantSaveScreen = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { partnerId, merchantId, terminalId } = useParams();
    const { partner, merchant, refreshAsync } = useOutletContext();
    const [error, setError] = useState(null);
    const isCreate = location.pathname.includes("/new");
    
    const cancelClicked = (e) => {
        const path = (location.pathname.includes("update")) ?
            '/partners/' + partnerId + '/merchants/' + merchantId + '/details' :
            '/partners/' + partnerId + '/merchants/';
        
        navigate(path);
    }

    const saveClickedAsync = async (e, formMerchant) => {
        if (!formMerchant.name) {
            alert('Please enter a name for the merchant');
            return;
        }

        if (!formMerchant.partner) formMerchant.partner = partner;
        if (!formMerchant.partner?.id) formMerchant.partner.id = partner.id;
        formMerchant.id = merchant.id;

        const rsp = location.pathname.includes("update") ?
            await PartnerService.instance.updateMerchantAsync(partner.id, formMerchant).then((m) => {
                return { merchant: m, url: '/partners/' + partner.id + '/merchants/' + m.id + "/details" };
            }) :
            await PartnerService.instance.createMerchantAsync(partner.id, formMerchant).then((m) => {
                return { merchant: m, url: '/partners/' + partner.id + '/merchants/' + m.id + "/details" };
            });
        
        if (!rsp) return;

        await refreshAsync(PartnerService.instance);
        
        navigate(rsp.url + "?refresh=" + (new Date().getTime()).toString());
    }

    const form = (isCreate || !!merchant) ? (<MerchantForm
        merchant={isCreate ? new MerchantModel({}) : merchant }
        onSubmit={saveClickedAsync}
        onCancel={cancelClicked}
        error={{...error}}
        isCreate={isCreate}/>) : null;
    
    const title = isCreate ? "Create Merchant" : "Update Merchant";
    
    const backButton = (<BackButton onClick={cancelClicked} />);
    
    return (
        <>
            <div className="form">
                <h2>
                    { backButton }
                    { title }
                </h2>
                <p>Enter the details of this merchant below</p>
                { form }
            </div>
        </>
    );
}


export default MerchantSaveScreen;