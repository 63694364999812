import React, {useContext} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faSmile} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../AppContext";

const CommonMasterPage = ({ subTitle, children, selector, icon, subMenu, bodyClassName }) => {
    const appContext = useContext(AppContext);
    const body = children || (<p>No body provided for CommonMasterPage</p>);
    const subTitleElement = typeof subTitle === "string" ? (<h2>{subTitle}</h2>) : (subTitle || null);
    const bodyClass = (typeof bodyClassName === "string") ? bodyClassName : "";

    console.warn("App Context: " + (appContext?.title || "No Title"));
    appContext.setTitle("Common Master Page");
    
    return (
        <MasterScreenMain selection={selector} icon={icon}>
            <div>
            {/*<div className={"pad" + bodyClass}>*/}
                <div className="details-body">
                    <div className={("pad " + (bodyClass || "") + " " + (appContext?.classes?.body || "")).trim()}>
                        { body }
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default CommonMasterPage;

