import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faBell, faCalendarPlus, faEnvelope, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";

const ActivityNotificationRow = (props) => {
    const { notification, index } = props;

    if (!notification) return (<></>);
    
    let icon = faBell;
    
    switch(notification.entityType) {
        case 'partner':
        case 1:
            icon = faAddressCard;
            break;
        case 'enrollment':
        case 2:
            icon = faPeopleGroup;
            break;
            case 'message':
        case 3:
            icon = faEnvelope;
            break;
    }
    
    return (<li id={'notifications-item-' + index} key={'notifications-item-' + index}>
        <Link to={notification.path} id={'notifications-item-link-' + index}>
            <strong id={'notifications-item-strong-' + index}>
                {notification.title}
                <span className="date" id={'notifications-item-date-' + index}>{notification.time}</span>
            </strong>
            <FontAwesomeIcon id={'notifications-item-svg'} icon={icon} />
        </Link>
    </li>);
};

export default ActivityNotificationRow;
