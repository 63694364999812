import HttpService from "../../common/services/HttpService";
import UserModel from "../models/UserModel";
import UserSessionModel from "../models/UserSessionModel";

class UserService {
    static instance;
    
    constructor() {
        this.users = null;
        this.userMap = {};
    }

    async getUsersAsync() {
        const path = '/api/user';
        const me = this;

        return await HttpService.instance.getPaylaniAsync(path).then((response) => {
            me.users = UserModel.fromJsonArray(response.data);
            me.users.map((user) => me.userMap[user.id] = user);

            return me.users;
        });
    }

    async getUserAsync(userId) {
        const path = '/api/user/' + userId;
        const me = this;

        return await HttpService.instance.getPaylaniAsync(path).then((response) => {
            let user = new UserModel(response.data);

            if (!!user.id) me.userMap[user.id] = user;

            return user;
        });
    }

    async getUserSessionsAsync(userId, startDate, endDate) {
        const path = '/api/authenticate/session/' + userId;
        const me = this;

        return await HttpService.instance.getPaylaniWithDateRangeAsync(path, startDate, endDate).then((response) => {
            return UserSessionModel.fromJsonArray(response?.data);
        });
    }
    
    async getActivityAsync(userId) {
        const path = '/api/user/' + userId + '/activity';
        const me = this;

        return await HttpService.instance.getPaylaniAsync(path).then((response) => {
            return response.data;
        });
    };
    
    async sendPasswordResetLinkAsync(userId) { 
        const path = '/api/passwordset/reset/' + userId;
        const me = this;
        let data = {};

        return await HttpService.instance.postPaylaniAsync(path).then((response) => {
            return response;
        });
    }

    async deleteSessionsAsync(userId) {
        const path = '/api/authenticate/' + userId + '/session';
        const me = this;

        return await HttpService.instance.deletePaylaniAsync(path).then((response) => {
            return response;
        });
    }
}

export default UserService;
