import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchorCircleCheck,
    faAnchorCircleExclamation,
    faCheckCircle, faExclamation,
    faSyncAlt, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";

const RefreshButton = (props) => {
    let { icon, children, completedIcon, workingIcon, errorIcon, caption, workingCaption, onComplete, onClick, onError, transitionDelay } = props;
    let [isPosting, setIsPosting] = useState(0);

    let refreshIcon = (<FontAwesomeIcon icon={icon || faSyncAlt} />);
    let refreshCaption = caption || 'Refresh';

    if (!transitionDelay || typeof transitionDelay !== 'number') transitionDelay = 3500;
    if (typeof onComplete !== 'function' || onComplete === null) onComplete = () => {};
    if (typeof onClick !== 'function' || onClick === null) onClick = () => console.warn('Refresh Button has no onClick handler');
    if (typeof onError !== 'function' || onError === null) onError = (ex) => console.warn('Default Refresh Button Error: ' + ex.toString());
    
    const onRefreshCompleted = (response) => {
        setIsPosting(2);
        onComplete(response);
        
        setTimeout(() => {
            setIsPosting(0);
        }, transitionDelay);
    };
    
    const onRefreshError = (error) => {
        onError(error);
        setIsPosting(-1);

        setTimeout(() => {
            setIsPosting(0);
        }, transitionDelay);
    };
    
    const onRefresh = async (e) => {
        setIsPosting(1);
        let isAsync = onClick.constructor.name === 'AsyncFunction';

        try {
            if (isAsync) await onClick(e).then((x) => onRefreshCompleted(x));
            else onRefreshCompleted(onClick(e));
        }
        catch (ex) {
            console.log('Failed to refresh: ' + ex.toString());
            onRefreshError(ex);
        }
    };
    
    if (!errorIcon) errorIcon = faTriangleExclamation;
    if (!completedIcon) completedIcon = faCheckCircle;
    
    let spanClassName = ' visible';
    
    switch(isPosting) {
        case -1:
            refreshIcon = (<FontAwesomeIcon icon={errorIcon} className="failed" />);
            refreshCaption = 'Error';
            break;
        case 1:
            refreshIcon = (<span style={{marginRight: '8px', marginTop: '3px'}}><label className="spinner spinning"></label></span>);
            refreshCaption = workingCaption || 'Working';
            break;
        case 2:
            refreshIcon = (<FontAwesomeIcon icon={completedIcon} className="success" />);
            refreshCaption = 'Success';
            break;
        default:
            spanClassName = '';
            break;
    }
    
    let body = children || (<>{refreshIcon} {refreshCaption}</>);
    
    return (<span className={"refresh-button" + spanClassName}>
            <a onClick={onRefresh}>
                { body }
            </a>
        </span>);
};

export default RefreshButton;
