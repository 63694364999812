import { createContext } from 'react';
import {faHomeUser} from "@fortawesome/free-solid-svg-icons";

const AppContext = createContext({
    title: "eVoke Portal.",
    setTitle: (title) => {},
    icon: faHomeUser,
    setIcon: (icon) => {},
    session: null,
    setSession: (session) => {},
    classes: {},
    setClasses: (classes) => {},
    name: "No Name",
});

export default AppContext;