import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import {
    Link,
    NavLink,
    useLocation,
    useNavigate
} from "react-router-dom";
import logo from './images/evoke-logo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faAddressCard,
    faArrowTrendUp,
    faGears,
    faHouseChimneyCrack,
    faUsers,
    faBell,
    faMobileRetro,
    faSignOutAlt,
    faHome,
    faGhost, faHomeUser

} from "@fortawesome/free-solid-svg-icons";

import AuthenticationService from "./components/authentication/services/AuthenticationService";
import ActivityNotificationRow from "./components/messaging/ui/ActivityNotificationRow";
import LoginScreen from "./routes/authentication/LoginScreen";
import NotificationService from "./components/messaging/services/NotificationService";
import EnvironmentModel from "./components/common/models/EnvironmentModel";
import PlatformService from "./components/common/services/PlatformService";
import AppContext
    from "./AppContext";

const MasterScreenMain = (props) => {
    const appContext = useContext(AppContext);
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState(props.icon || faHomeUser);
    
    let selections = { partners: null, home: null, terminals: null };
    let selector = props.selection || 'general';

    const onSubPageClick = props.onClick;
    let _;

    const [notificationState, setNotificationState] = useState(0);  // Notification dropdown shown (1) or not (0)
    const [session, setSession] = useState(AuthenticationService.instance.session || null);
    const [platformInfo, setPlatformInfo] = useState(PlatformService.instance.platformInfo || null);

    if (selector) selections[selector] = 'selected';

    const signOut = (e) => {
        AuthenticationService.instance.signOutAsync().then(() => {
            console.log("sign out complete");
            setSession(null);
            setNotificationState(0);
            
            appContext.setSession(null);
        }).catch((error) => console.error('Ex: ' + error));
    };

    let user = session?.user;
    let imageUrl = user?.imageUrl || '';

    let notifications = NotificationService.instance.notifications;
    let notificationsList = notifications.map((notification, index) => (<ActivityNotificationRow key={'notifications-item-' + index.toString()} notification={notification} index={index} />));

    const onNotificationClick = () => {
        if (notifications.length === 0 && notificationState === 0) return;
        console.log('Clicked notification');

        let newState = notificationState;

        if (newState === 0) newState = 1;
        else newState = 0;

        setNotificationState(newState);
    };

    const closeNotificationBox = (e) => {
        if (typeof onSubPageClick === 'function') onSubPageClick(e);

        if (notificationState === 0) return;
        if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
            return;
        }

        setNotificationState(0);
    };

    let notificationBadge = notifications.length > 0 ? (<span className="badge" id="notification-004">
                            <span className="badge-content" id="main-notification-badge">{ notifications.length.formatNumber(0)}</span>
                        </span>) : (<></>);

    let notificationsBox = (notificationState === 0) ?
        (<></>) :
        (<div id="notifications-box">
            <div className="arrow-holder" id="notifications-box-arrow-holder">
                <div className="arrow-up" id="notifications-box-arrow-up"></div>
            </div>
            <div className="content" id="notifications-box-content">
                <ul id="notifications-item-list">
                    { notificationsList }
                </ul>
            </div>
        </div>);

    let profileDetails = user ?
        (<ul><li><Link to={"/people/" + user?.id + "/details"}>{user?.firstName + ' ' + user?.lastName}</Link></li><li>Master User</li></ul>) :
        (<ul><li>Loading...</li><li>.</li></ul>);

    const profileImage = imageUrl.length > 5 ?
        (<img id="my-profile-image-top" src={imageUrl} alt="Cardsync Logo" />) :
        (<FontAwesomeIcon icon={faGhost} />);

    const dbEnv = platformInfo?.databaseEnvironment ? (<span id={"db-env"}>{platformInfo.databaseEnvironment} Database</span>) : null;
    
    const contextData = {
        title: title,
        setTitle: setTitle,
        icon: icon,
        setIcon: setIcon,
        session: session,
        setSession: (session) => setSession,
        classes: {},
        setClasses: (classes) => {
            console.log("Set Classes in Master Page:");
            console.log(JSON.stringify(classes, null, 4));
        },
        name: "MasterScreenMain",
    };
    
    return (
        <AppContext.Provider value={contextData}>

        <div id="main" onClick={closeNotificationBox}>
            <div id="my-header">
                <div className={"inner"}>
                    <span id={"title-h1"}>
                        <div id={"environment-div"} className={EnvironmentModel.environment?.key}>
                            <span>{ platformInfo?.environment?.toUpperCase() || EnvironmentModel.environment?.name?.toUpperCase() }</span>
                            { dbEnv }
                        </div>
                        <h1>
                            <FontAwesomeIcon icon={icon || faHome} />
                            { title || "eVoke Portal"}
                        </h1>
                    </span>

                    <span id={"my-header-account-panel"}>
                        <span className="section">
                            <span id="my-profile-top-menu-item">
                                <span className={"default-image"}>{profileImage}</span>
                                <span id="my-profile-account-details-top">
                                    {profileDetails}
                                </span>
                            </span>
                        </span>
                        
                        <span className="section" id="main-notification-section" onClick={onNotificationClick}>
                            <span className="content notifications" id="notification-001">
                                <span id="notifications-top">
                                    <FontAwesomeIcon icon={faBell} id="notification-003" />
                                </span>
                                {notificationBadge}
                            </span>
                        </span>
                        
                    </span>

                </div>
            </div>

            <div id="main-column">
                <div id="logo">
                    <Link to="/"><img src={logo} alt="Paylani" /></Link>
                </div>
                <ul>
                    <li className={location.pathname==='/' ? 'selected':''} id="menu-home"><NavLink  to="/"><FontAwesomeIcon icon={faHouseChimneyCrack} /> Home</NavLink></li>
                    <li className={location.pathname.startsWith('/partners') ? 'selected':''} id="menu-partners"><Link to="/partners"><FontAwesomeIcon icon={faAddressCard} /> Partners</Link></li>
                    <li className={location.pathname.startsWith('/terminals') ? 'selected':''} id="menu-messaging"><Link to="/terminals"><FontAwesomeIcon icon={faMobileRetro} /> Terminals</Link></li>
                    <li className={location.pathname.startsWith('/reporting') ? 'selected':''} id="menu-reporting"><Link to="/reporting"><FontAwesomeIcon icon={faArrowTrendUp} /> Reporting</Link></li>
                    <li className={location.pathname.startsWith('/people') ? 'selected':''} id="menu-users"><Link to="/people"><FontAwesomeIcon icon={faUsers} /> Users</Link></li>
                </ul>

                <ul id="mini-menu">
                    <li>
                        <a onClick={signOut}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                        </a>
                    </li>
                </ul>
            </div>

            <div id="main-body" className={selector}>
                { props.children }
            </div>
            { notificationsBox }
        </div>

        </AppContext.Provider>
    )
};

export default MasterScreenMain;
