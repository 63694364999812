import AddressModel from "../../geo/models/AddressModel";

class LocationModel {
    static json = {
        "id": "52ac51fc-2a52-4c52-a3ea-8e9796ef7957",
        "merchantId": "d29a5af9-f2cb-4cdd-829c-a139ded98b83",
        "isDefault": true,
        "partnerMerchantLocationId": null,
        "name": " 16068 Merchant Location",
        "address1": null,
        "address2": null,
        "city": null,
        "state": null,
        "zip": null,
        "countryCode": null,
        "created": "0001-01-01T00:00:00"
    };

    constructor(json) {
        if (!json) json = {};

        this.id = (json.id || json.locationId) || (json.merchantLocationId || null);
        this.merchantId = json.merchantId;
        this.isDefault = json.isDefault;
        this.partnerMerchantLocationId = json.partnerMerchantLocationId;
        this.name = json.name || '';
        this.isLocal = json.is_local || false;
        this.isServer = !this.isLocal;
        
        this.address = new AddressModel(json.address || json);
        this.created = json.created ? new Date(json.created) : null;
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new LocationModel(json));
    }

   static toJson(location) {
        return {
            "id": location.id,
            "merchantId": location.merchantId,
            "partnerMerchantLocationId": location.partnerMerchantLocationId,
            "name": location.name,
            "address1": location.address?.street,
            "address2": location.address?.unit,
            "city": location.address?.city,
            "state": location.address?.state,
            "zip": location.address?.zip,
            "countryCode": location.address?.country,
        };
    }
}

export default LocationModel;
