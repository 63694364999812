import HttpService
    from "../../common/services/HttpService";
import PartnerModel
    from "../models/PartnerModel";
import SaleModel
    from "../../sales/models/SaleModel";
import {
    Await
} from "react-router-dom";
import MerchantModel
    from "../models/MerchantModel";
import LocationModel from "../models/LocationModel";

class PartnerService {
    static instance;

    constructor(props) {
        this.partnerMap = {};
        this.subMerchantMap = {};
        this.subMerchantsMap = {};
        this.partners = [];
        this.salesMap = {};
        this.statementHistory = [];
        this.batchActivity = {};
        this.cardBrandMap = {};

        this.reportMap = {};
    }

    async getPartnersAsync() {
        const me = this;
        const url = '/api/partners';

        console.log("Getting Partners: " + url);

        return await HttpService.instance.getAsync(url).then((rsp) => {
            const partners = PartnerModel.fromJsonArray(rsp.data);
            me.partners = partners;
            me.partnerMap = {};

            partners.map((partner) => me.partnerMap[partner.id] = partner);

            return partners;
        });
    }

    async getMerchantAsync(merchantId, force = false) {
        
    }
    
    async createMerchantAsync(partnerId, merchant) {
        return await HttpService.instance.postAsync('/api/partners/' + partnerId + '/merchants', merchant.toNewJson())
            .then(async (rsp) => {

                if (!rsp?.data)
                    return null;

                await this.getPartnersAsync();

                return new MerchantModel(rsp.data);
            });
    }

    async updateMerchantAsync(partnerId, merchant) {
        const path = "/api/partners/" + partnerId + "/merchants/" + merchant.id;
        const data = merchant.toNewJson();
        
        return await HttpService.instance.putAsync(path, data).then(async (rsp) => {
            if (!rsp?.data) return null;
            await this.getPartnersAsync();
            return new MerchantModel(rsp.data);
        }).catch((ex) => {
            // console.error(JSON.stringify(ex, null, 4));
            // console.log("---- Raw Request ----");
            // console.log("Headers:");
            // console.log(JSON.stringify(ex.response?.config.headers, null, 4));
            // console.log("Http Request:");
            // console.log("PUT " + path);
            // console.log("");
            // console.log(JSON.stringify(data, null, 4));
            throw ex;
        });
    }

    async createMerchantLocationAsync(partnerId, location) {
        console.log("Creating Location: " + location.name);
        
        const url = "/api/partners/" + partnerId + "/merchants/" + location.merchantId + "/locations";
        const data = LocationModel.toJson(location);

        return await HttpService.instance.postAsync(url, data).then(async (rsp) => {
            if (!rsp?.data) return null;
            await this.getPartnersAsync();
            return new LocationModel(rsp.data);
        });
    }
    
    async updateMerchantLocationAsync(partnerId, location) {
        console.log(location.name);
        
        const url = "/api/partners/" + partnerId + "/merchants/" + location.merchantId + "/locations/" + location.id;
        
        return await HttpService.instance.putAsync(url, LocationModel.toJson(location)).then(async (rsp) => {
            if (!rsp?.data) return null;

            await this.getPartnersAsync();

            return new LocationModel(rsp.data);
        });
    }

    async getPartnerAsync(partnerId, force = false) {
        if (!partnerId) {
            console.error('Failed to get partner. Partner id no good: ' + partnerId);
            return null;
        }

        const p = this.partnerMap[partnerId];

        if (!!p && force !== true) return p;
        await this.getPartnersAsync();
        
        return this.partnerMap[partnerId] || null;
        // const me = this;
        // const url = '/api/partners/' + partnerId;
        //
        // return await HttpService.instance.getAsync(url).then((rsp) => { 
        //     if (!rsp?.data) return null;
        //    
        //     const partner = new PartnerModel(rsp?.data);
        //     me.partnerMap[partner.id] = partner;
        //    
        //     return partner;
        // });
    }

    async createPartnerAsync(partner) {
        const me = this;
        console.log("Creating Partner: " + partner.name);
        
        return await HttpService.instance.postAsync('/api/partners', JSON.stringify(partner)).then((rsp) => {
                if (!rsp?.data) return null;

                const partner = new PartnerModel(rsp?.data);

                me.partnerMap[partner.id] = partner;

                return partner;
            });
    }

    async updatePartnerAsync(partner) {
        const partnerId = partner?.id;
        
        if (!partnerId) throw new Error("Partner Id is required to update a partner.");
        if (!partner?.name) throw new Error("Partner Name is required to update a partner.");

        console.log("Updating Partner: " + partner?.name);
        
        const data = { name: partner.name };
        const me = this;
        
        return await HttpService.instance.putAsync('/api/partners/' + partnerId, data).then((rsp) => {
            const p = new PartnerModel(rsp?.data);
            if (!p.name) return null;

            if (!!me?.partnerMap) me.partnerMap[partnerId] = p;
            else console.warn("Me: " + (typeof this).toString());

            return p;
        });
    }
    
    async getSalesByMerchantAsync(merchantId, partnerId) {
        const me = this;
        const url = "/api/merchants/" + merchantId + "/sales";

        return await HttpService.instance.getAsync(url).then((rsp) => {
            if (!rsp?.data) return null;

            const sales = SaleModel.fromJsonArray(rsp.data);
            me.salesMap[merchantId] = sales;

            return sales;
        });
    }
}

export default PartnerService;
