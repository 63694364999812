import React, {
    useRef,
    useState,
    useEffect
} from 'react';
import {
    useOutletContext,
    useParams
} from "react-router-dom";
import PartnerService
    from "../../components/partners/services/PartnerService";
import PagerController
    from "../../components/common/ui/pager/PagerController";
import PartnerMasterPage
    from "./PartnerMasterPage";
import PartnerWebhookModel
    from "../../components/partners/models/PartnerWebhookModel";
import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import {
    faEdit
} from "@fortawesome/free-solid-svg-icons";
import PartnerWebhooksSubMenu
    from "../../components/partners/ui/PartnerWebhooksSubMenu";
import PartnerWebhookForm
    from "../../components/partners/ui/PartnerWebhookForm";
import BackButton
    from "../../components/common/ui/BackButton";

const WebhooksScreen = (props) => {
    const partner = useOutletContext().partner;
    const webhookTypes = ["Webhook.Terminal.Connection.Changed", "Webhook.Transaction.Completed"];

    let tempWebhooks = partner.webhooks;

    console.warn(JSON.stringify(partner.webhooks, null, 4));
    
    webhookTypes.map((webhookType) => {
        let existingWebhook = partner.webhooks.find((wh) => wh.webhookType === webhookType);

        if (!existingWebhook) {
            console.log("Adding Webhook: " + webhookType);
            const data = { webhookType: webhookType, partnerId: partner.id, url: "", id: null };
            const webhook = new PartnerWebhookModel(data);
            tempWebhooks.push(webhook);
        } else {
            if (tempWebhooks.indexOf(existingWebhook) === -1)
                tempWebhooks.push(existingWebhook);
        }
    });
    
    const [partnerWebhooks, setPartnerWebhooks] = useState(tempWebhooks);
    const [editIndex, setEditIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(0);
    const pageController = useRef(new PagerController(setCurrentPage, 12)).current;

    const handleChange = (event, index) => {
        const {
            name,
            value
        } = event.target;
        const updatedWebhooks = [...partnerWebhooks];
        updatedWebhooks[index] = {
            ...partnerWebhooks[index],
            [name]: value
        };
        setPartnerWebhooks(updatedWebhooks);
    }

    const cancelEditor = (e) => {
        setEditIndex(-1);
    };

    const updateWebhook = (e, webhook) => {
        let existingWebhook = partnerWebhooks.find((wh) => wh.webhookType === webhook.webhookType);
        
        console.log("updatedWebhook" + existingWebhook.webhookType + " " + existingWebhook.url);
    }

    //let webhookElements = partnerWebhooks.map((webhook, index) => {
        //const cn = (index === editIndex) ? " show" : "";

        // return (
        //     <div
        //         key={webhook.webhookType}
        //         className={"webhook-item" + cn}>
        //         <span
        //             className={"hover-edit float-right padded"}>
        //             <a onClick={(e) => setEditIndex(index)}><FontAwesomeIcon
        //                 icon={faEdit}/> Edit</a>
        //         </span>
        //         <div
        //             className={"webhook-item-display"}>
        //             <h4>{webhook.webhookType.replaceAll(".", " ").replace("Webhook", "")}</h4>
        //             <p>{webhook.url || "No webhook set"}</p>
        //         </div>
        //         <div
        //             className={"webhook-url"}>
        //             <label
        //                 htmlFor={"webhook-url-" + webhook.webhookType}>Webhook
        //                 Post-to
        //                 Url: </label>
        //             <div
        //                 className="webhook-url-inputs">
        //                 <input
        //                     id={"webhook-url-" + webhook.webhookType}
        //                     name="url"
        //                     value={webhook.url}
        //                     onChange={(event) => handleChange(event, index)}
        //                     type={"text"}/>
        //
        //                 <button
        //                     onClick={(e) => updateWebhook(e, webhook)}>Update
        //                 </button>
        //                 <a onClick={cancelEditor}>Cancel</a>
        //             </div>
        //         </div>
        //     </div>
        // );
    //});

    const webhookMenu = partner ? (
        <PartnerWebhooksSubMenu
            partner={partner}/>) : null;

    return (
        // <PartnerMasterPage onPartner={onPartner} title={partner?.name + ": Webhooks"} selection={"webhooks"}>
        <>
            <h2>
                { partner?.name }
                <span className="subtitle">Webhooks</span>
            </h2>
            
            {webhookMenu}
            <p>
                Webhooks
                allow
                you
                to
                receive
                real-time
                notifications
                of
                events
                that
                occur
                in
                your
                account.
            </p>

            <div
                className={"form"}>
                {partnerWebhooks.map((webhook, i) => <PartnerWebhookForm key={"wh-" + i} webhook={webhook}/>)}
            </div>
        </>
        // </PartnerMasterPage>
    );
};

export default WebhooksScreen;

