import AddressModel from "../../geo/models/AddressModel";
import CreditCardModel from "../../sales/models/CreditCardModel";

class UserModel {

    static createMockCustomerJson(firstName, lastName, email, phone) {
        let customer = UserModel.createMockUserJson(firstName, lastName, email, phone);
        customer.user_type = 'customer';
        customer.credit_card = CreditCardModel.createMockCreditCardJson();
        return customer;
    }

    static createMockUserJson(firstName, lastName, email, phone) {
        if (!firstName) firstName = 'John';
        if (!lastName) lastName = 'Doe';
        if (!email) email = 'info@' + firstName.toLowerCase() + '.' + lastName.toLowerCase() + '.com';
        if (!phone) phone = '800-332-0922';

        return {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": phone,
            "user_type": "user",
            "image_url": "https://s3-us-west-2.amazonaws.com/paylani-images/userb8804d7e-9b80-459b-b8d6-963fa2852228-637103485328496655.jpg",
            "address": {
                "street1": "123 Main St",
                "street2": "#404",
                "city": "San Diego",
                "state": "CA",
                "country": "US",
                "zip": "92124"
            },
            "credit_card": null,
            "created": "2018-03-23T19:56:58.703",
            "modified": null
        };
    }

    constructor(user) {
        if (!user) user = {};

        this.id = user.id;
        this.evokeApiToken = user.evoke_api_token;
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.email = user.email;
        this.phone = user.phone;
        this.imageUrl = user.image_url;
        this.username = user.user_name;
        this.userType = user.user_type || 'customer';
        this.address = user.address == null ? new AddressModel() : new AddressModel(user.address);
        this.name = (this.firstName + ' ' + this.lastName).trim();
        this.creditCard = new CreditCardModel(user.credit_card);

        this.lastLogin = !!user.last_login ? new Date(user.last_login) : null;
        this.created = new Date(user.created);
        this.modified = !!user.modified ? new Date(user.modified) : null;
    }

    static fromJsonArray(jsonArray) {
        let users = [];

        for (let i = 0; i < jsonArray.length; i++) {
            let user = new UserModel(jsonArray[i]);
            if (!!user.id && user.id.length > 10) users.push(user);
        }

        return users;
    }

    toJson = () => {
        return {
            "id": this.id,
            "first_name": this.firstName,
            "last_name": this.lastName,
            "email": this.email,
            "phone": this.phone,
            "image_url": this.imageUrl,
            "user_name": this.username,
            "user_type": this.userType,
            "address": this.address.toJson()
        };
    };
}

export default UserModel;
