import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import {
    Link,
    useOutletContext,
    useParams, useRoutes
} from "react-router-dom";

import PartnerService from "../../components/partners/services/PartnerService";
import PhoneNumber from "../../components/formatters/PhoneNumber";
import DateTime from "../../components/formatters/DateTime";
import AppContext
    from "../../AppContext";
import BackButton
    from "../../components/common/ui/BackButton";
import RefreshButton from "../../components/common/ui/RefreshButton";
import AddButton from "../../components/common/ui/AddButton";

const MerchantsScreen = (props) => {
    const { partner, refreshAsync } = useOutletContext();
    const { partnerId } = useParams();
    const merchants = partner?.merchants || [];
    const nameStyle = { width: '28%' };
    const countStyle = { width: '12%' };
    const nameAndCountStyle = { width: '40%' };
    const emailStyle = { width: '25%' };
    const phoneStyle = { width: '14%' };
    let _;

    return (
        <>
            <h2>
                { partner?.name }
                <span className="subtitle">Sub-Merchants</span>
                <RefreshButton onClick={() => refreshAsync(PartnerService.instance)} />
                <AddButton path={"/partners/" + partner?.id + "/merchants/new"}>Add Merchant</AddButton>
            </h2>
            <p>
                Below are all of the sub-merchants for { !!partner ? (<>partner <strong>{partner?.name}</strong></>) : "this partner"}.  Click on the name of a merchant to view further details
            </p>
            <table width={"100%"} className={"table x-large"}>
                <thead>
                <tr>
                    <th colSpan={2} style={nameAndCountStyle}>Name</th>
                    <th style={emailStyle}>Email</th>
                    <th style={phoneStyle}>Phone</th>
                    <th>Created</th>    
                </tr>
                </thead>
                <tbody>
                {merchants?.map((merchant, index) => {
                    const path = "/partners/" + partner?.id + "/merchants/" + merchant.id + "/details";
    
                    return (
                        <tr key={index}>
                            <td style={nameStyle}>
                                <Link to={path}>{merchant.name}</Link>
                            </td>
                            <td className="small-cell" style={countStyle}><Link to={path}>{merchant.isDefault}</Link></td>
                            <td className="email-cell" style={emailStyle}>{merchant.email}</td>
                            <td className="phone-cell" style={phoneStyle}><PhoneNumber value={merchant.phone} defaultValue={'N/A'} /></td>
                            <td><DateTime time={true} value={merchant.created} /></td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </>
    );
};

export default MerchantsScreen;
