import React, {
    useState,
    useRef,
    useEffect,
    useContext
} from 'react';
import './App.css';
import './components/reporting/ui/Reporting.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHandsClapping, faHandSpock, faHouseUser, faSync} from "@fortawesome/free-solid-svg-icons";
import CardBrandLineGraph from "./components/reporting/ui/CardBrandLineGraph";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip

} from "chart.js";
import {Link, useOutletContext} from "react-router-dom";
import ReportingService from "./components/reporting/services/ReportingService";
import DateTime from "./components/formatters/DateTime";
import AuthenticationService from "./components/authentication/services/AuthenticationService";
import RefreshButton from "./components/common/ui/RefreshButton";
import PagerController from "./components/common/ui/pager/PagerController";
import Pager from "./components/common/ui/pager/Pager";
import ReportItemModel from "./components/reporting/models/ReportItemModel";
import NumberDisplay from "./components/formatters/NumberDisplay";
import SaleService from "./components/sales/services/SaleService";
import CustomerModel from "./components/people/models/CustomerModel";
import PartnerModel from "./components/partners/models/PartnerModel";
import LoadingBox from "./components/common/ui/LoadingBox";
import SearchFilter from "./components/common/ui/search-filter/SearchFilter";
import PartnerService from "./components/partners/services/PartnerService";
import AppContext from "./AppContext";
import ErrorModel from "./components/common/models/ErrorModel";

function Main({ bodyClass }) {
    const appContext = useContext(AppContext);
    const outletContext = useOutletContext();
    
    useEffect(() => {
        appContext.setTitle("Home");
        appContext.setIcon(faHouseUser);
        appContext.setClasses({ body: "use-state-main" });
    }, []);
    
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    const defaultLineGraphTitle = 'Total Transactions';
    let _;
    let stateTitles = [
        defaultLineGraphTitle,
        'Total Sales Volume'
    ];

    const submissionsReportKey = 'master-submissions';
    const updatesReportKey = 'master-updates';

    const [errors, setErrors] = useState({ recentSales: null, chartData: null, errorState: 0, count: 0 });
    const [chartState, setChartState] = useState({title: defaultLineGraphTitle, state: 0});
    const [dashboardData, setDashboardData] = useState(ReportingService.instance.dashboard || null);
    const [recentSales, setRecentSales] = useState(null);
    const [saleReportItems, setSaleReportItems] = useState(null);
    const [filterText, setFilterText] = useState('');

    const [currentPage, setCurrentPage] = useState(0);
    const [user, setUser] = useState(AuthenticationService.instance.session?.user || null);
    const pageController = useRef(new PagerController(setCurrentPage, 16)).current;

    let startDate = new Date().addDays(-14);

    const getDashboardDataAsync = async (force) => {
        if (!!dashboardData && !force) {
            //console.log('Got Cached Dashboard Data')
            return;
        }

        console.warn("TODO: GetDashboard Data");
    };

    const getPortalData = (force) => {
        _ = getDashboardDataAsync(force);
    }

    const getPortalDataAsync = async (force) => {
        return Promise.all([
            getDashboardDataAsync(force),
        ]);
    }

    const getSalesReportItemsAsync = async (force) => {
        if (!!saleReportItems && saleReportItems.length > 0 && !force) return saleReportItems;
        console.warn("TODO: Sales Report Item Data");
    };

    const getRecentSalesAsync = async (force) => {
        if (Array.isArray(recentSales) && !force) return recentSales;
        const saleItems = await SaleService.instance.getSalesAsync().catch((ex) => { 
            setErrors({...errors, count: errors.count + 1, recentSales: new ErrorModel(ex).message || "Error when getting recent sales"});
            return null;
        });
        
        if (!saleItems) return;
        
        setRecentSales(saleItems);
    };

    const refreshAsync = async (force) => {
        return Promise.all([
            getRecentSalesAsync(force),
            getSalesReportItemsAsync(force),
            getPortalDataAsync(force),
        ]);

    };
    
    useEffect(() => {
        _ = PartnerService.instance.getPartnersAsync();
        _ = getRecentSalesAsync();
        _ = getSalesReportItemsAsync();
        _ = getPortalDataAsync();
        
        if (!Array.isArray(recentSales) && Object.keys(errors).length > 0) {
            // First time loading...
            console.error("Clearing Error: " + errors.count);
            setErrors({ recentSales: null, chartData: null, errorState: 0, count: errors.count + 1 });
        } else { 
            //
        }
        
    }, [recentSales]);

    const toggleState = (e) => {
        let newState = {title: defaultLineGraphTitle, state: 0};
        if (chartState.state === 0) {
            newState.state = 1;
        }

        newState.title = stateTitles[newState.state];
        setChartState(newState);
    };

    const setLineGraphState = (state) => {
        if (state === chartState.state) return;

        let newState = {title: stateTitles[state], state: state};
        setChartState(newState);
    };

    const counts = {};
    const volume = {};
    const partners = {};

    const salesLoaded = Array.isArray(recentSales);
    const showNumbers = salesLoaded || !!errors.recentSales;
    
    const snapShot = {
        count: -1,
        volume: -1,
        partnerCount: -1
    };
    
    if (showNumbers) {
        snapShot.count = 0;
        snapShot.volume = 0;
        snapShot.partnerCount = 0;
    }
    
    const d = new Date().addDays(-190);

    const countData = salesLoaded ? recentSales.map((s) => {
        if (s.created < d) return s.total;

        const dateKey = s.created.formatDate('yyyy-MM-dd');
        
        const pKey = s.partnerId;

        if (!counts[dateKey]) counts[dateKey] = 0;
        if (!volume[dateKey]) volume[dateKey] = 0;

        if (!partners[pKey]) {
            snapShot.partnerCount++;
            partners[pKey] = true;
        }

        counts[dateKey]++;
        volume[dateKey] += s.total;

        if (snapShot.volume < 0) snapShot.volume = 0;
        if (snapShot.count < 0) snapShot.count = 0;

        snapShot.volume += s.total;
        snapShot.count++;

        return s.total;
    }) : [];

    let items = [];

    for (let key in counts) {
        const item = {date: key, count: counts[key], volume: volume[key]};
        items.push(item);
    }

    items = items.sort((a, b) => {
        return a.date > b.date ? 1 : -1;
    });

    const field = chartState.state === 0 ? "count" : "volume";
    const lineChartData = items.map(item => item[field]);

    const lineColor = chartState.state === 0 ? "#CCDD88" : "#AAFFAA";
    const lineData = ReportItemModel.createLineChartSeries(lineChartData, stateTitles[chartState.state], lineColor);
    const labels = items.map((d) => d.date);

    const salesDataSets = [lineData];
    const currentState = 0; //chartState.state

    let salesLineData = {
        labels: labels, // ['VISA', 'MasterCard', 'Discover', 'Amex'],
        datasets: salesDataSets // chartState.state === 1 ? countsDataSets : salesDataSets
    };

    const controlSelections = ['', ''];

    controlSelections[chartState.state] = 'selected';

    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>{stateTitles[0]}</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>{stateTitles[1]}</a>
    </span>)

    const welcomeMessage = !!user ? (user?.gender === 'F' ? 'Welcome, ' + user?.firstName : 'Welcome, Mr. ' + user?.lastName) : 'Welcome, Fellow Traveller';
    const cardColumnStyle = {width: '120px'};

    const filterResults = (items) => {
        if (!items || !salesLoaded || items.length === 0) {
            return [];
        }

        return items.filter((saleItem) => {
            if (!filterText || filterText.length < 3) return true;
            const ft = filterText.toLowerCase();

            if (CustomerModel.searchFor(saleItem.customer, ft)) return true;
            if (PartnerModel.searchFor(saleItem.merchant, ft)) return true;

            return (saleItem.searchFor(ft));
        });
    };

    const getEmptySalesBody = () => {
        if (!!errors.recentSales)
            return (<div className={"error-box"}>{errors.recentSales} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a onClick={() => setRecentSales(Math.random())}><FontAwesomeIcon icon={faSync} /> Reload Sales</a></div>);
        
        return (<LoadingBox />);
    };
    
    const salesList = filterResults(recentSales);
    
    const salesElements = !salesLoaded ? (<tr>
        <td colSpan={4} className={"loading"}>{ getEmptySalesBody() }</td>
    </tr>) : pageController.mapLineItems(salesList, (item, index) => {
        
        const saleMerchant = PartnerService.instance.partnerMap[item.partnerId]?.merchants?.find((m) => m.locations.find((l) => l.id === item.locationId));
        const mid = saleMerchant?.id || (item.merchantId || null);
        const partnerPath = "/partners/" + item.partnerId + "/sales/" + item.id;
        const detailsPath = partnerPath + (!!mid ? "/with-merchant/" + mid : "");
        
        return (
            <tr key={"recent-sale-" + index}>
                <td style={cardColumnStyle}>
                    <Link to={detailsPath}>#{item.saleNumber}</Link>
                </td>
                <td>{item.total.formatCurrency(2)}</td>
                <td>
                    <Link title={"Merchant: " + item.merchant?.name}
                          to={"partners/"+item.partnerId+"/merchants/" + saleMerchant?.id + "/locations/" + item.locationId + "/sales"}>
                        {item.location?.name || "No Name"}
                    </Link>
                </td>
                <td><DateTime value={item.created} time={true}/></td>
            </tr>);
    });

    const subtitle = (<><FontAwesomeIcon icon={faHandsClapping}/>{welcomeMessage} <RefreshButton onClick={async () => refreshAsync(true)}/></>);
    
    const filterElement = recentSales?.length > 2 ?
        (<SearchFilter onFilter={(text) => setFilterText(text)} label={"Search Recent Sales"}/>) : null;

    const dt = items.length > 0 ? items[0].date : new Date();

    const snapShotCount = typeof snapShot?.count === "number" && snapShot.count >= 0 ? 
        (<NumberDisplay value={snapShot.count} isComponent={true}/>) : 
        (<LoadingBox/>);
    
    const snapShotVolume = typeof snapShot?.volume === "number" && snapShot.volume >= 0 ?
        (<NumberDisplay value={snapShot.volume} type={"currency"} isComponent={true}/>) : 
        (<LoadingBox/>);
    
    const snapShotPartners = typeof snapShot?.partnerCount === "number" && snapShot.partnerCount >= 0 ?
        (<NumberDisplay value={snapShot.partnerCount} isComponent={true}/>) : (<LoadingBox/>);

    return (
      <>
          <br/>
        <h2 className={"welcome"}>
            Welcome, {user?.firstName || "Friend-o"}
            <FontAwesomeIcon icon={faHandSpock} />
        </h2>
        <p>
            Sales activity is relatively low.
            Only a small percentage of active partners have processed sales over the last week.
            Although no anomalies have been detected in the system.
        </p>

        <div className="dashboard-snapshot">
            <Link to={"/reporting/sales"} className="panel-box round8 rows" onClick={(e) => setLineGraphState(0)}>
                <ul>
                    <li>Month Sales Volume</li>
                    <li>Since Date:</li>
                    <li><DateTime value={dt} showRelativeDate={true} time={false} defaultValue={"Never"}/></li>
                </ul>
                <span className="large">{snapShotVolume}</span>
            </Link>

            <a className="panel-box round8 rows" onClick={(e) => setLineGraphState(1)}>
                <ul>
                    <li>Sale Count</li>
                    <li>Last Transaction on:</li>
                    <li><DateTime value={dt} showRelativeDate={true} defaultValue={"Never"}/></li>
                </ul>
                <span className="large">{snapShotCount}</span>
            </a>

            <Link className="panel-box round8 rows" to={"/partners"}>
                <ul>
                    <li>Active Partners</li>
                    <li>Newest Onboarded on:</li>
                    <li><DateTime value={new Date().addDays(-1)} showRelativeDate={true} defaultValue={"Never"}/>
                    </li>
                </ul>
                <span className="large">{snapShotPartners}</span>
            </Link>
        </div>

        <CardBrandLineGraph title={chartState.title} data={salesLineData} chartControls={controls}/>

        <div id="recent-sales">
            <h2 className={"has-search"}>
                Recent Sales {filterElement}
            </h2>
            
            <table className="table-x-large" width="100%">
                <thead>
                <tr>
                    <th style={cardColumnStyle}>Sale #</th>
                    <th>Amount</th>
                    <th>Origination</th>
                    <th>Date</th>
                </tr>
                </thead>

                <tbody>
                {salesElements}
                </tbody>
            </table>

            <Pager controller={pageController} items={salesList}/>

        </div>
    </>);

}

export default Main;
