import React, {useEffect, useRef, useState} from 'react';
import {
    useOutletContext,
    useParams
} from "react-router-dom";
import PartnerService from "../../components/partners/services/PartnerService";
import PagerController from "../../components/common/ui/pager/PagerController";
import PartnerWebhookModel from "../../components/partners/models/PartnerWebhookModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import PartnerWebhooksSubMenu from "../../components/partners/ui/PartnerWebhooksSubMenu";
import PartnerMasterPage from "./PartnerMasterPage";
import LoadingBox from "../../components/common/ui/LoadingBox";
import DateTime from "../../components/formatters/DateTime";
import SaleService from "../../components/sales/services/SaleService";
import WebhookService from "../../components/webhooks/services/WebhookService";

const WebhookLogsScreen = (props) => {
    const partner = useOutletContext().partner;

    let [webhookLogs, setWebhookLogs] = useState(null);
    let _;

    useEffect(() => {
        _ = getPartnerWebhookLogsAsync();
    }, [])

    const getPartnerWebhookLogsAsync = async () => {
        await WebhookService.instance.getWebhookLogsAsync(partner.id).then((l) => {
            if (!!l) setWebhookLogs(l);
        });
    }

    const webhookElements = webhookLogs === null ? (<tr>
        <td colSpan={3}><LoadingBox/></td>
    </tr>) : webhookLogs?.map((log, index) => {
        return (
            <tr key={"webhook-log-item-" + index}>
                <td className={"webhook"}>{log.webhookType}</td>
                <td className={"response"}>{log.responseJson ?? "no response"}</td>
                <td className={"url"}>{log.url}</td>
                <td className={"date-time"}><DateTime value={log.timestamp} time={true}/></td>
            </tr>
        );
    });


    const webhookMenu = partner ? (<PartnerWebhooksSubMenu selection={"logs"} partner={partner}/>) : null;

    return (
        // <PartnerMasterPage onPartner={onPartner} title={partner?.name + ": Posted Webhook Logs"} selection={"webhooks"}>
           <>
           <h2>
               { partner?.name }
               <span className="subtitle">Webhook Logs</span>
           </h2>
            {webhookMenu}
            <div>
                <table className={"table x-large logs"} width={"100%"}>
                    <thead>
                    <tr>
                        <th className={"webhook"}>Webhook</th>
                        <th className={"response"}>Response</th>
                        <th className={"url"}>URL</th>
                        <th className={"date-time"}>DateTime</th>
                    </tr>
                    </thead>

                    <tbody>
                    {webhookElements}
                    </tbody>
                </table>
            </div>
           </>
        // </PartnerMasterPage>
    );
};

export default WebhookLogsScreen;
