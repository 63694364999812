import HttpService from "../../common/services/HttpService";
import TerminalModel from "../models/TerminalModel";

class TerminalService { 
    static instance;
    
    constructor() {
        this.terminalMap = {};
        this.terminalsMap = {};
        this.terminals = null;
    }
    
    async getTerminalsAsync() {
        const path = "/api/terminals";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            me.terminals = TerminalModel.fromJsonArray(rsp.data);
            me.terminals.map((terminal) => me.terminalMap[terminal.serialNumber] = terminal);
            
            return me.terminals;
        });
    }

    async getTerminalsByMerchantAsync(merchantId) {
        if (typeof merchantId !== 'string' || merchantId.length < 30) throw new Error("Merchant id is required: " + merchantId);
        
        const path = "/api/merchant/" + merchantId + "/terminals";
        const me = this;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            me.terminalsMap[merchantId] = TerminalModel.fromJsonArray(rsp.data);
            return me.terminalsMap[merchantId];
        });
    }
    
    async getTerminalsByPartnerAsync(partnerId) {
        if (typeof partnerId !== 'string' || partnerId.length < 30) throw new Error("Partner id is required: " + partnerId);
        const path = "/api/partner/" + partnerId + "/terminals";
        const me = this;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            me.terminalsMap[partnerId] = TerminalModel.fromJsonArray(rsp.data);
            return me.terminalsMap[partnerId];
        });
    }
    
    async getTerminalAsync(serialNumber) {
        
    }
    
    async updateTerminalAsync(terminal){
        if(!terminal)
            throw new Error("Terminal is required");
        
        const path = "/api/terminals/" + terminal.serialNumber;
        const me = this;

        return await HttpService.instance.putAsync(path, TerminalModel.toJson(terminal)).then((rsp) => {
            me.terminalsMap[terminal.partnerId] = TerminalModel.fromJsonArray(rsp.data);
            
            return me.terminalsMap[terminal.partnerId];
        });
    }
    
    async createTerminalAsync(terminal) {
        if (!terminal) throw new Error("Terminal is required");

        const path = "/api/terminals";
        const me = this;
        const data = TerminalModel.toJson(terminal);

        return await HttpService.instance.postAsync(path, data).then((rsp) => {
            const newTerminal = new TerminalModel(rsp.data);
            
            if (!!newTerminal.serialNumber) {
                if (Array.isArray(me.terminalsMap[newTerminal.partnerId]))
                    me.terminalsMap[newTerminal.partnerId]?.push(newTerminal);
                
                return newTerminal;
            }
            
            return null;
        });
    }
    
    async deleteTerminalAsync(terminal) {
        if (!terminal)
            throw new Error("Terminal is required");

        const path = "/api/terminals/" + terminal.serialNumber;
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((rsp) => {
            me.terminalsMap[terminal.partnerId] = null
        });

    }
        
}

export default TerminalService;
