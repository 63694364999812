import React, {
    useEffect,
    useState,
    useRef,
    useContext
} from 'react';
import DateTime from "../../components/formatters/DateTime";
import Pager from "../../components/common/ui/pager/Pager";
import { Link } from "react-router-dom";

import PartnerService from "../../components/partners/services/PartnerService";
import PagerController from "../../components/common/ui/pager/PagerController";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import LoadingBox from "../../components/common/ui/LoadingBox";
import AppContext from "../../AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faCirclePlus, faUserPlus} from "@fortawesome/free-solid-svg-icons";

const PartnersScreen = () => {
    const appContext = useContext(AppContext);

    useEffect(() => {
        appContext.setTitle("Partners");
    }, []);
    
    const [partners, setPartners] = useState(PartnerService.instance.partners || null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterTerm, setFilterTerm] = useState("");
    
    let _;
    const pageController = useRef(new PagerController(setCurrentPage, 20)).current;

    const getPartnersAsync = async (force) => {
        if (!force && !!partners && Array.isArray(partners) && partners.length > 0)
            return partners;
        
        return await PartnerService.instance.getPartnersAsync().then((partners) => {
            setPartners(partners);
            return partners;
        }).catch((err) => {
            console.error(err);
        });        
    }
    
    const onFilter = (filter) => {
        setFilterTerm(filter);
    };

    useEffect(() => {
        //
    }, [partners]);

    useEffect(() => {
        appContext.setIcon(faAddressCard);
        
        _ = getPartnersAsync();
        
    }, []);

    const filteredPartners = !!filterTerm && filterTerm.length > 2 ? partners?.filter((partner) => partner.search(filterTerm)) : partners;
    
    const partnerElements = pageController.mapLineItems(filteredPartners, (partner, index) => {
        let path = '/partners/' + partner.id;
        let merchants = (<Link to={path + '/merchants'}>{partner.merchants?.length} Merchants</Link>);

        if (partner.merchants?.length === 1) {
            merchants = (<Link to={path + '/merchants/' + partner.merchants[0].id + "/details"}>{partner.merchants[0].name}</Link>);
        } else if (partner.merchants?.length === 0) {
            merchants = (<>No Merchants</>);
        }

        return (
            <tr key={index}>
                <td>
                    <Link to={path +"/details"}>{partner.name}</Link>
                </td>
                <td className="small-cell">{merchants}</td>
                <td>
                    <DateTime time={true} value={partner.created}/>
                </td>
            </tr>
        );
    });

    const doneLoading = partners?.length > 0;
    const filterElement = doneLoading ? 
        (<>
            <h3 style={{marginTop: "24px"}}><SearchFilter label={"Search Partners"} onFilter={onFilter} />
            <Link className={"add-new"} to={'/partners/new'}><FontAwesomeIcon icon={faCirclePlus} /> New Partner</Link></h3>
        </>
        ) : null;

    const body = doneLoading ? (<table width={"100%"} className={"table x-large partners"}>
        <thead>
        <tr>
            <th>Name</th>
            <th>Merchant</th>
            <th>Created</th>
        </tr>
        </thead>

        <tbody>
        {partnerElements}
        </tbody>
    </table>) : (<div className={"pad-v"}><LoadingBox/></div>);
    
    return (
           <div className={"has-filter"}>
               {filterElement}
               {body}
               
               <Pager controller={pageController} items={filteredPartners}/>
           </div>
    );
};

export default PartnersScreen;
