class UserSessionModel {
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.ip = json.ip_address;
        if (this.ip === "::1") this.ip = "localhost";
        
        this.userId = json.user_id;
        this.region = json.region || "";
        this.city = json.city || "";
        this.country = json.country || "";
        this.created = new Date(json.created);
        this.modified = !!json.modified ? new Date(json.modified) : null;
        
        this.location = json.city || "";
        
        if (!!this.city) {
            if (!!this.region) this.location += ", " + json.region;
        } else if (!!this.region) {
            this.location = json.region;
        }
    }

    static fromJsonArray(jsonArray) {
        return jsonArray.map((json) => new UserSessionModel(json));
    }
}

export default UserSessionModel;
