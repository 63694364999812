import React, {
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import UserService from "../../components/people/services/UserService";
import PagerController from "../../components/common/ui/pager/PagerController";
import {Link} from "react-router-dom";
import PhoneNumber from "../../components/formatters/PhoneNumber";
import DateTime from "../../components/formatters/DateTime";
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faCloudMoon, faCloudSun,
    faPersonRunning,
    faPersonWalking,
    faUserInjured, faUsers
} from "@fortawesome/free-solid-svg-icons";
import Pager from "../../components/common/ui/pager/Pager";
import RefreshButton from "../../components/common/ui/RefreshButton";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import CommonMasterPage from "../common/CommonMasterPage";
import LoadingBox from "../../components/common/ui/LoadingBox";
import AppContext
    from "../../AppContext";

const UsersScreen = (props) => {
    const appContext = useContext(AppContext);

    useEffect(() => {
        appContext.setTitle("Users");
    }, []);
    
    let [users, setUsers] = useState(UserService.instance.users || null);
    let [currentPage, setCurrentPage] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage)).current;

    let _;
    
    const refreshUsersAsync = async (e) => {
        return await UserService.instance.getUsersAsync().then((us) => {
            setUsers(us);
        });
    };

    useEffect(() => {
        appContext.setTitle("Users");
        appContext.setIcon(faUsers);
        _ = refreshUsersAsync();
    }, []);

    const nameStyle = { width: '24%' };
    const userNameStyle = { width: '22%' };
    const countStyle = { width: '12%' };
    const emailStyle = { width: '25%' };

    const userElements = users === null ? (<tr><td colSpan={4}><LoadingBox /></td></tr>) : pageController.mapLineItems(users, (user, index) => {
        let path = '/people/' + user.id + "/details";
        let cn = user.id === AuthenticationService.instance.session?.user?.id ? 'current' : '';
        let date = user.created;
        let dateIcon = faCloudMoon;
        
        if (!!user.lastLogin) { 
            date = user.lastLogin;
            dateIcon = faPersonRunning;
            
            let minuteDifference = (new Date().getTime() - user.lastLogin.toLocalTime().getTime()) / 60000;
            
            if (minuteDifference > 60) cn += ' active';
            else cn += ' super-active';
            
        } else if (!!user.modified) {
            date = user.modified;
            dateIcon = faCloudSun;
            cn += ' used';
        }
        
        return (
            <tr key={index} className={cn.trim()}>
                <td style={nameStyle}><Link to={path}>{user.name}</Link></td>
                <td className="phone-cell" style={userNameStyle}><Link to={path}>{ user.username }</Link></td>
                <td className="email-cell" style={emailStyle}>{user.email}</td>
                <td className={"date"}><DateTime time={true} value={date} useUtc={false} /> <FontAwesomeIcon icon={dateIcon} /></td>
            </tr>
        );
    });

    return (
        // <CommonMasterPage selector="users" icon={faPersonWalking} subTitle={"Super Admin Users"} title={"People"}>
        <>
            <h2 className={"form-only"}>
                Portal Users Master List
            </h2>
            <p>
                Below are all the Master Users in the system. These users have access to the Paylani and CardSync.io Portals as well.  Click on a user to view their details.
            </p>
            <table className="table-x-large" width="100%">
                <thead>
                <tr>
                    <th style={nameStyle}>Name</th>
                    <th className="phone-cell" style={userNameStyle}>Username</th>
                    <th className="email-cell" style={emailStyle}>Email</th>
                    <th>Last Activity</th>
                </tr>
                </thead>

                <tbody>
                { userElements }
                </tbody>

            </table>
            <Pager id="user-pager-bottom" controller={pageController} items={users} />
        </>  
        //</CommonMasterPage>
    );
};

export default UsersScreen;
