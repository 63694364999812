import PartnerModel from "./PartnerModel";
import UserModel from "../../people/models/UserModel";
import LocationModel from "./LocationModel";

class MerchantModel { 
    static json = {
        "id": "d29a5af9-f2cb-4cdd-829c-a139ded98b83",
        "partnerId": "0e1896bd-c5b7-4fd0-a959-ef58c35d32ed",
        "posMerchantId": null,
        "name": " 16068 Merchant",
        "logoUrl": null,
        "foregroundColor": null,
        "backgroundColor": null,
        "font": null,
        "phone": null,
        "email": null,
        "authByDefault": false,
        "website":null,
        "created": "0001-01-01T00:00:00",
        "locations": []
    };
    
    constructor(json, partner = null) {
        if (!json) json = {};
        
        this.id = (json.id || json.merchantId) || null;
        this.partnerId = json.partnerId;
        this.posMerchantId = json.posMerchantId;
        this.name = json.name || '';
        this.logoUrl = json.logoUrl || '';
        this.foregroundColor = json.foregroundColor || '';
        this.backgroundColor = json.backgroundColor || '';
        this.font = json.font || '';
        this.phone = json.phone || '';
        this.email = json.email || '';
        this.authByDefault = json.authByDefault || false;
        this.website = json.website || '';
        this.created = json.created ? new Date(json.created) : null;
        
        this.locations = LocationModel.fromJsonArray(json.locations);
    }

    findLocation(locationId) {
        if (typeof locationId !== "string" || locationId.length < 30 || !this.locations?.length)
            return null;
        
        return this.locations.find((location) => location.id === locationId);
    }
    
    trimmedName(maxLength, ellipsis = '...') { 
        if (!this.name) return this.name;
        if (typeof maxLength !== 'number' || maxLength > this.name.length) return this.name;
        
        return this.name.substring(0, maxLength) + ellipsis;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new MerchantModel(json));
    }
    toNewJson(){
        return {
            name: this.name,
            email: this.email,
            phone: this.phone,
            website: this.website,
        }
    }
    toJson() { 
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            website: this.website,
            logoUrl: this.logoUrl,
            foregroundColor: this.foregroundColor,
            backgroundColor: this.backgroundColor,
            font: this.font,
            authByDefault: this.authByDefault,
            partnerId: this.partnerId,
            posMerchantId: this.posMerchantId,
        };
    }
}

export default MerchantModel;