import UserModel from "../../people/models/UserModel";

class SessionModel { 
    static staticDevSessionId = "fd81cf33-0d02-4d3b-94ba-b224ca33796c";
    static staticStagingSessionId = "8AEA049B-C6E2-4DBE-A5A0-B49489FEEDE5";

    static staticSessionId = "8AEA049B-C6E2-4DBE-A5A0-B49489FEEDE5"; //"fd81cf33-0d02-4d3b-94ba-b224ca33796c";

    constructor(session) {
        this.id = session.id;
        this.ipAddress = session.ip_address;
        this.user = new UserModel(session.user);
        this.created = new Date();
    }
}

export default SessionModel;
