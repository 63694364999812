class ErrorModel {
    static focusInput(errorsObject, prefix = "", delay = 200) {
        const errorKeys = Object.keys(errorsObject);
        
        if (errorKeys.length > 0 && typeof errorKeys[0] === "string") {
            if (typeof delay !== "number" || delay < 0 || delay > 3000) delay = 200;
            setTimeout(() => document.getElementById(prefix + errorKeys[0])?.focus(), delay);
        }
        
        return errorsObject;
    }

    /**
     * 
     * @param err
     * @param isFormError {boolean|null} - If null, will be determined by the absence of "data" and "response" properties.
     */
    constructor(err, isFormError = null) {
        if (!err) err = {};
        else if (typeof err !== "object") {
            err = { message: err?.toString() || "An unknown error occurred" };
            
            if (isFormError !== false) {
                err.general = err.message;
                delete err.message;
                isFormError = true;
            }
        }
        
        this.error = {...err};
        this.key = err?.key || "";
        this.isFormError = isFormError || false;

        this.requiredFields = (err.required_fields || err.requiredFields) || null;
        if (typeof this.requiredFields !== "object" || !this.requiredFields) this.requiredFields = {};
        
        this.formError = (isFormError === true || (typeof err.data === "undefined" && typeof err.response === "undefined")) ?
            {...err} :
            {...(err.formError || err.form_error)} || {};

        this.message = err?.message || (this.formError?.general || "");
        this.messages = Array.isArray(err?.messages) ? err.messages : [];
        
        if (!!this.message && this.messages.length === 0)
            this.messages.push(this.message);
        
        this.parse();
        
        // if (this.isFormError) { 
        //     delete this.error;
        // }
    }

    parse(err = null) {
        if (!err) err = ErrorModel.cleanErrorFields(this.error || (this.isFormError ? this.formError : null));

        if (!err?.message) {
            err = ErrorModel.cleanErrorFields(err?.response?.data || err?.data || {});

            this.message = err?.message || "An unknown error occurred.";
            this.messages = err?.messages || [];

            if (this.messages.length === 0) this.messages.push(this.message);
        }

        const switched = !this.isFormError;
        this.isFormError = this.isFormError || (!!this.formError && Object.keys(this.formError).length > 0);

        console.warn("Parse: " + this.isFormError);

        if (this.isFormError && switched) {
            if (!this.formError) this.formError = {};

            if (this.messages.length > (this.formError.messages?.length || 0))
                this.formError.messages = [...this.messages];

            if (this.message !== this.formError.message) {
                this.formError.message = this.message;
            }

        } else {
            console.error(JSON.stringify(err, null, 4));
        }

        return (this.message?.length > 0 || this.isFormError);
    }
    
    hasFormErrors() { 
        return Object.keys(this.formError || {}).length > 0;
    }
    
    focus(prefix = "", delay = 200) { 
        return ErrorModel.focusInput(this.formError, prefix, delay);
    }
    
    validateForm(form) {
        //
    }
    

    /**
     * Creates an object with form field names as keys (field), and error messages as values (message).
     * @param ex { object|object[]|string|string[]|null} - Usually an error that came from an API call.
     * @param formElementIdPrefix {string|null} - If included, it will call try to find the element with the given ID and focus it.
     * @param defaultError {string} - If no error message is found, this will be used.
     * @param defaultField {string} = If no field name is found, this will be used.
     * @returns {{}|null}
     */
    static createFormError(ex, formElementIdPrefix = null, defaultError = "An unknown error happened. Check logs for details.", defaultField = "general") {
        let data = ErrorModel.cleanErrorFields(ex?.response?.data || (ex?.data || ex));
        
        let er = {};

        if (Array.isArray(ex) && ex?.length > 0) {
            const items = typeof ex[0] === "string" ? 
                ex.map((x) => { return { message: x, field: defaultField }; }) :
                ex;
            
            data = { objects: items };
            
        } else if (typeof ex === "string") { 
            data = { objects: [{ message: ex, field: defaultField }] };
        } else if (typeof data === "string") { 
            data = { objects: [{ message: data, field: defaultField }] };
        }
        
        if (!data) er.general = ex?.message || defaultError;
        else {
            const objects = data?.objects;

            if (Array.isArray(objects) && objects.length > 0) {
                for(let i = 0; i < objects.length; i++) {
                    let f = objects[i];
                    
                    if (typeof f === "string") {
                        f = { message: f, field: defaultField };
                    } else if (typeof f === "object") { 
                        if (!f.message) f.message = f?.error || defaultError;
                        if (!f.field) f.field = (f.field_name || f.fieldId) || ((f.field_id || defaultField) || (f.field_key || f.key));
                    } else {
                        console.warn("Error parser: unknown object type: " + (typeof f).toString() + " (" + f?.toString() + "). Skipping");
                        continue;
                    }
                    
                    if (f.message && f.field) {
                        let existing = !!er[f.field] ? er[f.field] + ". " : "";
                        er[f.field] = existing + f.message;
                    }
                }
            } else er.general = data?.message || defaultError;

            if (Object.keys(er).length === 0)
                er.general = data?.message || defaultError;
        }
        
        if (typeof formElementIdPrefix === "string" && formElementIdPrefix.length > 0) {
            ErrorModel.focusInput(er, formElementIdPrefix);
        }
        
        return er;
    }
    
    static cleanErrorFields(data) {
        if (typeof data !== "object" || !data)
            return data;
        
        if (data["Message"] && !data.message) {
            data.message = data["Message"];
            delete data["Message"];
        }

        return data;
    }
}

export default ErrorModel;
