import React from 'react';
import {
    Link,
    useLocation
} from "react-router-dom";
import {
    faAnchor,
    faIdCardClip,
    faBuildingWheat,
    faLock,
    faCheckToSlot,
    faMobileRetro
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PartnerSubMenu = ({ partnerId, partner, loading }) => {
    const location = useLocation();
    const pid = (partner?.id || partnerId) || null;

    if (!pid || pid.length < 30) { 
        return typeof loading === "string" ? 
            (<span>{ loading }</span>) :
            (<span>Loading...</span>);
    }
    
    return (
        <div className="sub-column">
            <h4>
                <span>Partner:</span>
                { partner?.name }
            </h4>
            <ul>
                <li  className={(location.pathname.startsWith('/partners/' + pid + '/details') || location.pathname.startsWith('/partners/' + pid + '/account-details')) ? 'selected':''}><Link to={ '/partners/' + pid + '/details'}><FontAwesomeIcon icon={faIdCardClip} /> Overview</Link></li>
                <li className={location.pathname.startsWith('/partners/' + pid + '/merchants') ? 'selected':''}><Link to={ '/partners/' + pid + '/merchants'}><FontAwesomeIcon icon={faBuildingWheat} /> Merchants</Link></li>
                <li  className={location.pathname.startsWith('/partners/' + pid + '/terminals') ? 'selected':''}><Link to={ '/partners/' + pid + '/terminals'}><FontAwesomeIcon icon={faMobileRetro} /> Terminals</Link></li>
                <li  className={location.pathname.startsWith('/partners/' + pid + '/sales') ? 'selected':''}><Link to={ '/partners/' + pid + '/sales'}><FontAwesomeIcon icon={faCheckToSlot} /> Sales</Link></li>
                <li  className={location.pathname.startsWith('/partners/' + pid + '/webhooks') ? 'selected':''}><Link to={ '/partners/' + pid + '/webhooks'}><FontAwesomeIcon icon={faAnchor} /> Webhooks</Link></li>
                <li style={{display: 'none'}}  className={location.pathname.startsWith('/partners') ? 'selected':''}><Link to={ '/partners/' + pid + '/authentication'}><FontAwesomeIcon icon={faLock} /> Authentication</Link></li>
            </ul>
        </div>
    );
};

export default PartnerSubMenu;
