import React, {
    useState
} from "react";
import PartnerWebhookModel
    from "../models/PartnerWebhookModel";
import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import {
    faEdit
} from "@fortawesome/free-solid-svg-icons";
import webhookService
    from "../../webhooks/services/WebhookService";
import {
    useNavigate
} from "react-router-dom";

const PartnerWebhookForm = (props) => {
    const [webHook] = useState(props.webhook || new PartnerWebhookModel());
    const [isEditable, setIsEditable] = useState(false);
    const [webhookUrl, setWebhookUrl] = useState(webHook.url || "");
    const navigate = useNavigate();

    const cancelEditor = (e) => {
        setIsEditable(false);
    }

    const updateWebhookAsync = async () => {
        const updatedWebhook = webHook;
        updatedWebhook.url = webhookUrl;

        await webhookService.instance.updatePartnerWebhooksAsync(webHook.partnerId, [updatedWebhook]).then((rsp) => {
            cancelEditor();
        });
    }

    const cn = isEditable ? " show" : "";

    const changeIsEditable = () => {
        setIsEditable(!isEditable);
    }
    
    const handleChange = (event) => {
        setWebhookUrl(event.target.value);
    }
    
    return <>
        <div
            key={webHook.webhookType}
            className={"webhook-item" + cn}>
                <span
                    className={"hover-edit float-right padded"}>
                    <a onClick={(e) => changeIsEditable()}><FontAwesomeIcon
                        icon={faEdit}/> Edit</a>
                </span>
            <div
                className={"webhook-item-display"}>
                <h4>{webHook.webhookType.replaceAll(".", " ").replace("Webhook", "")}</h4>
                <p>{webHook.url || "No webhook set"}</p>
            </div>
            <div
                className={"webhook-url"}>
                <label htmlFor={"webhook-url-" + webHook.webhookType}>WebHook Post-to Url: </label>
                <div className="webhook-url-inputs">
                    <input
                        value={webhookUrl}
                        onChange={handleChange}
                        type={"text"}/>

                    <button
                        onClick={(e) => updateWebhookAsync(e, webHook)}>Update
                    </button>
                    <a onClick={cancelEditor}>Cancel</a>
                </div>
            </div>
        </div>
    </>;
};

export default PartnerWebhookForm;