import HttpService from "../../common/services/HttpService";
import DateTime from "../../formatters/DateTime";

class ReportingService { 
    static instance = new ReportingService();
    
    constructor() {
        this.reportMap = {};
        this.partnerCardBrandMap = {};
        this.dashboard = null;
        this.dashboardDate = null;
    }

}

export default ReportingService;
