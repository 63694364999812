import React from 'react';

const LoadingBox = (props) => {
    const {children, size} = props;

    return (
        <span className={"loading-box spinner size32"}>
            <label className={"spinner-label"}>{ children || "Loading"}</label>
            <span className={"spinning"}><label></label></span>
        </span>);
};

export default LoadingBox;
