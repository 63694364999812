import React, {
} from 'react';
import LoginForm from "../../components/authentication/ui/LoginForm";

const LoginScreen = (props) => {
    return (
        <div id="main">
            <div id="main-body-login">
                <LoginForm onAuthenticate={props.onAuthenticate} />
            </div>
        </div>
    );
};

export default LoginScreen;
