import VisaCard from "../ui/CreditCards/VisaCard";

class CreditCardModel {
// --visa-color: #AAA9FF88;
// --mastercard-color: #EED02D88;
// --discover-color: #CE702D88;
// --amex-color: #6666FF77;

    static cardDescriptors = {
        visa: { color: VisaCard.color, name: "VISA", longName: "VISA" },
        mastercard: { color: '#EED02D', name: 'MasterCard', longName: 'MasterCard' },
        discover: { color: '#CE702D', name: 'Discover', longName: 'Discover' },
        amex: { color: '#6666FF', name: 'American Express', longName: 'American Express' }
    }

    static cardKeys = ['visa', 'mastercard', 'amex', 'discover'];

    static createExpiration(expiration) {
        let exp = {year: (new Date()).getFullYear(), month: 1};

        if (!expiration) return exp;

        expiration = expiration.replace(/\D/g,'');
        if (expiration.length !== 4) return exp;

        exp.month = parseInt(expiration.substring(0, 2));
        exp.year = parseInt(expiration.substring(2));

        if (isNaN(exp.month)) exp.month = 1;
        if (isNaN(exp.year)) exp.year = (new Date()).getFullYear();

        if (exp.year < 2000) exp.year += 2000;

        return exp;
    }

    static createMockCreditCardJson() {
        return {
            number: "4111111111111111",
            name: "Mr. Anderson",
            expires: "12/24",
            cvv: '',
            card_mask: '4 *** **** **** 1111',
            card_type: 4
        };
    }

    static getCardType(number, defaultType) {
        if (!defaultType || typeof defaultType !== 'number') defaultType = 0;

        if (!number) return defaultType;
        if (number.match(/[^\d]+/)) return defaultType;

        let cardType = parseInt(number.substring(0, 1));

        if (isNaN(cardType)) cardType = defaultType;
        if (number.length < 2) return cardType;

        let prefix = number.substring(0, 2);

        if (prefix === "34" || prefix === "37") {
            cardType = 3; //"American Express";
        } else if (prefix === "51" || prefix === "52" || prefix === "53" || prefix === "54" || prefix === "55") {
            cardType = 5; //"MasterCard";
        } else if (prefix === "4") {
            cardType = 4; // "Visa";
        }

        return cardType;
    }

    constructor(json) {
        if (typeof json === 'undefined') json = {};

        this.id = json.id || '';
        this.number = json.number;
        this.name = json.name;

        let exp = CreditCardModel.createExpiration(json.expires);
        this.expirationMonth = exp.month;
        this.expirationYear = exp.year;

        this.expires = json.expires;
        this.cvv = json.cvv;
        this.cardType = json.cardType || CreditCardModel.getCardType(json.number);
    }

    getCardMask() {
        if (!this.number) return '';

        let firstDigit = '*';
        let unmasked = ('****' + this.number);
        unmasked = unmasked.substring(unmasked.length - 4);

        if (this.number.length > 4)  firstDigit = this.number.substring(0, 1) + ' ';

        return firstDigit + '*** **** **** ' + unmasked;
    }

    toJson = () => {
        let exp = CreditCardModel.createExpiration(this.expires) || {};

        return {
            number: this.number || '',
            name: this.name || '',
            expires: this.expires || '',
            card_type: this.cardType || 0,
            card_mask: this.getCardMask() || '',
            expiration_month: exp.month,
            expiration_year: exp.year
        };
    }
}

export default CreditCardModel;
