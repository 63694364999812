import React, {
    useState,
    useEffect,
    Children,
    useContext
} from 'react';
import {
    Outlet,
    useParams
} from "react-router-dom";
import {
    faAddressCard,
    faGhost
} from "@fortawesome/free-solid-svg-icons";

import MasterScreen
    from "../../MasterScreenMain";
import PartnerSubMenu
    from "../../components/partners/ui/PartnerSubMenu";
import PartnerService
    from "../../components/partners/services/PartnerService";
import AppContext
    from "../../AppContext";
import MerchantSubMenu
    from "../../components/partners/ui/MerchantSubMenu";
import HttpService
    from "../../components/common/services/HttpService";

const MerchantMasterPage = (props) => {
    const appContext = useContext(AppContext);
    const { partnerId, merchantId } = useParams();
    const { onPartnerChange } = props;
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || null);
    const merchant = partner?.merchants?.find((m) => m.id === merchantId);
    let _;
    
    const outletContext = {
        partner: partner,
        merchant: merchant,
        name: "MerchantMasterPage",
        refreshAsync: async (partnerService) => {
            await (partnerService || PartnerService.instance)?.getPartnersAsync();
            const p = PartnerService.instance.partnerMap[partnerId];
            if (!!p) setPartner(p);
        },
    };

    if (!!partnerId) HttpService.instance.setPartnerId(partnerId);

    const getPartnerAsync = async (force = false) => {
        if (!partnerId) { 
            console.error("No PartnerId when getting partner from server.");
            return (partner || null);
        }
        
        let p = PartnerService.instance.partnerMap[partnerId];
        if (!!p?.id && !force) return partner;

        p = await PartnerService.instance.getPartnerAsync(partnerId);
        
        if (!!p?.id) {
            if (typeof onPartnerChange === "function") onPartnerChange(p);
            setPartner(p);
        }
        
        return p || (partner || null);
    };
    
    useEffect(() => {
        console.warn("UseEffect Merchant MasterPage with partnerId: " + partner?.id);
        _ = getPartnerAsync();
        appContext.setTitle("Merchants");
    }, [partner]);
    
    const outletElement = !!partner ? (<Outlet context={outletContext} />) : null;
    
    return (
        <div className="details-body">
            <MerchantSubMenu partner={partner} merchant={merchant}/>
            <div className={"pad"}>
                { outletElement }
            </div>
        </div>
    );

};

export default MerchantMasterPage;