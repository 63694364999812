import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    Link,
    useNavigate,
    useOutletContext,
    useParams
} from "react-router-dom";
import PagerController
    from "../../components/common/ui/pager/PagerController";
import DateTime
    from "../../components/formatters/DateTime";
import SaleService
    from "../../components/sales/services/SaleService";
import LoadingBox
    from "../../components/common/ui/LoadingBox";
import "../../components/sales/ui/Sales.css";
import HttpService
    from "../../components/common/services/HttpService";
import BackButton
    from "../../components/common/ui/BackButton";
import appContext from "../../AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import Controller from "../../components/common/services/Controller";

const SalesScreen = (props) => {
    const { partnerId, merchantId, locationId } = useParams();
    const { partner, merchant, location } = useOutletContext();
    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false);
    const [sales, setSales] = useState(null);
    let _;

    const [currentPage, setCurrentPage] = useState(0);
    const pageController = useRef(new PagerController(setCurrentPage, 12)).current;
    const [scroller, setScroller] = useState(Controller.createScrollController());
    
    const isLocation = !!locationId;
    const isMerchant = !isLocation && !!merchantId;
    const isPartner = !isMerchant && !!partnerId;
    
    const getPartnerSalesAsync = async (force) => {
        if (!force && sales?.length > 0) return sales;
        
        if (isLocation) {
            await SaleService.instance.getSalesByLocationAsync(locationId).then((ss) => {
                if (!!ss) setSales(ss);
            });
        } else if (isMerchant) {
            await SaleService.instance.getSalesByMerchantAsync(merchantId).then((ss) => {
                if (!!ss) setSales(ss);
            });
        } else
            await SaleService.instance.getSalesByPartnerAsync(partnerId).then((ss) => {
                if (!!ss) setSales(ss);
            });
    }

    useEffect(() => {
        scroller.scroll();
        _ = getPartnerSalesAsync();
    }, []);

    const locationMap = {};

    if (partner?.merchants?.length > 0) {
        const locations = partner.merchants.map((m) => m.locations).flat();

        locations.forEach((loc) => {
            locationMap[loc.id] = loc;
        });
    }

    const onRowClicked = (sale, e) => {
        if (e?.target?.tagName === "A") {
            //e?.preventDefault();
            //e?.stopPropagation();
            return true;
        }
        
        let url = "/partners/" + partner.id + "/sales/" + sale.id + "/with-merchant/" + merchant?.id;

        if (location) url = "/partners/" + partner.id + "/merchants/" + merchant.id + "/locations/" + location.id + "/sales/" + sale.id;
        if (merchant) url = "/partners/" + partner.id + "/merchants/" + merchant.id + "/sales/" + sale.id;

        navigate(url);
    }

    const partnerMerchantId = partner?.merchants?.length === 1 ? partner.merchants[0].id : null;
    
    const saleElements = sales === null ? (
        <tr>
            <td></td>
            <td colSpan={5}>
                <LoadingBox/>
            </td>
            <td></td>
        </tr>) : sales.length === 0 ?
        <tr>
            <td></td>
            <td colSpan={3}>No sales found</td>
            <td></td>
        </tr> : sales?.map((sale, index) => {
            const location = locationMap[sale.locationId || ""] || null;
            const saleMerchantId = (sale.merchantId || partnerMerchantId) || (merchantId || sale.merchant?.id);
            const pathExtension = saleMerchantId ? "/with-merchant/" + saleMerchantId : "";

            const locationSalesPath = "/partners/" + partnerId + "/merchants/" + saleMerchantId + "/locations/" + sale.locationId + "/sales";
            const locationName = location?.name || "No Location Returned";
            const locationElement = !isLocation && !!sale.locationId ? (<Link to={locationSalesPath}>{locationName}</Link>) : (<>{locationName}</>);
            
            return (
                <tr onClick={(e) => onRowClicked(sale, e)} key={"sale-item-" + index}>
                    <td className={"amount"}><Link to={"/partners/" + partnerId + "/sales/" + sale.id + pathExtension}>{sale.total.formatCurrency(2)}</Link></td>
                    <td className={"sale-location"}>{locationElement}</td>
                    <td className={"trans-count"}>{sale.transactions?.length || -1}</td>
                    <td className={"date-time"}><DateTime value={sale.created} /></td>
                    <td className={"sale-link"}>
                        <Link to={"/partners/" + partnerId + "/sales/" + sale.id + pathExtension}>Details</Link> | <a
                            href={HttpService.instance.getUrl("/api/partners/" + partnerId + "/sales/" + sale.id + "/receipt")}
                            target="_blank">Receipt <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                    </td>
                </tr>
            );
        });

    const description = location ? 
        "Sales for " + location.name :
        (merchant ? (<>Sales for {merchant.name}</>) : (<>Combined sales made by all merchants and locations for <strong>{ partner?.name }</strong></>));

    const backButton = isLocation ? null : 
        <BackButton path={isMerchant ? "/partners/" + partnerId + "/merchants" : "/partners"} />;
    
    const saleType = isLocation ? "Location " : (isMerchant ? "Merchant " : "Partner ");
    
    return (
        <>
            <h2>
                {isLocation ? location?.name : isMerchant ? merchant?.name : partner?.name}
                <span className="subtitle">{ saleType }Sales</span>
            </h2>

            <p>{description}</p>

            <table className={"table x-large sales"} width={"100%"}>
                <thead>
                    <tr>
                        <th className={"amount"}>Amount</th>
                        <th className={"sale-location"}>Location</th>
                        <th className={"trans-count"}>Transactions</th>
                        <th className={"date-time"}>Date/Time</th>
                        <th className={"date-time"}>View</th>
                    </tr>
                </thead>

                <tbody>
                {saleElements}
                </tbody>
            </table>
        </>
    );
};

export default SalesScreen;
