import axios
    from "axios";
import DateTime
    from "../../formatters/DateTime";
import EnvironmentModel
    from "../models/EnvironmentModel";

class HttpService {
    static instance;
    static sessionKey = 'session-id';
    static adminToken = '';
    static partnerId = '';

    static emptyResponse = {
        data: {},
        message: 'no session id'
    };

    constructor() {
        this.onUpdate = null;
        this.ipAddress = "";
        this.sessionId = null;
        this.forceProduction = false;
        this.config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        EnvironmentModel.setEnvironment();

        this.onUnauthorizedResponse = (errorResponse) => {
        };
        // this.getIpAddressAsync();
    }

    getIpAddressAsync() {
        const me = this;
        this.getAsync("https://api.ipify.org/?format=json", true).then((rsp) => {
            let ip = rsp?.data?.ip;
            if (ip) {
                me.ipAddress = ip;
                me.config.headers['X-Forwarded-For'] = ip;
                me.config.headers['X-IP-Address'] = ip;
            }
            me.onUpdate('Ip: ' + ip);
        }).catch((err) => {
            console.warn('Error getting IP Address: ' + err);
            me.onUpdate('IpFailed');
        });

    }

    setAdminToken(adminToken) {
        console.log('Admin Token: ' + adminToken);
        this.adminToken = adminToken;
    }

    setPartnerId(partnerId) {
        this.partnerId = partnerId;
    }

    setSessionId(sessionId) {
        if (!sessionId || sessionId.length < 10) sessionId = null;

        this.sessionId = sessionId;

        if (!sessionId) delete this.config.headers[HttpService.sessionKey];
        else this.config.headers[HttpService.sessionKey] = sessionId;
    }

    handleError(errorResponse) {
        let statusCode = errorResponse?.status || 0;
        switch (statusCode) {
            case 0:
                console.warn('Aborted request');
                break;
            case 401:
                this.setSessionId(null);
                this.setAdminToken(null);
                if (typeof this.onUnauthorizedResponse === 'function')
                    this.onUnauthorizedResponse(errorResponse);
                break;
        }

        return HttpService.emptyResponse;
    }

    createUrlWithDateRange(path, startDate, endDate) {
        if (DateTime.isDate(startDate)) startDate = startDate.toDate().toFormDate();
        else startDate = '';

        if (DateTime.isDate(endDate)) endDate = endDate.toDate().toFormDate();
        else endDate = '';

        let qa = path.indexOf('?') > -1 ? '&' : '?';

        return path + qa + 'start-date=' + startDate + '&end-date=' + endDate;
    }

    async getWithDateRangeAsync(path, startDate, endDate) {
        let url = this.createUrlWithDateRange(path, startDate, endDate);
        console.log('Date Url: ' + url);
        return await HttpService.instance.getAsync(url);
    }

    async getAsync(url) {
        const me = this;
        url = this.getUrl(url);

        this.config.headers['AdminToken'] = this.adminToken;
        
        console.log("HttpGet: " + url);
        // console.log(JSON.stringify(this.config.headers, null, 4));

        if(this.partnerId)
            this.config.headers['PartnerId'] = this.partnerId;

        return await axios.get(url, this.config).catch((err) => {
            me.handleError(err.response);
        });
    }

    async postAsync(url, data) {
        this.config.headers['AdminToken'] = this.adminToken;

        if(this.partnerId)
            this.config.headers['PartnerId'] = this.partnerId;
        
        return await axios.post(this.getUrl(url), data, this.config);
    }

    async putAsync(url, data) {
        this.config.headers['AdminToken'] = this.adminToken;

        if(this.partnerId)
            this.config.headers['PartnerId'] = this.partnerId;
        
        return await axios.put(this.getUrl(url), data, this.config);
    }

    async deleteAsync(url) {
        this.config.headers['AdminToken'] = this.adminToken;
        
        if(this.partnerId)
            this.config.headers['PartnerId'] = this.partnerId;
        
        return await axios.delete(this.getUrl(url), this.config);
    }

    async getPaylaniWithDateRangeAsync(path, startDate, endDate) {
        let url = this.createUrlWithDateRange(path, startDate, endDate);
        console.log('Date Url: ' + url);
        return await HttpService.instance.getPaylaniAsync(url);
    }

    async getPaylaniAsync(url, isPublic) {
        if (!isPublic && !this.config.headers[HttpService.sessionKey]) {
            console.warn("No public or session id within the key: " + HttpService.sessionKey);
            return HttpService.emptyResponse;
        }

        const me = this;
        console.log(EnvironmentModel.environment.paylaniApiUrl);

        url = this.getPaylaniUrl(url);

        console.log("HttpGet: " + url);

        return await axios.get(url, this.config).catch((err) => {
            console.log(err);
            me.handleError(err.response);
        });
    }

    async postPaylaniAsync(url, data) {
        const me = this;
        return await axios.post(this.getPaylaniUrl(url), data, this.config);
    }

    async putPaylaniAsync(url, data) {
        const me = this;
        return await axios.put(this.getPaylaniUrl(url), data, this.config);
    }

    async deletePaylaniAsync(url) {
        const me = this;
        return await axios.delete(this.getPaylaniUrl(url), this.config);
    }

    getUrl(url) {
        return (url.startsWith("http") ? "" : EnvironmentModel.environment.baseUrl) + url;
    }

    getPaylaniUrl(url) {
        return (url.startsWith("http") ? "" : EnvironmentModel.environment.paylaniApiUrl) + url;
    }
}

export default HttpService;
