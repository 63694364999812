class SaleAmountModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.amount = json.amount || 0.0;
        this.name = (json.name || json.caption) || (json.description || "");
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new SaleAmountModel(json));
    }
}

export default SaleAmountModel;
