
import React, {useRef, useState} from 'react';

const FormButton = (props) => {
    let buttonId = props.id || 'form-button-' + Math.floor(Math.random() * 1000000).toString();
    let buttonRef = useRef(null);
    
    const notSpinning = 'ui-button';
    const spinning = 'ui-button spinning';
    const onButtonSubmit = props.onClick;
    
    let [buttonClassName, setButtonClassName] = useState(notSpinning);
    
    const onClick = async (e) => {
        if (typeof onButtonSubmit === 'function') {
            setButtonClassName(spinning);
            
            let rsp = await onButtonSubmit(e)
            if (!rsp || typeof rsp.then !== 'function') {
                setButtonClassName(notSpinning);
                return;
            }
            
            rsp.then(function (x) {
                setButtonClassName(notSpinning);
            }, function (err) {
                setButtonClassName(notSpinning);
            })
        } else {
            console.warn('Button with id ' + buttonId + ' was clicked but no onClick handler was provided.');
        }
    };
    
    let label = props.label || (props.children || 'Okay');
    
    return (
        <button id={buttonId} className={buttonClassName} ref={buttonRef} onClick={onClick}>
            <span></span>
            <span>
                {label}
            </span>
            <span><label></label></span>
        </button>
    );
};

export default FormButton;
