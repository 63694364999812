import HttpService
    from "../../common/services/HttpService";
import WebhookModel
    from "../models/WebhookModel";
import WebhookLogModel
    from "../models/WebhookLogModel";
import PartnerWebhookModel
    from "../../partners/models/PartnerWebhookModel";
import WebhookDataModel
    from "../models/WebhookDataModel";

class WebhookService {
    static instance = new WebhookService();

    async getWebhookLogsAsync(partnerId) {
        const url = '/api/partners/' + partnerId + '/webhookrequests';

        return await HttpService.instance.getAsync(url).then((rsp) => WebhookLogModel.fromJsonArray(rsp.data));
    }

    async getLatestWebhookDataAsync(partnerId) {
        const url = '/api/partners/' + partnerId + '/webhookdata';

        return await HttpService.instance.getAsync(url).then((rsp => WebhookDataModel(rsp.data)));
    }
    
    async updatePartnerWebhooksAsync(partnerId, webhooks) {
        const url = '/api/partners/' + partnerId + '/webhooks';

        return await HttpService.instance.putAsync(url, JSON.stringify(PartnerWebhookModel.toJsonArray(webhooks)));
    }
}

export default WebhookService;
