import HttpService from "../../common/services/HttpService";
import SaleModel from "../models/SaleModel";

class SaleService { 
    static instance;

    constructor() {
        this.salesMap = {};
        this.merchantSalesMap = {};
        this.locationSalesMap = {};
        this.sales = [];
        this.saleMap = {};
    }

    async getSaleAsync(saleId) {
        const path = '/api/sales/' + saleId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.saleMap[saleId] = new SaleModel(response.data);
            return me.saleMap[saleId];
        });
    }

    async getSalesAsync() {
        const path = "/api/sales";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.sales = response.data.map((sale) => {
                return new SaleModel(sale);
            });

            return me.sales;
        });
    }

    async getSalesByPartnerAsync(partnerId) {
        if (!partnerId || partnerId.length < 30) return this.salesMap[partnerId] || [];
        
        const path = "/api/partners/" + partnerId + "/sales";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.salesMap[partnerId] = response.data.map((sale) => {
                return new SaleModel(sale);
            });

            return me.salesMap[partnerId];
        });
    }

    async getSalesByMerchantAsync(merchantId) {
        if (!merchantId || merchantId.length < 30) throw new Error("Invalid Merchant Id when getting sales by merchant: " + merchantId); // return this.merchantSalesMap[merchantId] || [];

        const path = "/api/merchants/" + merchantId + "/sales";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.merchantSalesMap[merchantId] = response.data.map((sale) => {
                return new SaleModel(sale);
            });

            return me.merchantSalesMap[merchantId];
        });
    }

    async getSalesByLocationAsync(locationId) {
        if (!locationId || locationId.length < 30) return this.locationSalesMap[locationId] || [];

        const path = "/api/locations/" + locationId + "/sales";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.locationSalesMap[locationId] = response.data.map((sale) => {
                return new SaleModel(sale);
            });

            return me.locationSalesMap[locationId];
        });
    }
}

export default SaleService;
