class Controller {
    
    constructor() {
        this.id = (10000 * Math.random()).toString();
        this.scroll = (count = 1) => {};
        this.data = {};
    }
    
    open(options, e) {
        console.log("Controller.open(" + this.id + ")");
    }

    close(options, e) {
        console.log("Controller.close(" + this.id + ")");
    }

    toggle(options, e) {
        console.log("Controller.toggle(" + this.id + ")");
    }
    
    async refreshAsync(options, e) {
        console.log("Controller.refreshAsync(" + this.id + ")");
    }
    
    static createScrollController() { 
        const controller = new Controller();
        controller.data.scrollCount = 0;
        
        controller.scroll = (count = 1) => {
            
            if (count > 0 && controller.data.scrollCount >= count) return false;
            
            window.scrollTo(0, 0);
            controller.data.scrollCount++;
            
            return true;
        }
        
        return controller;
    }
}

export default Controller;
