import React, {useState} from 'react';
import FormButton from "../../common/ui/FormButton";

const PersonForm = (props) => {
    const { person, onSubmit, buttonCaption } = props;
    let [payload, setPayload] = useState(person || {});
    const onFormSubmit = onSubmit;

    console.log('PersonForm: ' + payload.first_name);
    
    const validateForm = (e) => {
        console.log('Validated form: ' + e.toString());
    };

    const handleChange = (fieldId, e) => {
        let value = e.target.value;
        console.log('Change [' + fieldId + '] to [' + value + ']');
        
        setPayload((values => ({...values, [fieldId]: value})));

        if (typeof props.onChange === 'function') {
            props.onChange(payload);
        }
    };

    const onSubmitAsync = (e) => {
        console.log('onSubmit Fired');
        validateForm(e);
        onFormSubmit(e, payload);
        console.log('onSubmit Fired Completed');
    };

    let submitElement = props.useButton ? (
        <div className="button">
            <FormButton id="address-update-button" onClick={onSubmitAsync} label={buttonCaption || "Update"} />
        </div>
    ) : (<></>);
    
    return (
        <div className="form">
            <div className="form-group double">
                <div>
                    <label htmlFor="customer-first-name">First Name</label>
                    <input type="text" id="customer-first-name" defaultValue={payload.first_name} onChange={handleChange.bind(this, "first_name")} />
                </div>
                <div>
                    <label htmlFor="customer-last-name">Last Name</label>
                    <input type="text" id="customer-last-name" defaultValue={payload.last_name} onChange={handleChange.bind(this, "last_name")} />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="customer-email">Email Address</label>
                <input type="text" id="customer-email" defaultValue={payload.email} onChange={handleChange.bind(this, "email")} />
            </div>

            <div className="form-group">
                <label htmlFor="customer-phone">Phone Number</label>
                <input type="text" id="customer-phone" defaultValue={payload.phone} onChange={handleChange.bind(this, "phone")} />
            </div>

            {submitElement}
        </div>        
    );
};

export default PersonForm;