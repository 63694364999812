import React, {
    useContext,
    useEffect,
    useState
} from "react";
import AppContext
    from "../../AppContext";
import {
    Outlet,
    useParams
} from "react-router-dom";
import PartnerService
    from "../../components/partners/services/PartnerService";
import MerchantSubMenu
    from "../../components/partners/ui/MerchantSubMenu";
import MerchantLocationSubMenu
    from "../../components/partners/ui/MerchantLocationSubMenu";
import HttpService
    from "../../components/common/services/HttpService";

const MerchantLocationMasterPage = () => {
    const appContext = useContext(AppContext);
    const { partnerId, merchantId, locationId } = useParams();
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || null);
    const merchant = partner?.merchants?.find((m) => m.id === merchantId) || null;
    const location = merchant?.locations?.find((l) => l.id === locationId) || null;
    let _;
    
    HttpService.instance.setPartnerId(partnerId);

    const getPartnerAsync = async (force = false) => {
        if (!partnerId) {
            console.error("No PartnerId when getting partner from server.");
            return (partner || null);
        }

        let p = PartnerService.instance.partnerMap[partnerId];
        if (!!p?.id && !force) return partner;

        p = await PartnerService.instance.getPartnerAsync(partnerId);

        if (!!p?.id) {
            setPartner(p);
        }

        return p || (partner || null);
    };

    const outletContext = {
        partner: partner,
        name: "MerchantLocationMasterPage",
        merchant: merchant,
        location: location,
        refreshAsync: (svc) => getPartnerAsync(true),
    };

    useEffect(() => {
        appContext.setTitle("Merchant Locations");
        _ = getPartnerAsync();
    }, [partner]);

    return (
        <div className="details-body">
            <MerchantLocationSubMenu
                partner={partner}
                merchant={merchant}
                merchantLocation={location}/>

            <div className={"pad"}>
                <Outlet context={outletContext} />
            </div>
        </div>
    );

};

export default MerchantLocationMasterPage;