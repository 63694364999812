class EnvironmentModel {
    static storageKey = 'cardsync-environment';

    static environments = {
        production: new EnvironmentModel({
            key: "production",
            name: "Production",
            baseUrl: "https://prod.evoke.paylani.com",
            paylaniApiUrl: "https://prod.api.paylani.com",
            terminalPortalUrl: "https://demo.evoke.paylani.com",
            access_level: 2
        }),
        development: new EnvironmentModel({
            key: "development",
            name: "Development",
            baseUrl: "https://dev.evoke.paylani.com",
            paylaniApiUrl: "https://dev.api.paylani.com",
            terminalPortalUrl: "https://dev.demo.evoke.paylani.com",
            access_level: 0
        }),
        local: new EnvironmentModel({
            key: "local",
            name: "Local",
            baseUrl: "https://dev.evoke.paylani.com",
            paylaniApiUrl: "https://dev.api.paylani.com",
            terminalPortalUrl: "https://dev.demo.evoke.paylani.com",
            access_level: -1
        })
    };

    static setEnvironment(environmentKey) {
        // if (!environmentKey) {
        //     console.log('Getting environment from storage');
        //     environmentKey = localStorage.getItem(EnvironmentModel.storageKey) || null;
        // }
        //
        // console.log("environment key: " + environmentKey);

        if (!environmentKey) {
            console.log("Getting environment from Url: " + window.location.href);

            if (window.location.href.indexOf('//evoke.portal.paylani.com') > 0) {
                environmentKey = "production";
            } else if (window.location.href.indexOf('//dev.evoke.portal.paylani.com') > 0) {
                environmentKey = "development";
            } else {
                environmentKey = "local";
            }
        }

        //environmentKey = "production";

        let env = EnvironmentModel.environments[environmentKey];

        if (env) {
            EnvironmentModel.environment = env;
            localStorage.setItem(EnvironmentModel.storageKey, env.key);

            return true;
        }

        return false;
    }

    constructor(json) {
        if (!json) json = {};

        this.name = json.name || '';
        this.baseUrl = json.baseUrl || '';
        this.paylaniApiUrl = json.paylaniApiUrl || '';
        this.terminalPortalUrl = json.terminalPortalUrl || '';
        this.accessLevel = json.access_level || 0;
        this.key = json.key;
        this.isProduction = this.accessLevel === 2;
    }
}

export default EnvironmentModel;
