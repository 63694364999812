import AddressModel from "../../geo/models/AddressModel";
import CreditCardModel from "../../sales/models/CreditCardModel";

class CustomerModel { 
    constructor(json) {
        if (typeof json !== 'object') json = {};
        
        this.id = json.id;
        this.customerNumber = json.customer_number;
        this.firstName = json.first_name;
        this.lastName = json.last_name;
        this.email = json.email;
        this.phone = json.phone;
        this.imageUrl = json.image_url;
        this.addressId = json.address_id;
        this.address = new AddressModel(json.address);
        this.creditCard = new CreditCardModel(json.credit_card);
    }

    toJson = () => {
        return {
            "id": this.id,
            "first_name": this.firstName || '',
            "last_name": this.lastName || '',
            "email": this.email || '',
            "phone": this.phone || '',
            "image_url": this.imageUrl || '',
            "user_name": this.username || '',
            "user_type": this.userType || 0,
            "address": this.address.toJson()
        };
    };

    static searchFor(customer, term) { 
        return false;
    }
}

export default CustomerModel;
