class PlatformModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.serverTime = json.server_time ? new Date(json.server_time) : null;
        this.startupTime = json.startup_time ? new Date(json.startup_time) : null;
        this.startupCompletedTime = json.startup_completedTime ? new Date(json.startup_completedTime) : null;
        this.startupMessages = json.startup_messages || [];
        this.environment = json.environment;
        this.databaseEnvironment = json.database_environment;
        this.localAddress = json.local_address;
        this.remoteAddress = json.remote_address;
        this.userId = json.user_id;
        this.userName = json.user_name;
        this.activityInstanceCount = json.activity_instance_count;
        this.activityInstanceId = json.activity_instance_id;
        this.activitySessionUserId = json.activity_session_user_id;
        this.activitySessionTime = json.activity_session_time ? new Date(json.activity_session_time) : null;
    }
}

export default PlatformModel;
