import FormButton
    from "../../components/common/ui/FormButton";
import React
    , {
    useEffect, useRef,
    useState
} from "react";
import {
    useLocation,
    useNavigate,
    useOutletContext, useParams
} from "react-router-dom";
import TerminalService
    from "../../components/terminals/services/TerminalService";
import TerminalModel
    from "../../components/terminals/models/TerminalModel";
import BackButton from "../../components/common/ui/BackButton";
import ErrorModel from "../../components/common/models/ErrorModel";
import Controller from "../../components/common/services/Controller";

const TerminalCreationScreen = () => {
    const navigate = useNavigate();
    const { partnerId, merchantId } = useParams();
    const { partner, merchant } = useOutletContext();

    //const [merchant, setMerchant] = useState(partner?.merchants[0]);
    const [merchantLocation, setMerchantLocation] = useState(merchant?.locations[0]);
    const [scroller, setScroller] = useState(Controller.createScrollController());
    const [error, setError] = useState({});

    const nameRef = useRef();
    const serialNumberRef = useRef();
    const terminalTypeRef = useRef();
    const maxTransactionsAllowedRef = useRef();
    const transactionsPerformedRef = useRef();
    const merchantIdRef = useRef();
    const merchantLocationIdRef = useRef();
    const demoModeRef = useRef();

    useEffect(() => { 
        scroller.scroll();
    }, []);
    
    const validateForm = (model) => {
        let err = {};

        if (!model.name?.trim()) err.name = "Name is required";
        if (!model.serialNumber?.trim()) err.serialNumber = "Serial Number is required";
        if (!model.terminalPosType) err.terminalPosType = "Terminal Type is required";
        if (!(model.maxTransactionsAllowed > 0)) err.maxTransactionsAllowed = "Max Transactions Allowed must be greater than 0";
        if (!(model.transactionsPerformed >= 0)) err.transactionsPerformed = "Transactions Performed must be greater than or equal to 0";
        if (!model.merchantLocationId || model.merchantLocationId.length < 30) err.merchantLocationId = "Merchant Location is required";
        if (!model.merchantId || model.merchantId.length < 30) err.merchantLocationId = "Merchant Location is required";
        
        return new ErrorModel(err, true);
    };
    
    const onCreateAsync = async () => {
        const terminal = new TerminalModel();

        terminal.name = nameRef.current?.value || null;
        terminal.partnerId = partnerId;
        terminal.merchantLocationId = merchantLocationIdRef.current?.value || (merchantLocation?.id || null);
        terminal.serialNumber = serialNumberRef.current?.value || null;
        terminal.terminalPosType = terminalTypeRef.current?.value || null;
        terminal.merchantId = merchantIdRef.current?.value || (merchant?.id || null);
        terminal.maxTransactionsAllowed = parseInt(maxTransactionsAllowedRef.current?.value || "-1") || 0;
        terminal.transactionsPerformed = parseInt(transactionsPerformedRef.current?.value || "-1") || 0;
        terminal.demoMode = (demoModeRef.current?.value !== "false");

        const formError = validateForm(terminal);

        if (formError.hasFormErrors()) {
            setError(formError.focus("terminal-"));
            return;
        }
        
        const newTerminal = await TerminalService.instance.createTerminalAsync(terminal).catch((ex) => { 
            const err = ErrorModel.createFormError(ex);
            setError(err);
            return null;
        });
        
        if (!newTerminal) return;
        
        navigate('/partners/' + partnerId + '/terminals/' + newTerminal.serialNumber, {state: newTerminal});
    }
    
    const merchantDropdownElements = partner?.merchants?.map((merchant) => (<option
        key={merchant.id}
        value={merchant.id}>{merchant.name}</option>)) || (<option>Loading...</option>);

    const m = (merchant || partner?.merchants?.find((mm) => mm.id === merchantId)) || partner?.merchants[0];
    const merchantLocationDropdownElements = m?.locations?.map((merchantLocation) => (<option
        key={merchantLocation.id}
        value={merchantLocation.id}>{merchantLocation.name}</option>)) || (<option>Loading...</option>);

    return (
        <div className={"form medium"}>
            <h2>
                <BackButton />
                New Terminal
            </h2>
            
            <div className="form-group">
                <label htmlFor="terminal-name">Terminal Name</label>
                <input type="text" id="terminal-name" ref={nameRef} />
                <div className={"form-error"}>{error?.name}</div>
            </div>
            <div className={"form-group"}>
                <label htmlFor={"terminal-serialNumber"}>Serial Number</label>
                <input type="text" id="terminal-serialNumber" defaultValue={""} ref={serialNumberRef} />
                <div className={"form-error"}>{error?.serialNumber}</div>
            </div>
            
            <div className={"form-group"}>
                <label htmlFor={"terminal-merchant"}>Merchant</label>
                <select id="merchant-select" defaultValue={m?.id} ref={merchantIdRef}>
                    { merchantDropdownElements }
                </select>
                <div className={"form-error"}>{error?.merchantId}</div>
            </div>
            
            <div className={"form-group"}>
                <label htmlFor={"terminal-merchantLocation"}>Merchant Location</label>
                <select id="merchant-location-select" defaultValue={merchantLocation?.id} ref={merchantLocationIdRef}>
                    {merchantLocationDropdownElements}
                </select>
                <div className={"form-error"}>{error?.merchantLocationId}</div>
            </div>
            
            <div className={"form-group"}>
                <label htmlFor="terminal-type">Terminal Type</label>
                <select id="terminal-type" defaultValue={"Pax"} ref={terminalTypeRef}>
                    <option value="Pax">PAX</option>
                    <option value="Poynt">Poynt</option>
                    <option value="Native">Native</option>
                    <option value="Sunmi">Sunmi</option>
                    <option value="Ingenico">Ingenico</option>
                    <option value="Tecs">Tecs</option>
                </select>
                <div className={"form-error"}>{error?.terminalType}</div>
            </div>
            
            <div className={"form-group"}>
                <label htmlFor="terminal-maxTransactionsAllowed">Max Transactions Allowed</label>
                <input type="number" id="terminal-maxTransactionsAllowed" ref={maxTransactionsAllowedRef}/>
                <div className={"form-error"}>{error?.maxTransactionsAllowed}</div>
            </div>
            
            <div className={"form-group"}>
                <label htmlFor="terminal-transactionsPerformed">Transactions Performed</label>
                <input type="number" id="terminal-transactionsPerformed" ref={transactionsPerformedRef} />
                <div className={"form-error"}>{error?.transactionsPerformed}</div>
            </div>
            
            <div className={"form-group"}>
                <label htmlFor="terminal-demoMode">Demo Mode</label>
                <select id="terminal-demoMode" ref={demoModeRef}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
                <div className={"form-error"}>{error?.demoMode}</div>
            </div>

            <div className={"form-error"}>{error?.general}</div>
            
            <div className="button">
                <FormButton id="terminal-create-button" onClick={onCreateAsync} label="Create" />
            </div>
        </div>
    );
}

export default TerminalCreationScreen;