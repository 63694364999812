import React, {useState} from 'react';
import FormButton from "../../common/ui/FormButton";

import AuthenticationService from "../services/AuthenticationService";

const LoginForm = (props) => {
    const { onAuthenticate, environmentKey } = props;
    let [creds, setCreds] = useState({username: '', password: ''});
    let [errorMessage, setErrorMessage] = useState('');

    const onChange = (fieldId, e) => {
        let c = { username: creds.username, password: creds.password };
        c[fieldId] = e.target.value;
        setCreds(c);
    };

    const onSubmitAsync = async (e) => {
        //console.log('Submit[' + environmentKey + ']: ' + JSON.stringify(creds));

        return await AuthenticationService.instance.authenticateAsync(creds.username, creds.password, environmentKey).then((session) => {
        //return await AuthenticationService.instance.authenticateAsync("ben.garrison@fragmob.com","fragmobb", environmentKey).then((session) => {
            if (!!onAuthenticate && typeof onAuthenticate === 'function') onAuthenticate(session);
            return 'Success';
        }).catch((err) => {

            setErrorMessage(err?.response?.data?.messages[0] || (err?.message || err));
            return 'error';
        });
    };

    const createErrorElement = () => {
        if (!errorMessage) return (<></>);

        let message = 'The login attempt for user ' + creds.username + ' failed. ' +
            "Remember that username and password are case sensitive. ";

        return (<div className="error-box">{message}<br/><br/>Server Returned: <strong>{errorMessage}</strong></div>);
    };

    let errorElement = createErrorElement();

    return (
        <div className="login-panel form">
            <label>Username:</label>
            <div><input onChange={onChange.bind(this, 'username')} type={"text"} /></div>

            <label>Password:</label>
            <div><input onChange={onChange.bind(this, 'password')} type={"password"} /></div>

            {errorElement}

            <div className={"button login-button-panel"}>
                <FormButton onClick={onSubmitAsync}>Login</FormButton>
            </div>
        </div>        
    );
};

export default LoginForm;
