import React, {
    useState,
    useEffect,
    Children,
    useContext
} from 'react';
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import PartnerService from "../../components/partners/services/PartnerService";
import AppContext from "../../AppContext";
import HttpService from "../../components/common/services/HttpService";

const PartnerMasterPage = (props) => {
    const appContext = useContext(AppContext);
    const { partnerId } = useParams();
    const { onPartner, subTitle, partnerModel, title, children, bodyClassName, selection } = props;
    
    const [classes, setClasses] = useState({ body: bodyClassName || "use-state-partner-master-page" });
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || null);
    let _;

    if (!!partnerId) HttpService.instance.setPartnerId(partnerId);
    
    useEffect(() => {
        appContext.setTitle("Partners");
        HttpService.instance.setPartnerId(partnerId);
    }, []);
    
    const getPartnerAsync = async (force) => {
        if (!partnerId) {
            console.error("No PartnerId when getting partner from server.");
            return (partner || null);
        }

        if (!!partner?.id && !force) {
            if (typeof onPartner === "function") onPartner(partner);
            return partner;
        }
        
        console.log("Refreshing Partner: " + partnerId);
        const p = await PartnerService.instance.getPartnerAsync(partnerId);
        
        if (!!p?.id) {
            if (typeof onPartner === "function") onPartner(p);
            console.log("Good: " + (p?.merchants?.length || 0).toString());
            setPartner(p);
        } else console.error("Failed to get partner: " + partnerId);

        return p || (partner || null);
    };

    useEffect(() => {
        if (typeof onPartner === 'function') onPartner(partner);
    }, [partner]);
    
    useEffect(() => {
        _ = getPartnerAsync();
    }, []);
    
    const outletContext = {
        name: "PartnerMasterPage",
        partner: partner,
        refreshAsync: () => getPartnerAsync(true),
        setClasses: (classes) => {
            if (typeof classes === "string") {
                setClasses({ body: classes });
                return;
            }
            
            if (typeof classes !== "object") return;
            
            setClasses(classes);
        },
        classes: { toast: "toast-class" },
    };
    
    const subMenu = !!partner ? (<PartnerSubMenu partner={partner} />) : null;
    const bodyClass = typeof classes.body === 'string' && classes.body.length > 0 ? " " + classes.body : "";

    console.warn("Partner Master Page Rendered");
    
    return (<div className="details-body">
            { subMenu }
            <div className={"pad master-page" + bodyClass}>
                {/*{ children }*/}
                <Outlet context={outletContext}/>
            </div>
        </div>);
};

export default PartnerMasterPage;