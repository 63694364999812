import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import {
    Link,
    Outlet,
    useLocation,
    useParams
} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook, faAddressCard, faAlignLeft, faBatteryHalf,
    faComments,
    faDiagramProject,
    faGears, faPersonWalking,
    faRobot,
    faSuitcase, faUnlockKeyhole
} from "@fortawesome/free-solid-svg-icons";
import UserService
    from "../services/UserService";
import AppContext
    from "../../../AppContext";

const UserSubMenu = (props) => {
    const location = useLocation();
    const appContext = useContext(AppContext);

    useEffect(() => {
        appContext.setTitle("Users");
    }, []);
    
    const { userId } = useParams();
    let [user] = useState(UserService.instance.userMap[userId] || null);

    const path = "/people/" + userId;
    
    let profileImageElement = !!user.imageUrl ?
        (<img src={user.imageUrl} alt="" />) :
        (<span className="image"><FontAwesomeIcon icon={faPersonWalking} /></span>);

    const outletContext= { user: user, name: "UserSubMenu" };
    
    return (
        <div className="details-body large multi-form">
        <div className="sub-column" id="user-submenu">
            <div className="image profile">
                <div id={"user-profile-image"}>{ profileImageElement }</div>
            </div>

            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={location.pathname.startsWith('/people/' + userId + '/details') ? 'selected':''}><Link to={path + "/details"}><FontAwesomeIcon icon={faAddressCard} /> User Details</Link></li>
                <li className={location.pathname.startsWith('/people/' + userId + '/access') ? 'selected':''}><Link to={path + "/access"}><FontAwesomeIcon icon={faUnlockKeyhole} /> Accessibility</Link></li>
                <li className={location.pathname.startsWith('/people/' + userId + '/sessions') ? 'selected':''}><Link to={path + "/sessions"}><FontAwesomeIcon icon={faBatteryHalf} /> Session History</Link></li>
            </ul>

        </div>
            <Outlet context={outletContext}/>

    </div>
    );
};

export default UserSubMenu;
