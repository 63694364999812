import React from 'react';

const PhoneNumber = (props) => {
    const { value, defaultValue, prefix, suffix, children } = props;
    
    let phone = typeof value === 'string' && value.trim().length > 0 ?
        value.trim() :
        (typeof children === 'string' ? children.trim() : "");
    
    phone = phone.replace(/\D/g,'');
    
    if (phone.length === 11 && phone.substr(0, 1) === '1') {
        phone = phone.substr(1);
    }
    
    if (!phone) {
        if (typeof defaultValue === "string" || typeof defaultValue === "number")
            return (<>{defaultValue}</>);
        if (typeof defaultValue === "object" && defaultValue !== null)
            return defaultValue;
        
        return null;
    }
    
    const stringPrefix = typeof prefix === 'string' || typeof prefix === "number" ? prefix.toString() : '';
    const stringSuffix = typeof suffix === 'string' || typeof suffix === "number" ? suffix.toString() : '';
    
    const objectPrefix = typeof prefix === 'object' && prefix !== null ? prefix : null;
    const objectSuffix = typeof suffix === 'object' && suffix !== null ? suffix : null;
    
    let converted = false;
    
    if (phone.length === 10) {
        converted = true;
        phone = (stringPrefix || '') + '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 4) + (stringSuffix || '');
    }
    
    if (!!objectSuffix || !!objectPrefix)
        return (<>{objectPrefix}{phone}{objectSuffix}</>);
    
    return (
        <span className="ui-span phone-number-text">
            {phone}
        </span>
    );
};

export default PhoneNumber;
