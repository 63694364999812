class SaleProductModel { 
    
    static json = {
        "id": "0d1fe9e4-3569-4afe-a124-91971869d774",
        "saleId": "0abdbb50-563b-4e81-8a4b-96c52b21b92e",
        "imageUrl": "https://paylanistorage.blob.core.windows.net/$web/images/product_yoga_mat.png",
        "name": "Paylani Yoga Mat",
        "quantity": 1,
        "cost": 20.0,
        "sum": 20.0
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.saleId = json.saleId || json.sale_id;
        this.name = (json.name || json.title) || "";
        this.imageUrl = (json.imageUrl || json.image_url);
        this.qty = (json.qty || json.quantity) || 0;
        this.price = (json.cost || json.price) || 0.0;
        this.amount = (json.sum || json.total) || (json.amount || 0);
    }
    
    toJson() {
        return {
            id: this.id,
            saleId: this.saleId,
            imageUrl: this.imageUrl,
            qty: this.qty,
            price: this.price,
            amount: this.amount
        };
    }
    
    searchFor(searchText) {
        return (this.name?.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new SaleProductModel(json));
    }
}

export default SaleProductModel;
