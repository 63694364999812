class WebhookDataModel {
    static json = {
        "latestTerminalChangedData": null,
        "latestTransacationCompletedData": null
    };

    constructor(json) {
        if (!json) json = {};

        this.latestTerminalChangedData = json.latestTerminalChangedData || null;
        this.latestTransacationCompletedData = json.latestTransacationCompletedData || null;
    }


}

export default WebhookDataModel;