import React from 'react';
import {
    Link,
    useLocation
} from "react-router-dom";
import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import {
    faCheckToSlot,
    faIdCardClip,
    faMobileRetro
} from "@fortawesome/free-solid-svg-icons";

const MerchantLocationSubMenu = (props) => {
    const location = useLocation();
    const { partner, merchant, merchantLocation, selection } = props;

    const partnerId = partner?.id;
    const merchantId = merchant?.id;
    const locationId = merchantLocation?.id;

    const getSelectionKey = () => {
        if (typeof selection === "string") return selection;
        if (location.pathname.includes('/sales')) return 'sales';
        if (location.pathname.includes('/terminals')) return 'terminals';

        return "overview";
    };

    const menuSelection = getSelectionKey();
    const classSelector = {};
    
    classSelector[menuSelection] = "selected";
    
    return (
        <div className="sub-column">
            <h4>
                <span>Location:</span>
                {merchantLocation?.name}
            </h4>
            
            <ul>
                <li className={classSelector["overview"] || null}> <Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/locations/' + locationId + '/details'}><FontAwesomeIcon icon={faIdCardClip} />Overview</Link></li>
                <li className={classSelector["sales"] || null}> <Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/locations/' + locationId + '/sales'}><FontAwesomeIcon icon={faCheckToSlot} />Sales</Link></li>
                <li className={classSelector["terminals"] || null}> <Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/locations/' + locationId + '/terminals'}><FontAwesomeIcon icon={faMobileRetro} />Terminals</Link></li>
            </ul>

            <h4>
                <span>Merchant:</span>
                <Link to={"/partners/" + partnerId + "/merchants/" + merchantId + "/details"}>{merchant?.name}</Link>
                
                <span>Partner:</span>
                <Link to={"/partners/" + partnerId + "/details"}>{partner?.name}</Link>
            </h4>
        </div>
    );
};

export default MerchantLocationSubMenu;

