import React, {useContext, useEffect, useState} from "react";
import "../../components/partners/ui/Partners.css";
import { useOutletContext } from "react-router-dom";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "../../components/formatters/DateTime";
import {faArrowRightToBracket, faBuildingUser, faPenFancy} from "@fortawesome/free-solid-svg-icons";
import BackButton from "../../components/common/ui/BackButton";
import AppContext from "../../AppContext";
import AddButton from "../../components/common/ui/AddButton";

const PartnerDetailsScreen = () => {
    const { partner } = useOutletContext();
    const appContext = useContext(AppContext);

    console.warn("AppContext Name: " + appContext?.name);
    
    return (
        <>
            <h2>
                <BackButton path="/partners" />
                { partner?.name }
                <span className="subtitle">Partner Details</span>
                
                <AddButton to={"/partners/" + partner?.id + "/update"} icon={faPenFancy} state={partner}>Edit Name</AddButton>
            </h2>
            
            {/*<h2>{partner.name + " Details"}</h2>*/}

            <div className={"date-activity"}>
                <span id={"user-last-login-date"}>
                    <FontAwesomeIcon icon={faArrowRightToBracket}/>
                    <DateTime showRelative={true} value={partner?.lastTransactionDate} defaultValue={"No transactions"} prefix={"Last Transaction: "}/>
                </span>
                
                <span id={"user-created-date"}>
                    <FontAwesomeIcon icon={faBuildingUser}/>
                    <DateTime showRelative={true} value={partner?.created} defaultValue={"No creation date"} prefix={"Created: "}/>
                </span>
            </div>
        </>
    );
};

export default PartnerDetailsScreen;
