import React, {useState, useEffect} from 'react';
import {
    Link,
    NavLink,
    useOutletContext, useParams
} from "react-router-dom";
import BackButton from "../../components/common/ui/BackButton";
import PartnerService from "../../components/partners/services/PartnerService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenFancy} from "@fortawesome/free-solid-svg-icons";
import AddButton from "../../components/common/ui/AddButton";
import Controller from "../../components/common/services/Controller";
import PhoneNumber from "../../components/formatters/PhoneNumber";
import EmailAddress from "../../components/formatters/EmailAddress";

const MerchantDetailsScreen = (props) => {
    const { refreshAsync } = useOutletContext();
    const { partnerId } = useParams();
    const merchantId = useParams().merchantId;
    const partner = PartnerService.instance.partnerMap[partnerId] || null;
    const [merchant, setMerchant] = useState(partner?.merchants?.find(m => m.id === merchantId) || null);
    const [scroller, setScroller] = useState(Controller.createScrollController());
    let _;

    useEffect(() => {
        scroller.scroll();
    }, []);

    const subTitle = (<h2>
        <BackButton path={"/partners/" + partner?.id + "/merchants"}/>
        {merchant?.name}
        <span className="subtitle">Merchant Details</span>
        <AddButton path={"/partners/" + partner?.id + "/merchants/" + merchant?.id + "/update"} icon={faPenFancy}>Edit Merchant</AddButton>
    </h2>);
    
    return (
        <>
            { subTitle}
            <div>
                <h2>{merchant?.name || "Loading..."}</h2>
                <p>
                    <EmailAddress prefix={"Email: "} suffix={(<br/>)}>{merchant?.email}</EmailAddress>
                    <PhoneNumber prefix={"Phone: "} suffix={(<br/>)}>{merchant?.phone}</PhoneNumber>
                    Website: {merchant?.website ? merchant?.website : "not set"}<br/>
                    {/*                    uncomment if this is something we want to be controlled... currently only applies to Poynt devices.
                    Authenticate Transactions By Default : {merchant.authByDefault ? "Yes" : "No"}<br/>*/}
                </p>
            </div>
        </>
    );
};

export default MerchantDetailsScreen;

