import {
    NavLink,
    useLocation,
    useOutletContext,
    useParams
} from "react-router-dom";
import React, {
    useEffect,
    useRef,
    useState
} from "react";
import PartnerService
    from "../../components/partners/services/PartnerService";
import BackButton
    from "../../components/common/ui/BackButton";
import AddButton from "../../components/common/ui/AddButton";
import {faPenFancy} from "@fortawesome/free-solid-svg-icons/faPenFancy";

const MerchantLocationDetailsScreen = (props) => {
    const { partner, merchant, location } = useOutletContext();
    const { merchantId, partnerId, locationId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    
    useEffect(() => {
        if (!isLoaded) {
            window.scrollTo(0, 0);
            setIsLoaded(true);
        }
    }, []);
    
    const body = (!!location?.address?.street || !!location?.address?.city) ?
        (<div>
            <h4>Address</h4>
            <p>
                {location?.address?.street} {location?.address?.unit}<br/>
                {location?.address?.city}, {location?.address?.state} {location?.address?.zip}<br/>
                {location?.address?.country}
            </p>
        </div>) : (<p>No address has been assigned</p>);
    
    return <>
            <h2>
                <BackButton path={"/partners/" + partnerId +"/merchants/" + merchantId +"/locations"} />
                { location?.name }
                <span className="subtitle">Location Details</span>
                
                <AddButton icon={faPenFancy} to={"/partners/" + partnerId + "/merchants/" + merchantId + "/locations/" + locationId + "/update"} state={location}>Edit Details</AddButton>
            </h2>

        <h2>{location?.name}</h2>
        {body}
        
    </>;

}

export default MerchantLocationDetailsScreen