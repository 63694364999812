import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PartnerService from "../../components/partners/services/PartnerService";
import FormButton from "../../components/common/ui/FormButton";
import BackButton from "../../components/common/ui/BackButton";
import ErrorModel from "../../components/common/models/ErrorModel";
import AppContext from "../../AppContext";

const PartnerCreationScreen = () => {
    let _;
    const noError = {};
    const appContext = useContext(AppContext);
    const outlet = useOutletContext();
    
    const {partnerId} = useParams();
    const [error, setError] = useState(noError);
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId || ""] || null);
    
    const navigate = useNavigate();
    const nameRef = useRef();

    const getPartnerAsync = async (force = false) => {
        if (!partnerId) return null;
        if (!!partner && !force) return partner;

        const p = await PartnerService.instance.getPartnerAsync(partnerId, force).catch((err) => {
            // Failed to get partner.
            console.error(new ErrorModel(err).message || "Failed to get partner for an unknown reason.");
        });

        if (!!p?.id) setPartner(p);

        return p || null;
    }

    useEffect(() => {
        _ = getPartnerAsync();
    });

    console.warn("PartnerCreationScreen: " + partnerId);
    console.log("OutletContext.refreshAsync: " + (typeof outlet?.refreshAsync).toString());
    
    const onSubmitAsync = async (e) => {
        e?.preventDefault();
        
        const name = nameRef.current?.value || "";
        
        if (!name) {
            setError({name: "Name is required", general: ""});
            return;
        }

        const data = {id: partnerId || null, name: name};
        // noinspection ES6MissingAwait
        const pr = !partnerId || partnerId.length < 30 ?
            PartnerService.instance.createPartnerAsync(data) :
            PartnerService.instance.updatePartnerAsync(data);

        const p = await pr.catch((err) => {
            console.error(err);
            setError(ErrorModel.createFormError(err));
            return null;
        });
        
        if (!p?.id) return;

        if (typeof outlet?.refreshAsync === "function")
            await outlet.refreshAsync();
        
        console.log("Created Partner: " + p.name);
        navigate('/partners/' + p.id + '/details');
    };

    const nameElement = !partnerId || !!partner ? (<input ref={nameRef} type="text" id="partner-name" onChange={() => setError(noError)} defaultValue={partner?.name}/>) : null;
    const h2ClassName = !partnerId ? "form-only" : "";
    const subtitle = !partnerId ? "Create a new partner." : "Update Partner Name";
    const pp = !partnerId ? "Enter the name of the partner company you'd like to create" : "Update the name of the partner below.";
    const backPath = !!partnerId ? "/partners/" + partnerId + "/details" : "/partners";
    const buttonCaption = !partnerId ? "Create Partner" : "Update Partner";
    
    return (
        <>
            <h2 className={h2ClassName}>
                <BackButton path={backPath} />
                {subtitle}
            </h2>
            
            <p>{ pp }</p>

            <div className="form medium">
                <div className="form-group">
                    <label htmlFor="partner-name">Partner Name</label>
                    { nameElement }
                    <div className={"form-error"}>{error?.name}</div>
                </div>

                <div className={"form-error"}>{error?.general}</div>
                <div className="button">
                    <FormButton id="address-update-button" onClick={onSubmitAsync} label={buttonCaption}/>
                </div>

            </div>
        </>
    );
};

export default PartnerCreationScreen;