import React, {
    useState,
    useRef,
    useEffect,
    useContext
} from 'react';
import {
    Link,
    NavLink,
    useOutletContext,
    useParams,
} from 'react-router-dom';

import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faArrowUpRightFromSquare,
    faMobileRetro,
    faMoon,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import TerminalService
    from "../../components/terminals/services/TerminalService";
import PagerController
    from "../../components/common/ui/pager/PagerController";
import PartnerService
    from "../../components/partners/services/PartnerService";

import a920
    from "../../images/pax-a920.png";
import poyntN10
    from "../../images/poynt-n910.png";
import LoadingBox
    from "../../components/common/ui/LoadingBox";
import EmptyBox
    from "../../components/common/ui/EmptyBox";
import PartnerModel
    from "../../components/partners/models/PartnerModel";
import AppContext
    from "../../AppContext";
import EnvironmentModel
    from "../../components/common/models/EnvironmentModel";
import BackButton
    from "../../components/common/ui/BackButton";
import "../../components/terminals/ui/Terminals.css";
import AddButton from "../../components/common/ui/AddButton";
import Controller from "../../components/common/services/Controller";

const TerminalsScreen = (props) => {
    const context = useOutletContext();
    const appContext = useContext(AppContext);
    
    const { merchant, location } = context || {};
    const { partnerId, merchantId, locationId } = useParams();
    
    const [partner, setPartner] = useState(context?.partner);
    const [terminals, setTerminals] = useState(TerminalService.instance.terminals || null);
    const [partnerMap, setPartnerMap] = useState(PartnerService.instance.partnerMap || null);
    const [scroller, setScroller] = useState(Controller.createScrollController());

    const isLocation = !!locationId;
    const isMerchant = !isLocation && !!merchantId;
    const isPartner = !isMerchant && !!partnerId;
    
    useEffect(() => {
        if (!!partnerId) _ = getPartnerAsync();
        _ = refreshTerminalsAsync();

        scroller.scroll();
    }, []);

    const [currentPage, setCurrentPage] = useState(0);
    const pageController = useRef(new PagerController(setCurrentPage)).current;
    let _;

    const getTerminalsAsync = async (force) => {
        if (!force && terminals?.length > 0) return;

        await TerminalService.instance.getTerminalsAsync().then((terminals) => {

            if (!!terminals) {
                if (location) terminals = terminals.filter((t) => location.id === t.merchantLocationId);
                
                if (merchant) {
                    const locationIds = partner.merchants.find((m) => m.id === merchant.id).locations.map((l) => l.id);
                    terminals = terminals.filter((t) => locationIds.includes(t.merchantLocationId));
                }

                setTerminals(terminals);
            }
        });
    };

    const refreshTerminalsAsync = async (force) => {
        appContext.setTitle("Terminals");
        appContext.setIcon(faMobileRetro);
        
        await getPartnersAsync(force);
        await getTerminalsAsync(force);
    };
    
    const getPartnerAsync = async (force = false) => {
        if (!partnerId) return;
        appContext.setTitle("Partners");
        appContext.setIcon(faAddressCard);
        
        if (!force && !!partner) return partner;

        await PartnerService.instance.getPartnerAsync(partnerId).then((partner) => {
            if (!!partner) setPartner(partner);
        });
    };

    const getPartnersAsync = async (force) => {
        if (!force && Object.keys(PartnerService.instance?.partnerMap || {}).length > 0)
            return;
        
        await PartnerService.instance.getPartnersAsync().then((_) => {
            setPartnerMap(PartnerService.instance.partnerMap);
        });
    };

    let i = 0;
    let ts = [];

    // const maxTerminals = !!partner.id ? 1 : 5;
    const terms = partner ? terminals?.filter((t) => t.partnerId === partner.id) || [] : terminals;

    terms?.forEach((t) => {
        ts.push(t);
    });

    const getProviderLink = (terminal) => {
        const terminalType = terminal.terminalPosType?.toLowerCase() || null;
        if (!terminalType) return null;
        
        if (terminalType === "poynt") {
            if (!terminal.posTerminalId) return null;
            
            const url = "https://poynt.secureserver.net/mc/#/reseller/terminals/" + terminal.posTerminalId; //urn:tid:6893477b-04ff-3482-b951-a5400fb5959c
            return (<a href={url} target={"_blank"} rel={"noreferrer"}>Poynt</a>);
        }
        
        if (terminalType === "pax" || terminalType.indexOf(".pax.") >= 0) {
            const encodedId = terminal.getEncodedId() || "NULL";
            if (!encodedId) return null;
            
            const url = "https://uat.paxstore.us/admin/#/terminals?" + encodedId; //6893477b-04ff-3482-b951-a5400fb5959c
            return (<a href={url} target={"_blank"} rel={"noreferrer"}>PAX</a>);
        }
        
        //console.error("No provider link for terminal sn " + terminal.serialNumber + ": " + terminal.terminalPosType);
        return null;
    };
    
    const createTerminalTileElement = (terminal, index) => {

        let connected = terminal.isConnected;
        if (index < 2) connected = true;

        const connectedState = !connected ?
            (<li title={"Connection State"} className={"offline"} key={"terminal-disconnected-key-" + index}>Offline <FontAwesomeIcon icon={faMoon} /></li>) :
            (<li title={"Connection State"} className={"online"} key={"terminal-key-" + index}>
                <span className={"pulse-container"}>
                    <span>Connected</span>
                    <span className={"pulse"}>&nbsp;</span>
                </span>
            </li>);

        let name = "PAX A920";
        let img = a920;

        if (terminal.terminalPosType === "poynt") {
            name = "Poynt N910";
            img = poyntN10;
        }
        
        let terminalPartner = partnerMap[terminal.partnerId] || new PartnerModel({
            id: terminal.partnerId,
            name: "Unknown"
        });
        
        const providerElement = getProviderLink(terminal);
        const terminalPath = "/partners/" + terminal.partnerId + "/terminals/" + terminal.serialNumber;
        const terminalPortalPath = EnvironmentModel.environment.terminalPortalUrl + "?serialNumber=" + terminal.serialNumber + "&applicationToken=" + terminalPartner.applicationToken;
        const partnerElement = !partnerId ? (<li key={"line-1"}><Link to={"/partners/" + terminal.partnerId + "/details"}>{terminalPartner.name}</Link></li>) : 
            null;

        const externalElement = !providerElement ?
            (<a href={terminalPortalPath} target="_blank">View in Portal <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>) :
            (<>View in <a href={terminalPortalPath} target="_blank">Portal</a> or {providerElement} &nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} /></>);
        
        return (<div key={"tile-element-" + index} className={"terminal"} id={"terminal-" + terminal.serialNumber}>
                <span key={"element-0"}><NavLink to={terminalPath} state={terminal}><img src={img} alt={"Terminal " + terminal.serialNumber}/></NavLink></span>
                <span>
                    <ul key={"element-1"}>
                        <li key={"line-0"} title={"Terminal Name/Label"}><NavLink to={terminalPath} state={terminal}>{terminal.name}</NavLink></li>
                        {partnerElement}
                        <li key={"line-2"} title={"Terminal Type"}>{terminal.terminalPosType + " - " + name}</li>
                        <li key={"line-3"} title={"Serial Number"}>{terminal.serialNumber}</li>
                        {connectedState}
                        <li key={"line-4"} className={"link-underline"}>{externalElement}</li>
                    </ul>
                </span>
            </div>);
    };

    const terminalTiles = terms?.length > 0 ? pageController.mapLineItems(ts, (terminal, index) => {
        return (<React.Fragment key={"fragment-" + index}>
            { createTerminalTileElement(terminal, index) }
        </React.Fragment>);
        
    }) : (terminals === null ? (<span>Loading...</span>) : (<EmptyBox key={"no-elements-key"}>No Terminals{partner ? " Managed by " + partner.name : ""}... So sorry.</EmptyBox>));

    const backButtonElement = !!location ?
        (<BackButton path={"/partners/" + partner.id + "/merchants/" + merchant.id + "/locations"}/>) :
        (!!merchant ? (<BackButton path={"/partners/" + partner.id + "/merchants"}/>) : null);
    
    return (
        <>
        {!!partner?.id ?
            (<><h2>
                {location ? location.name : merchant ? merchant.name : partner.name}
                <span className="subtitle">Terminals</span>
                <AddButton path={"/partners/" + partner.id + "/terminals/new"}>Add Terminal</AddButton>
            </h2>
            <p>Below are the terminals managed by <strong>{partner.name}</strong></p>
            </>) : (
                <>
                    <h2 className={"form-only"}>
                        Master Terminal List
                    </h2>
                    <p>
                        Below are all the terminals in the system. You can click each image (or title) to see further details, or click on the Merchant associated with the terminal to view the merchant details.
                    </p>
                </>
                    
            )}

            {
                terminals !== null ? 
                (<div className={"terminal-tiles" + (partner ? " partners" : "")}>{terminalTiles}</div>) :
                (<LoadingBox/>)
            }
        </>
    );
};

export default TerminalsScreen;
