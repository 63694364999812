import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const EmailAddress = (props) => {
    const { value, children, prefix, defaultValue, suffix, hasIcon, icon, isLinked } = props;

    let email = typeof value === 'string' && value.trim().length > 0 ?
        value.trim().toLowerCase() :
        (typeof children === 'string' ? children.trim().toLowerCase() : "");

    if (!email) return (<></>);
    if (email.indexOf('@') < 0) return (<>{defaultValue || (value || "")}</>);
    
    const stringPrefix = typeof prefix === 'string' || typeof prefix === "number" ? prefix.toString() : '';
    const stringSuffix = typeof suffix === 'string' || typeof suffix === "number" ? suffix.toString() : '';

    const objectPrefix = typeof prefix === 'object' && prefix !== null ? prefix : null;
    const objectSuffix = typeof suffix === 'object' && suffix !== null ? suffix : null;

    let iconElement = typeof icon === "object" ? icon : null;
    
    if (hasIcon === true && !iconElement) {
        iconElement = (<FontAwesomeIcon icon={faEnvelope} />);
    }
    
    if (isLinked !== false) {
        email = (<>{stringPrefix}<a href={'mailto:' + email}>{email}</a>{stringSuffix}</>);
    } else {
        email = (stringPrefix + email + stringSuffix);
    }
    
    if (!!objectSuffix || !!objectPrefix)
        return (<>{objectPrefix}{email}{objectSuffix}</>);
    
    return (
        <span className="ui-span email-address-text">{email}</span>
    )
};

export default EmailAddress;
