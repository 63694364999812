import {
    Link,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams
} from "react-router-dom";
import FormButton
    from "../../components/common/ui/FormButton";
import React
    , {
    useEffect, useRef,
    useState
} from "react";
import TerminalService
    from "../../components/terminals/services/TerminalService";
import BackButton from "../../components/common/ui/BackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import ErrorModel from "../../components/common/models/ErrorModel";
import Controller from "../../components/common/services/Controller";

const TerminalUpdateScreen = (props) => {
    const { partnerId, serialNumber, saleId, merchantId } = useParams();
    const [currentTerminal, setCurrentTerminal] = useState(TerminalService.instance.terminalMap[serialNumber] || null);
    const [updatedTerminal, setUpdatedTerminal] = useState(null);
    const [scroller, setScroller] = useState(Controller.createScrollController());
    const [error, setError] = useState({});
    
    const nameRef = useRef();
    const maxTransactionsAllowedRef = useRef();
    const transactionsPerformedRef = useRef();
    const demoModeRef = useRef();
    const navigate = useNavigate();
    let _;

    const showLoading = !currentTerminal;
    
    const getTerminals = async (force) => {
        if (!force && !!currentTerminal)
            return;
        
        const rsp = await TerminalService.instance.getTerminalsAsync().catch((ex) => { 
            const e = ErrorModel.createFormError(ex);
            if (!!e.general) {
                e.top = e?.general?.toString() || "";
                
                delete e.general;
                
                e.redirect = "/partners/" + partnerId + "/terminals";
                e.redirectCaption = "Back to Terminals";
            }
            
            setError(e);
            return null
        });

        const terminal = TerminalService.instance.terminalMap[serialNumber];
        
        if (!terminal) {
            const terminalCount = Object.keys(TerminalService.instance.terminalMap).length;
            const message = terminalCount > 0 ?
                "Terminal " + serialNumber + " was not found" :
                "Failed to get terminal " + serialNumber + " from the server";
            
            const er = {
                top: message,
                redirect: "/partners/" + partnerId + "/terminals",
                redirectCaption: "Back to Terminals",
            };

            if (typeof saleId === "string" && saleId.length > 30) { 
                er.redirect = "/partners/" + partnerId + "/sales/" + saleId + (!!merchantId ? "/with-merchant/" + merchantId : "");
                er.redirectCaption = "Return to Sale Details";
            }

            setError(er);
            return;
        }
        setCurrentTerminal(terminal);
    };
    
    const checkForNav = () => {
        if (!!updatedTerminal) {
            navigate('/partners/' + updatedTerminal.partnerId + '/terminals/' + updatedTerminal.serialNumber, {state: updatedTerminal});
            return;
        }
        
        if (error.redirect) navigate(error.redirect);
    }
    
    useEffect(() => {
        checkForNav();
        _ = getTerminals();
        scroller.scroll();
    }, [updatedTerminal]);

    const validateForm = (model) => {
        let err = {};
        
        if (!model.name?.trim()) err.name = "Name is required";
        if (!model.serialNumber?.trim()) err.serialNumber = "Serial Number is required";
        //if (!model.terminalPosType) err.terminalPosType = "Terminal Type is required";
        if (!(model.maxTransactionsAllowed > 0)) err.maxTransactionsAllowed = "Max Transactions Allowed must be greater than 0";
        if (!(model.transactionsPerformed >= 0)) err.transactionsPerformed = "Transactions Performed must be greater than or equal to 0";

        return new ErrorModel(err, true);
    }
    
    const onUpdateAsync = async () => {
        currentTerminal.name = nameRef.current?.value || null;
        currentTerminal.maxTransactionsAllowed = parseInt(maxTransactionsAllowedRef.current?.value || "-1") || 0;
        currentTerminal.transactionsPerformed = parseInt(transactionsPerformedRef.current?.value || "-1") || 0;
        currentTerminal.demoMode = demoModeRef.current?.value !== "false";

        const formError = validateForm(currentTerminal);

        if (formError.hasFormErrors()) {
            setError(formError.focus("terminal-"));
            return;
        }
        
        const rsp = await TerminalService.instance.updateTerminalAsync(currentTerminal).catch((ex) => {
            const er = ErrorModel.createFormError(ex);
            setError(er);
            return null;
        });
        
        if (!rsp) return;

        await TerminalService.instance.getTerminalsAsync();
        navigate('/partners/' + partnerId + '/terminals/');
    }

    const onDeleteAsync = async () => {
        const response = await TerminalService.instance.deleteTerminalAsync(currentTerminal).catch((ex) => { 
            const err = ErrorModel.createFormError(ex);
            setError(err);
            return null;
        });
        
        if (!response) return;
        
        navigate('/partners/' + partnerId + '/terminals/');
    }
    
    const errorGetElement = error?.top ? (<div className={"error-box top"}>
        {error?.top}
        {error?.redirect ? (<><br/><Link to={error?.redirect}>{ error.redirectCaption || "Go Back" }</Link></>) : null}
    </div>) : null;
    
    const deleteButton = !error.top ? (<a onClick={onDeleteAsync} className="add-new"><FontAwesomeIcon icon={faClose} /> Delete</a>) : null;
    
    return (
        <div className={"form medium"}>
            <h2>
                <BackButton path={error.redirect ? error.redirect : null} /> Update Terminal
                {deleteButton}
            </h2>
            { errorGetElement }
            
            <div className="form-group">
                <label htmlFor="terminal-name">Terminal Name</label>
                <input type="text" ref={nameRef} id="terminal-name" defaultValue={currentTerminal?.name} />
                <div className={"form-error"}>{error?.name}</div>
            </div>
            <div className="form-group">
                <label htmlFor="terminal-maxTransactionsAllowed">Max Transactions Allowed</label>
                <input type="number" id="terminal-maxTransactionsAllowed" ref={maxTransactionsAllowedRef} defaultValue={currentTerminal?.maxTransactionsAllowed}/>
                <div className={"form-error"}>{error?.maxTransactionsAllowed}</div>
            </div>
            <div className="form-group">
                <label htmlFor="terminal-transactionsPerformed">Transactions Performed</label>
                <input type="number" id="terminal-transactionsPerformed" ref={transactionsPerformedRef} defaultValue={currentTerminal?.transactionsPerformed}/>
                <div className={"form-error"}>{error?.transactionsPerformed}</div>
            </div>
            <div className="form-group">
                <label htmlFor="terminal-demoMode">Demo Mode</label>
                <select id="terminal-demoMode" ref={demoModeRef} defaultValue={currentTerminal?.demoMode?.toString()}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
                <div className={"form-error"}>{error?.demoMode}</div>
            </div>

            <div className={"form-error"}>{error?.general || error?.serialNumber}</div>
            
            <div className="button">
                <FormButton id="terminal-update-button" onClick={onUpdateAsync} label="Update"/>
            </div>
            
        </div>
    );
}

export default TerminalUpdateScreen;

    