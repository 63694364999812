import React, {
    useRef,
    useState
} from "react";
import FormButton
    from "../../common/ui/FormButton";
import AddressModel
    from "../../geo/models/AddressModel";
import ErrorModel from "../../common/models/ErrorModel";

const MerchantLocationForm = (props) => {
    const { isCreate, location, onSubmit, onCancel }= props;
    const [error, setError] = useState(props.error || {});

    const nameRef = useRef();
    const streetRef = useRef();
    const unitRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
    const countryRef = useRef();
    const partnerMerchantLocationIdRef = useRef();
    
    const validateForm = (model) => {
        const err = {};
        
        if (!model?.name?.trim()) err.name = "Name is required";
        
        // Add additional checks here.
        
        return new ErrorModel(err, true);
    };
    
    const onSubmitClicked = async () => {
        const updatedLocation = location;
        updatedLocation.name = nameRef.current?.value || null;
        updatedLocation.partnerMerchantLocationId = partnerMerchantLocationIdRef.current?.value || (location?.partnerMerchantLocationId || null);
        
        updatedLocation.address = new AddressModel();
        updatedLocation.address.street = streetRef.current?.value || null;
        updatedLocation.address.unit = unitRef.current?.value || null;
        updatedLocation.address.city = cityRef.current?.value || null;
        updatedLocation.address.state = stateRef.current?.value || null;
        updatedLocation.address.zip = zipRef.current?.value || null;
        updatedLocation.address.country = countryRef.current?.value || null;

        const formError = validateForm(updatedLocation);
        
        if (formError.hasFormErrors()) {
            setError(formError.focus("location-"));
            return;
        }
        
        console.warn(JSON.stringify(updatedLocation, null, 4));
        
        const rsp = onSubmit(updatedLocation);
        
        return (typeof rsp.then === "function") ?
            await rsp.then().catch((ex) => {
                setError(ErrorModel.createFormError(ex, "location-"))
                return null;
            }) : rsp;
    }

    return <>
        <div className="form">
            <div className="form-group">
                <label htmlFor={"location-name"}>Name</label>
                <input type="text" defaultValue={location?.name} ref={nameRef} id={"location-name"} />
                <div className={"form-error"}>{ error?.name }</div>
            </div>
            <div className="form-group">
                <label htmlFor={"location-address-street"}>Street</label>
                <input type="text" defaultValue={location?.address?.street} id={"location-address-street"} />
            </div>
            <div className="form-group">
                <label htmlFor={"location-address-unit"}>Unit</label>
                <input type="text" defaultValue={location?.address?.unit} ref={unitRef} id={"location-address-unit"} />
            </div>
            <div className="form-group">
                <label htmlFor={"location-address-city"}>City</label>
                <input type="text" defaultValue={location?.address?.city} ref={cityRef} id={"location-address-city"} />
            </div>
            <div className="form-group">
                <label htmlFor={"location-address-state"}>State</label>
                <input type="text" defaultValue={location?.address?.state} ref={stateRef} id={"location-address-state"} />
            </div>
            <div className="form-group">
                <label htmlFor={"location-address-zip"}>Zip</label>
                <input type="text" defaultValue={location?.address?.zip} ref={zipRef} id={"location-address-zip"} />
            </div>
            <div className="form-group">
                <label htmlFor={"location-address-country"}>Country</label>
                <input type="text" defaultValue={location?.address?.country} ref={countryRef} id={"location-address-country"} />
            </div>
            <div className="form-group">
                <label htmlFor={"location-partner-merchant-location-id"}>Partner Merchant Location ID</label>
                <input type="text" defaultValue={location?.partnerMerchantLocationId} ref={partnerMerchantLocationIdRef} id={"location-partner-merchant-location-id"} />
            </div>

            <div className={"form-error"}>{ error?.general }</div>
            
            <div className="button">
                <span><FormButton id="location-update-button" onClick={onSubmitClicked} label={isCreate ? "Create" : "Update"} /></span>
                <span className={"cancel"}><a onClick={onCancel}>Cancel</a></span>
            </div>

        </div>
    </>;
}

export default MerchantLocationForm;