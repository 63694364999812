import React, {
} from "react";
import {
    Link,
    NavLink,
    useOutletContext, useParams,
} from "react-router-dom";
import BackButton
    from "../../components/common/ui/BackButton";
import AddButton from "../../components/common/ui/AddButton";

const MerchantLocationsScreen = ({props}) => {
    const { partnerId, merchantId } = useParams();
    const { partner, merchant } = useOutletContext();

    const locationElements = merchant?.locations?.map((location, index) => {
        const path = '/partners/' + partner.id + '/merchants/' + merchant.id + '/locations/' + location.id + '/details';

        return (<tr key={"m-item-" + index}>
                <td><NavLink to={path} state={location}>{location.name}</NavLink></td>
                <td>{location.address?.city ? location.address?.city + ", " + location.address?.state : "no address"}</td>
            </tr>);
    });
    
    return <>
        <div>
            <h2>
                { merchant?.name }
                <span className="subtitle">Locations</span>
                <AddButton path={"/partners/" + partnerId + "/merchants/" + merchantId + "/locations/new"} state={merchant}>New Location</AddButton>
            </h2>
            
            <h2>Locations</h2>
            
            <table className={"table x-large partners"} width={"100%"}>
                <thead>
                <tr>
                    <th>Location Name</th>
                    <th>Region</th>
                </tr>
                </thead>

                <tbody>
                {locationElements}
                </tbody>
            </table>
        </div>
    </>;
}

export default MerchantLocationsScreen