class TerminalModel {
    static json = {
        "serialNumber": "ST3SL472MW000012",
        "partnerId": "bb68e800-d00e-4323-a407-85012df6a6e8",
        "merchantLocationId": "deae6d3b-db99-4f26-9c9c-7e615ab57480",
        "name": "Demo ST3SL472MW000012",
        "terminalPosType": "poynt",
        "connectionId": "b46f259f27d548c4ca605f",
        "maxTransactionsAllowed": 200,
        "transactionsPerformed": 18,
        "demoMode": true,
        "isConnected": true,
        "created": "2022-09-20T18:45:01.0061593Z",
        "lastConnected": "2022-10-03T19:56:24Z"
    };

    constructor(json) {
        if (!json) json = {};

        this.serialNumber = json.serialNumber || "";
        this.partnerId = json.partnerId || "";
        this.merchantLocationId = json.merchantLocationId || "";
        this.name = json.name || "";
        this.terminalPosType = json.terminalPosType || "n/a";
        this.posTerminalId = json.posTerminalId || "";
        this.remoteId = json.remoteId || (json.posTerminalId || null);
        this.connectionId = json.connectionId;
        this.maxTransactionsAllowed = json.maxTransactionsAllowed || 0;
        this.transactionsPerformed = json.transactionsPerformed || 0;
        this.demoMode = (json.demoMode === true);
        this.isConnected = (json.isConnected === true);
        this.created = json.created ? new Date(json.created) : null;
        this.lastConnected = json.lastConnected ? new Date(json.lastConnected) : null;
    }

    getBinaryId(fullQuery = true) {
        const id = this.posTerminalId 
        //if (this.serialNumber === "1760014462") id = "1453181588471859";
        
        if (typeof id !== "string" || id.length === 0) return id;
        const encodedUrl = fullQuery === true ? "terminal=" + id + "&isShowList=false&factoryId=1&activeIndex=0&accrssoryActiveIndex=" : id;

        console.warn("Parsing: " + encodedUrl);
        
        const lengthData = { length: encodedUrl.length };
        const codeUnits = Uint16Array.from(lengthData, (element, index) => encodedUrl.charCodeAt(index));
        const charCodes = new Uint8Array(codeUnits.buffer);

        let result = "";
        charCodes.forEach((char) => {
            result += String.fromCharCode(char);
        });

        return result;
    }
    
    getEncodedId() {
        let posId = this.getBinaryId();
        if (!posId) return null;
        
        const tt = this.terminalPosType?.toLowerCase() || "";
        
        if (tt === "pax" || tt.indexOf(".pax.") >= 0) {
            if (typeof btoa === "function") {
                try {
                    console.warn("CodeA: " + "a".codePointAt(0).toString(16));
                    console.warn("CodeB: " + posId.codePointAt(0).toString(16));
                    console.warn("CodeO: " + this.posTerminalId?.codePointAt(0)?.toString(16) + "");

                    posId = btoa(posId);
                    console.warn("---- " + posId + " ----");
                    
                    return posId;
                }
                catch(ex) { 
                    console.error("Failed to parse base64 id: " + ex.toString());
                    console.error("Code: " + posId.codePointAt(0).toString(16));
                    return null;
                }
            }
            
            return null;
        }

        console.error("EncodedId is no good: " + (typeof btoa).toString());
        console.warn(JSON.stringify(this, null, 2));
        
        return (posId || "")?.toString().trim();
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new TerminalModel(json));
    }

    static toJson(terminal) {
        return {
            "serialNumber": terminal.serialNumber,
            "partnerId": terminal.partnerId,
            "merchantLocationId": terminal.merchantLocationId,
            "name": terminal.name,
            "terminalPosType": terminal.terminalPosType,
            "connectionId": terminal.connectionId,
            "maxTransactionsAllowed": terminal.maxTransactionsAllowed,
            "transactionsPerformed": terminal.transactionsPerformed,
            "demoMode": terminal.demoMode,
            "isConnected": terminal.isConnected,
        };
    }
}

export default TerminalModel;
