import TransactionModel from "./TransactionModel";
import MerchantModel from "../../partners/models/MerchantModel";
import LocationModel from "../../partners/models/LocationModel";
import SaleProductModel from "./SaleProductModel";
import SaleEventModel from "./SaleEventModel";
import SaleAmountModel from "./SaleAmountModel";

class SaleModel { 
    constructor(json) {
        if (!json) json = {};

        this.id = json.id;
        this.locationId = json.merchantLocationId || json.locationId;
        this.partnerId = json.partnerId || null;
        this.merchantId = json.merchantId || null;
        this.partnerMerchantLocationId = json.partnerMerchantLocationId;
        this.partnerCustomerId = json.partnerCustomerId;
        this.partnerSaleId = json.partnerSaleId;
        this.total = json.total || 0.0;
        
        this.lineItems = SaleProductModel.fromJsonArray(json.lineItems || (json.line_items || [ { amount: this.total, name: "Total" } ]));
        this.amountItems = SaleAmountModel.fromJsonArray(json.amountItems || json.amount_items);
        this.transactions = TransactionModel.fromJsonArray(json.transactions);
        this.events = SaleEventModel.fromJsonArray(json.events);
        
        this.created = !!json.created ? new Date(json.created) : null;

        // Mock fields
        this.saleNumber = (json.saleNumber || json.sale_number) || (json.number || Math.floor(Math.random() * 99999));
        this.merchant = new MerchantModel(json.merchant || { name: "Paylani Merchant", merchantId: this.merchantId || this.partnerId });
        this.location = new LocationModel(json.location || { name: "Paylani Location", locationId: this.locationId, is_local: true });
    }
    
    searchFor(searchText) {
        if (this.saleNumber.toString().indexOf(searchText) > -1) return true;
        return this.lineItems.filter((item) => item.searchFor(searchText)).length > 0;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new SaleModel(json));
    }
}

export default SaleModel;
