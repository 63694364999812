import SessionModel from "../models/SessionModel";
import HttpService from "../../common/services/HttpService";
import EnvironmentModel from "../../common/models/EnvironmentModel";
import appContext
    from "../../../AppContext";
import UserService
    from "../../people/services/UserService";

class AuthenticationService {
    static createMockSession() {
        const json = {
            "user": {
                "merchant_id": "35ae9fac-9be3-4077-a84c-7ed467062fe5",
                "merchant_location_id": "61c737cc-3642-4d57-bee2-5a2759e2c10c",
                "role_id": "2494abff-49db-4c34-b457-e98a3b3b95ef",
                "remote_id": null,
                "user_name": "jade@jadecharles.com",
                "first_name": "Jade",
                "last_name": "Charles",
                "email": "sonani.babubhai@atos.net",
                "phone": "8583668906",
                "company_name": null,
                "image_url": "https://s3-us-west-2.amazonaws.com/paylani-images/userb8804d7e-9b80-459b-b8d6-963fa2852228-637103485328496655.jpg",
                "status_key": 1,
                "is_active": true,
                "auth_level_key": 0,
                "evoke_api_token": "18EF4A51-5A50-45CD-9686-B0E744279B45",
                "activation_date": "2018-05-23T21:37:38.04",
                "last_login": "2022-10-03T11:30:27.27",
                "created_by": "b8804d7e-9b80-459b-b8d6-963fa2852228",
                "pin": null,
                "id": "b8804d7e-9b80-459b-b8d6-963fa2852228",
                "created": "2018-03-23T19:56:58.703",
                "modified": "2022-09-07T09:51:10.707"
            },
            "user_id": "b8804d7e-9b80-459b-b8d6-963fa2852228",
            "ip_address": "70.95.111.112",
            "is_active": true,
            "user_agent": "Postman",
            "expires": "2022-09-20T13:24:12.257",
            "city": null,
            "region": null,
            "country": null,
            "location": null,
            "code": 0,
            "id": "6541de67-4e09-4385-9bc7-8491e3120e4d",
            "created": "2022-09-20T13:09:08.45",
            "modified": "2022-09-20T13:09:12.26"
        };

        return new SessionModel(json);
    }

    static sessionKey = HttpService.sessionKey;
    static instance;
    
    static hasValidSession() { 
        return !!this.instance.session && this.instance.session.user !== null;
    }
    
    constructor() {
        let me = this;
        
        //this.session = AuthenticationService.createMockSession();
        // this.sessionId = localStorage.getItem(AuthenticationService.sessionKey) || this.session?.id;
        //
        // console.log('Session ID: ' + this.sessionId);
        //
        // if (!!this.sessionId) this.sessionId = this.session?.id;
        //
        // console.warn("Init SessionId: " + this.sessionId);
        //
        this.isGetting = false;
        this.onSessionSet = null;   // Sort of a hack, but it works. Change this later
        this.onUpdate = (v) => {
           // console.log('Unset onUpdate Fire: ' + me.session);
        };
        //
        this.onSessionError = (err) => {
            console.error('Failed to get session: ' + err);
            let _ = me.signOutAsync();
        };
        
         if (!this.sessionId || this.sessionId.length < 10) this.sessionId = null;
        //
        // HttpService.instance.onUnauthorizedResponse = (errorResponse) => {
        //     console.warn('UNAUTHORIZED RESPONSE: ' + errorResponse.status);
        //     me.onSessionError(errorResponse);
        // };
        //
        // HttpService.instance.onUpdate = (v) => {
        //     if (typeof me.onUpdate === 'function') me.onUpdate(v);
        // };
        
        //HttpService.instance.setSessionId(this.sessionId);
        //this.getSessionAsync().then((rsp) => this.setSession(rsp?.data));
    }
    
   async restoreSessionAsync(){
       this.sessionId = localStorage.getItem(AuthenticationService.sessionKey) || this.session?.id;
         HttpService.instance.setSessionId(this.sessionId);

       return this.getSessionAsync(true);
    }
    
    setSession(json) {
        console.log('Set Session: ' + json);
        this.session = !!json ? new SessionModel(json) : null;
        this.sessionId = this.session ? this.session.id : this.sessionId;
        
        UserService.instance.userMap[this.session.user.id] = this.session.user;
        
        localStorage.setItem(AuthenticationService.sessionKey, this.sessionId);
        
        HttpService.instance.setSessionId(this.sessionId);
        HttpService.instance.setAdminToken(this.session?.user?.evokeApiToken);
        
        if(this.sessionId?.user?.evoke_api_token===null){
            console.warn('USER IS NOT AUTHORIZED TO USE EVOKE (No Evoke API Token Assigned)');
            
            this.onSessionError('This user is not authorized to use Evoke');
        }
        if (typeof this.onSessionSet === 'function') {
            let result = this.onSessionSet(this.session);
            console.warn('Session Set OK: ' + result);
            console.warn('Session null? ' + (this.session === null).toString());
            if (result !== true) this.onSessionSet = null;
        }
        
        if (typeof this.onUpdate === 'function') this.onUpdate("session-set");
        //console.warn('Set session: ' + this.sessionId);
    }
    
    
    async signOutAsync() { 
        this.session = null;
        this.sessionId = null;
        this.isGetting = false;

        HttpService.instance.sessionId = null;
        console.warn('Signing out [' + AuthenticationService.sessionKey + ']');
        localStorage.removeItem(AuthenticationService.sessionKey);
    }
    
    async authenticateAsync(username, password, environmentKey) {
        let error = '';
        if (!username) error += 'Username is required. ';
        if (!password) error += 'Password is required. ';

        if (!!error) {
            throw Error(error);
        }
        
        // TODO: Move to HttpService
        const me = this;
        let url = '/api/authenticate/user';
        let env = (!!environmentKey) ? EnvironmentModel.environments[environmentKey] : null;

        if (!!env) {
            let bu = env.paylaniApiUrl || ('http://' + window.location.host);
            url = bu + url;
            
            console.warn("Authing at: " + url);
            console.warn('With SessionId: ' + this.sessionId);
        } else { 
            console.warn('No environment key. Using default for auth: ' + environmentKey);
        }

        let data = {
            user_name: username,
            password: password,
            captcha: '',
        };

        return HttpService.instance.postPaylaniAsync(url, data).then((rsp) => {
            let environmentRedirect = (!!env) ? EnvironmentModel.setEnvironment(env.key) : false;
            
            me.setSession(rsp.data);
            me.isGetting = false;

            if (environmentRedirect) me.redirect();
            
            return me.session;
        }).catch((err) => {
            me.isGetting = false;
            throw err;
        }); 
    }

    redirect() {
        // Redirecting clears all cache.
        let q = 'change-environment=true'
        let m = window.location.href.indexOf('?') > 0 ? '&' : '?';
        
        window.location.href = window.location.href + m + q;
    }
    
    isLoggedIn() { 
        return !!this.sessionId && this.sessionId.length > 10;
    }
    
    async getSessionAsync(force) {
       // return AuthenticationService.createMockSession();
        
        if (this.session !== null && !force) {
            console.log('Got from cache. Good.');
            HttpService.instance.setSessionId(this.sessionId);
            return this.session;
        }
        
        if (this.isGetting === true) { 
            //console.warn('Getting. So aborting.');
            return;
        }
        
        if (!this.sessionId) { 
            //console.error('Failed to get session. Session id no good: ' + this.sessionId);
            return;
        }

        this.isGetting = true;
        
        const me = this;
        const url = '/api/authenticate/session';
        
        HttpService.instance.setSessionId(this.sessionId);
        
        return HttpService.instance.getPaylaniAsync(url).then((rsp) => {
            me.setSession(rsp.data);
            me.isGetting = false;

            return me.session;
        }).catch((err) => {
            me.isGetting = false;
            me.onSessionError(err);
        });
    }
    
    async getAccountResetAsync(resetId) {
        const me = this;
        const url = '/api/user/account/' + resetId;
        
        return HttpService.instance.getPaylaniAsync(url, true).then((rsp) => {
            return rsp.data;
        });
    }
    
    async updateAccountPasswordAsync(resetId, password) {
        const me = this;
        const url = '/api/user/account/' + resetId;

        return HttpService.instance.postPaylaniAsync(url, { value: password}).then((rsp) => {
            return rsp.data;
        });
    }
    
}

export default AuthenticationService;
