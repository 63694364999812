import React from 'react';
import {Doughnut} from "react-chartjs-2";

const CardBrandPieChart = (props) => {
    const { title, width, height, data, visa, masterCard, discover, amex } = props;
    
    const cardBrands = [
        { name: "Visa", color: '#AAA9FF88', key: 'visa' },
        { name: "MasterCard", color: '#EED02D88', key: 'mastercard' },
        { name: "Discover", color: '#CE702D88', key: 'discover' },
        { name: "AmericanExpress", color: '#6666FF77', key: 'amex' },
    ];
    
    const createPieData = () => {
        let data = {
            visa: visa || 0,
            mastercard: masterCard || 0,
            discover: discover || 0,
            amex: amex || 0,
        };
        
        let colors = cardBrands.map(item => item.color);
        return {
            labels: cardBrands.map(item => item.name),
            datasets: [
                {
                    label: 'Card Brand',
                    data: cardBrands.map(item => data[item.key]),
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                }
            ]
        };
        
    };
    
    let w = width || 240;
    let h = height || 240;
    
    let pieData = data;
    if (!pieData || !pieData.datasets) pieData = createPieData();
    
    return (
        <div className="dark round24">
            <Doughnut options={CardBrandPieChart.createPieOptions(title)} data={pieData} width={w} height={h} />
        </div>
    );
};

CardBrandPieChart.createPieOptions = (title) => {
    return {
        responsive: true,
        plugins: {
            legend: {
                align: 'end',
                position: 'chartArea',
                fullSize: false,
            },
            title: {
                display: true,
                color: '#FFFFFFAA',
                text: title,
            },
        }
    };
};

export default CardBrandPieChart;
